import React, {
  useState,
  useCallback,
  useEffect,
  MouseEventHandler,
  Dispatch,
  SetStateAction,
  useMemo
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { ROOT_NODE_ID } from 'ecto-common/lib/constants';

import SignalProviders from 'ecto-common/lib/SignalSelector/SignalProviders';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import styles from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric.module.css';
import SelectEquipment from 'ecto-common/lib/SelectEquipment/SelectEquipment';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import APIGen, {
  FullSignalProviderResponseModel,
  SignalProviderSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SelectedSignalsTable } from 'ecto-common/lib/SignalSelector/SelectedSignalsTable';
import { ColorResult } from 'react-color';
import { useNode, useNodes } from 'ecto-common/lib/hooks/useCurrentNode';

interface SignalSelectorGenericProps {
  nodeId?: string;
  selectedSignals: ChartSignal[];
  addNewSignal: (
    signal: SignalProviderSignalResponseModel,
    provider: FullSignalProviderResponseModel
  ) => void;
  hasSignal?: (
    signals: ChartSignal[],
    signal: SignalProviderSignalResponseModel
  ) => boolean;
  onRemoveAllSignals: MouseEventHandler<HTMLButtonElement>;
  onRemoveSignal: (signal: ChartSignal) => void;
  coloredSignals?: boolean;
  className?: string;
  selectFromCurrentNodeOnly?: boolean;
  showSignalType?: boolean;
  validSignalTypeIds?: string[];
  setSelectedSignals: Dispatch<SetStateAction<ChartSignal[]>>;
}

const SignalSelectorGeneric = ({
  nodeId,
  selectedSignals,
  addNewSignal,
  hasSignal,
  onRemoveAllSignals,
  setSelectedSignals,
  onRemoveSignal,
  coloredSignals,
  className,
  validSignalTypeIds = null,
  showSignalType = false,
  selectFromCurrentNodeOnly = false
}: SignalSelectorGenericProps) => {
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);
  const isRootNode = _.startsWith(
    _.defaultTo(nodeId, ROOT_NODE_ID),
    ROOT_NODE_ID
  );

  const [selectedIds, setSelectedIds] = useState(
    isRootNode ? [] : _.compact([nodeId])
  );

  const req = isAdmin
    ? APIGen.AdminSignals.getSignalsByNode
    : APIGen.Signals.getSignalsByNode;
  const {
    isLoading: getSignalsForNodeIdIsLoading,
    data: signalProviders,
    error
  } = req.useQuery(
    {
      nodesIds: selectedIds
    },
    {
      enabled:
        selectedIds.length > 0 && !_.head(selectedIds).startsWith(ROOT_NODE_ID)
    }
  );

  useEffect(() => {
    if (nodeId) {
      if (!selectedIds.length) {
        const _nodeId = isRootNode ? [] : [nodeId];

        setSelectedIds(_nodeId);
      }
    }
  }, [nodeId, selectedIds.length, isRootNode]);

  const onSignalSettingsChanged = useCallback(
    (key: string[], value: unknown, editedSignalIndex: number) => {
      setSelectedSignals((oldSelectedSignals) => {
        const newSelectedSignals = [...oldSelectedSignals];
        newSelectedSignals[editedSignalIndex] = {
          ...newSelectedSignals[editedSignalIndex],
          settings: {
            ...newSelectedSignals[editedSignalIndex].settings,
            [key[0]]: value
          }
        };

        return newSelectedSignals;
      });
    },
    [setSelectedSignals]
  );

  const onColorChange = useCallback(
    ({ hex }: ColorResult, editedSignalIndex: number) => {
      setSelectedSignals((oldSelectedSignals) => {
        const newSelectedSignals = [...oldSelectedSignals];
        newSelectedSignals[editedSignalIndex] = {
          ...newSelectedSignals[editedSignalIndex],
          color: hex
        };

        return newSelectedSignals;
      });
    },
    [setSelectedSignals]
  );

  const allNodeIds = useMemo(() => {
    return _.flatMap(selectedSignals, (signal) => signal.group.nodeIds);
  }, [selectedSignals]);

  const { node } = useNode(_.head(selectedIds) ?? nodeId);

  const allNodes = useNodes(allNodeIds);

  return (
    <div className={classNames(styles.detailsItem, className)}>
      <div className={classNames(styles.treeList, styles.box, styles.treeBox)}>
        <SelectEquipment
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          selectFromCurrentNodeOnly={selectFromCurrentNodeOnly}
        />
      </div>
      <div className={classNames(styles.signalListSelector, styles.box)}>
        <SignalProviders
          node={node}
          selectedId={_.head(selectedIds)}
          signalProviders={signalProviders}
          isLoading={getSignalsForNodeIdIsLoading}
          hasError={error != null}
          addNewSignal={addNewSignal}
          hasSignal={hasSignal}
          selectedSignals={selectedSignals}
          showSignalType={showSignalType}
          validSignalTypeIds={validSignalTypeIds}
        />
      </div>

      <div className={classNames(styles.signalList, styles.box)}>
        {selectedSignals.length > 0 && (
          <SelectedSignalsTable
            selectedSignals={selectedSignals}
            onDeleteAllSignalsClick={onRemoveAllSignals}
            onRemoveClick={onRemoveSignal}
            getNodeName={(_signal) =>
              allNodes.nodes.find((x) =>
                _signal.group.nodeIds.includes(x.nodeId)
              )?.name
            }
            onColorChange={coloredSignals ? onColorChange : null}
            onSignalSettingsChanged={
              coloredSignals ? onSignalSettingsChanged : null
            }
            showSignalType={showSignalType}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(SignalSelectorGeneric);
