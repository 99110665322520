module.exports = {
  iconButtonWidth: 36,
  iconButtonHeight: 33,
  footerHeight: 50,
  headerHeight: 75,
  toolbarHeight: 65,
  toolbarSpacing: 20,
  leftSidebarWidth: 75,
  navRowHeight: 50,
  standardMargin: 14,
  insetMargin: 3,
  smallMargin: 7,
  largeMargin: 21,
  tableVerticalMargin: 9,
  iconTextMargin: 10,
  plainBoxPadding: 10,
  hugeMargin: 28,
  tableBorderRadius: 5,
  maxListPageWidth: 1400,
  contractedViewBreakpointWidth: 1600,
  contractedViewWidth: 1366,
  graphPageSmallViewport: 1280,
  labelWeight: '500',
  labelFontSize: 13,
  tableInlineFontSize: 13,
  tableFontSize: 14,
  baseSvgFontSize: 16,
  textInputFontSize: '0.81rem',

  alternateSvgFontSize: 15, // Some SVG icons look sharper at 15px..
  subtitleFontSize: '0.85em',
  contentSidebarWidth: 340,
  //
  // Breakpoints

  mobileBreakpoint: 650,
  tabletBreakpoint: 1200,

  //
  // Modal
  modalBreakpointHeight: 760,

  dataTableRowHeightSmaller: 32,
  dataTableRowHeightStandard: 38,
  dataTableRowHeightWithButtons: 49,
  dataTableRowHeightWithSelect: 51,
  dataTableRowHeightWithCheckbox: 38,

  modalContractedHeaderHeight: 45,
  modalContractedFooterHeight: 45,
  modalHeaderHeight: 48,
  modalFooterHeight: 54,
  modalCornerRadius: 6,
  maxModalHeight: '80vh',
  maxModalWidth: 900,

  //
  // Table

  tableHeaderHeight: 25,
  borderWidth: 1,
  dividerWidth: 1,
  //
  // Horizontal treeview
  horizontalColumnWidth: 235,

  treeDialogWidth: 450,
  treeDialogHeight: 500,

  spinnerLargeSize: 72,
  spinnerMediumSize: 42,
  spinnerSmallSize: 32,
  spinnerInlineSize: 6,
  spinnerTinySize: 22,
  spinnerTreeViewSize: 16,
  sidebarExpandedLimit: 1800
};
