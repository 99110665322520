import React, { useContext, useMemo, useRef } from 'react';
import _ from 'lodash';

import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import animations from 'ecto-common/lib/styles/variables/animations';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import ErrorBoundary from 'ecto-common/lib/utils/ErrorBoundary';
import { getSignalColor } from 'ecto-common/lib/SignalSelector/StockChart.config';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import colors from 'ecto-common/lib/styles/variables/colors';
import { migrateSignalSettingSystemNamesToSignalTypes } from 'ecto-common/lib/Dashboard/migrations/datasourceUtil';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import dimensions from 'ecto-common/lib/styles/dimensions';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';

import {
  CustomPanelProps,
  DashboardPanel,
  PanelSizeType
} from 'ecto-common/lib/Dashboard/Panel';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import styles from './BarChartComparePanel.module.css';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';

type BarChartComparePanelConfig = {
  refreshInterval?: number;
  minValue?: number;
  maxValue?: number;
};

const DEFAULT_REFRESH_INTERVAL = 15;
const MIN_REFRESH_INTERVAL = 15;

const renderSolidLine = (
  renderer: Highcharts.SVGRenderer,
  startX: number,
  endX: number,
  y: number
) => {
  return renderer
    .path([
      ['M', startX, y],
      ['L', endX, y]
    ])
    .attr({
      'stroke-width': 2,
      stroke: colors.surface2Color
    })
    .add();
};

const renderCenteredText = (
  renderer: Highcharts.SVGRenderer,
  x: number,
  y: number,
  text: string
) => {
  return renderer
    .text(text, x, y)
    .add()
    .attr({
      zIndex: 3,
      align: 'center',
      class: styles.svgText
    })
    .css({
      fontWeight: 600 + '',
      fontSize: dimensions.labelFontSize + 'px'
    });
};

const renderDashedLine = (
  renderer: Highcharts.SVGRenderer,
  x: number,
  startY: number,
  endY: number
) => {
  return renderer
    .path(
      renderer.crispLine(
        [
          ['M', x, startY],
          ['L', x, endY]
        ],
        1,
        undefined
      )
    )
    .attr({
      'stroke-width': 1,
      zIndex: 3,
      stroke: colors.borderColor,
      dashStyle: 'shortdash'
    })
    .css({
      strokeDasharray: '4, 10'
    })
    .add();
};

const LABEL_POS_Y = 20;
const CATEGORY_TOP_LINE_POS_Y = 34;
const DASH_LINE_OFFSET = 6;

// Use any at the moment since Highcharts TS defs do not match passed objects
const renderAnnotations = (
  chart: Highcharts.Chart,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  seriesData: any,
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  renderedElements: any
) => {
  _.invokeMap(renderedElements.current, 'destroy');
  renderedElements.current = [];

  const groups = _.groupBy(seriesData, 'options.category');

  let groupSections = _.map(groups, (group, groupName) => {
    const maxElem = _.maxBy(group, 'barX');
    return {
      group,
      groupName,
      midX: _.meanBy(group, (point) => point.plotX + chart.plotLeft),
      minX: _.minBy(group, 'barX').barX + chart.plotLeft,
      maxX: maxElem.barX + maxElem.pointWidth + chart.plotLeft,
      categoryIndex: _.head(group).categoryIndex
    };
  });

  groupSections = _.orderBy(groupSections, 'categoryIndex');

  _.forEach(groupSections, (groupSection, idx) => {
    // Draw dotted line separator between groups
    if (groupSection.categoryIndex < groupSections.length - 1) {
      const nextGroupSection = groupSections[idx + 1];
      const midPoint =
        groupSection.maxX + (nextGroupSection.minX - groupSection.maxX) / 2.0;

      // marginBottom is missing in Typescript definition, but it is there
      renderedElements.current.push(
        renderDashedLine(
          chart.renderer,
          midPoint,
          CATEGORY_TOP_LINE_POS_Y + DASH_LINE_OFFSET,
          // @ts-ignore-next-line
          chart.chartHeight - chart.marginBottom
        )
      );
    }

    if (groupSection.groupName !== '') {
      const extraPadding = chart.plotWidth * 0.02;
      renderedElements.current.push(
        renderCenteredText(
          chart.renderer,
          groupSection.midX,
          LABEL_POS_Y,
          groupSection.groupName
        )
      );

      renderedElements.current.push(
        renderSolidLine(
          chart.renderer,
          groupSection.minX - extraPadding,
          groupSection.maxX + extraPadding,
          CATEGORY_TOP_LINE_POS_Y
        )
      );
    }
  });
};

type BarChartComparePanelContentProps = LastSignalValuesDataSourceResult & {
  size?: PanelSizeType;
  minValue?: number;
  maxValue?: number;
};

const BarChartComparePanelContent = ({
  signalValues,
  signalInfo,
  isLoading,
  hasError,
  size,
  minValue,
  maxValue
}: BarChartComparePanelContentProps) => {
  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const categoryOrder = useMemo(() => {
    return _(signalInfo.signalInputs)
      .map('category')
      .uniq()
      .map((category, index) => [category, index])
      .fromPairs()
      .value();
  }, [signalInfo]);

  // If all of the signals share the same unit, show that in the Y axis.
  const sharedUnit = useMemo(() => {
    const units = _(signalValues)
      .map((x) => signalInfo.signals[x.signalId].signalTypeId)
      .uniqBy(_.identity)
      .map((signalType) =>
        getSignalTypeUnit(signalType, signalTypesMap, signalUnitTypesMap)
      )
      .uniqBy(_.identity)
      .value();

    if (units.length === 1) {
      return _.head(units);
    }
    return '';
  }, [signalValues, signalInfo, signalTypesMap, signalUnitTypesMap]);

  const signalInfoMap = useMemo(() => {
    return _.keyBy(signalInfo.matchingSignals, 'signal.signalId');
  }, [signalInfo.matchingSignals]);

  const dataSeries = useMemo(() => {
    // Since the signal values order is not defined, find out which order
    // the categories were defined initially in the signal picker. Then
    // create a mapping between category name and it's index - then use
    // this to sort the data values per category
    const annotatedValues = _.map(signalValues, (signalValue, index) => {
      const signal = signalInfo.signals[signalValue.signalId];
      const matchingSignal = signalInfoMap[signalValue.signalId];

      const numberOfDecimals = matchingSignal?.signalInfo?.rounding;

      const initialY = signalValue.value ?? 0;
      const y =
        numberOfDecimals != null
          ? _.round(initialY, numberOfDecimals)
          : initialY;

      const rule = signalValue.signalInput;
      const unit = getSignalTypeUnit(
        signal.signalTypeId,
        signalTypesMap,
        signalUnitTypesMap
      );

      return {
        y,
        matchIndex: rule?.matchIndex,
        name: rule?.displayName || signal.name,
        color: getSignalColor(rule, index),
        category: rule?.category ?? '',
        categoryIndex: categoryOrder[rule?.category] ?? 0,
        formattedValue: formatNumberUnit(
          initialY,
          unit,
          numberOfDecimals != null ? numberOfDecimals : numDecimalsForUnit(unit)
        )
      };
    });

    return _.map(
      _.orderBy(annotatedValues, ['categoryIndex', 'matchIndex']),
      (point, index) => ({
        ...point,
        x: index
      })
    );
  }, [
    signalValues,
    signalInfo.signals,
    signalInfoMap,
    signalTypesMap,
    signalUnitTypesMap,
    categoryOrder
  ]);

  const renderedElements = useRef([]);

  const config: Highcharts.Options = useMemo(() => {
    const hasCategories = _.some(dataSeries, (x) => x.category !== '');

    return {
      rangeSelector: {
        enabled: false
      },
      boost: {
        allowForce: false,
        turbo: false,
        enabled: false
      },
      navigator: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        liveRedraw: false,
        enabled: false
      },
      chart: {
        type: 'column',
        marginTop: hasCategories ? 35 : null,
        zoomType: false,
        events: {
          render: (e) => {
            const chart = e.target as unknown as Highcharts.Chart,
              seriesData = chart.series[0].data;
            renderAnnotations(chart, seriesData, renderedElements);
          }
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '14px'
          }
        }
      },
      yAxis: {
        min: minValue,
        max: maxValue,
        opposite: false,
        crosshair: false,
        labels: {
          formatter: (value) => {
            if (sharedUnit !== '') {
              return formatNumberUnit(
                Number(value.value),
                sharedUnit,
                numDecimalsForUnit(sharedUnit)
              );
            }

            return yAxisFormatter(value);
          }
        }
      },
      tooltip: {
        pointFormat: '{point.formattedValue}'
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          animation: {
            duration: parseFloat(animations.defaultSpeed) * 1000
          },
          groupPadding: 0.2,
          pointPadding: 0
        },
        column: {
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          name: T.admin.dashboards.comparebarchart.series,
          data: dataSeries,
          type: 'column'
        }
      ]
    };
  }, [dataSeries, maxValue, minValue, sharedUnit]);

  return (
    <ErrorBoundary>
      <StockChart
        config={config}
        hasError={hasError}
        isLoading={isLoading}
        containerWidth={size.width}
      />
    </ErrorBoundary>
  );
};

type BarChartComparePanelProps = CustomPanelProps & {
  data: {
    values: LastSignalValuesDataSourceResult;
  };
};

const BarChartComparePanel = ({
  data,
  panelApi
}: BarChartComparePanelProps) => {
  const { values, ...other } = data;
  return (
    <BarChartComparePanelContent {...values} {...other} size={panelApi.size} />
  );
};

type BarChartCompareSignalInput = SignalInputType & {
  category: string;
  displayName: string;
};

const sections: ModelFormSectionType<BarChartComparePanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.barchart,
    initiallyCollapsed: true,
    lines: [
      {
        models: [
          {
            key: (input) => input.refreshInterval,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.linechart.refreshinterval,
            placeholder: _.toString(DEFAULT_REFRESH_INTERVAL),
            hasError: (value: number) => value < MIN_REFRESH_INTERVAL
          }
        ]
      }
    ]
  },
  {
    label: T.admin.dashboards.panels.minmaxsettings,
    lines: [
      {
        models: [
          {
            key: (input) => input.minValue,
            modelType: ModelType.NUMBER,
            label: T.graphs.minmax.table.min
          },
          {
            key: (input) => input.maxValue,
            modelType: ModelType.NUMBER,
            label: T.graphs.minmax.table.max
          }
        ]
      }
    ]
  }
];

export const BarChartComparePanelData = {
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS_LAST_VALUE]: {
      optionalSignalModels: [
        {
          key: (input) => input.category,
          modelType: ModelType.TEXT,
          label: T.signals.category,
          placeholder: T.signals.categoryplaceholder
        },
        {
          key: (input) => input.displayName,
          modelType: ModelType.TEXT,
          label: T.signals.displayname,
          placeholder: T.signals.displaynameplaceholder
        }
      ] as ModelDefinition<BarChartCompareSignalInput>[]
    }
  },
  emptyTargets: {
    values: {
      sourceType: DataSourceTypes.SIGNALS_LAST_VALUE
    }
  },
  sections,
  migrations: [
    {
      version: 2,
      migration: (
        panel: DashboardPanel,
        enums: GetEnumsAndFixedConfigurationsResponseModel,
        signalTypesMap: Record<string, SignalTypeResponseModel>
      ) => {
        panel.targets.values = migrateSignalSettingSystemNamesToSignalTypes(
          panel.targets.values,
          enums,
          signalTypesMap
        );
      }
    }
  ],
  helpPath: HelpPaths.docs.dashboard.dashboards.compare_value_chart
};

export default React.memo(BarChartComparePanel);
