import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import DashboardMap from 'ecto-common/lib/Dashboard/panels/LocationMapPanel/DashboardMap';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { migrateSignalSettingSystemNamesToSignalTypes } from 'ecto-common/lib/Dashboard/migrations/datasourceUtil';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { PanelMigration } from '../PanelTypes';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import {
  nodeIsEquipment,
  nodeIsSite,
  useNode
} from 'ecto-common/lib/hooks/useCurrentNode';

const EmptyNodes: NodeV2ResponseModel[] = [];

type LocationMapPanelDataType = {
  signals: LastSignalValuesDataSourceResult;
  hideControls: boolean;
  autoSelectFirstSignal?: boolean;
};

type LocationMapPanelProps = CustomPanelProps & {
  data: LocationMapPanelDataType;
};

const LocationMapPanel = ({ data, panelApi }: LocationMapPanelProps) => {
  const nodes = data?.signals?.nodes ?? EmptyNodes;

  const { nodeId } = useContext(DashboardDataContext);

  const { node } = useNode(nodeId);

  // When showing a site, don't show the actual site as a pin
  // in the map, only it's children. Otherwise, it becomes confusing
  // since the node pin includes a count.
  // The exception to this is when the site does not contain any children.

  const filteredNodes = useMemo(() => {
    const nodesWithoutEquipment = nodes.filter((otherNode) => {
      return !nodeIsEquipment(otherNode);
    });

    if (nodeIsSite(node)) {
      // The site is the only node, keep it
      if (nodes.length === 1 && nodes[0].nodeId === node?.nodeId) {
        return nodesWithoutEquipment;
      }

      return _.filter(
        nodesWithoutEquipment,
        (otherNode) => otherNode.nodeId !== node.nodeId
      );
    }

    return nodesWithoutEquipment;
  }, [nodes, node]);
  return (
    <DashboardMap
      nodeList={filteredNodes}
      size={panelApi.size}
      signals={data?.signals}
      hideControls={data?.hideControls}
      autoSelectFirstSignal={data?.autoSelectFirstSignal}
    />
  );
};

const typedMigrations: PanelMigration[] = [
  {
    version: 1,
    migration: (panel) => {
      // This migration removes node data source and replaces it with signals last value
      const hardcodedNodeId = panel.targets?.node?.nodeId;
      delete panel.targets.node;
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      (panel.targets.signals as any) = {
        sourceType: DataSourceTypes.SIGNALS_LAST_VALUE,
        traverseNodes: true,
        useSiblings: true,
        nodeIds: _.compact([hardcodedNodeId])
      };
    }
  },
  {
    version: 2,
    migration: (panel) => {
      // This migration removes the now unused flag traverseNodes
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      delete (panel.targets.signals as any).traverseNodes;
    }
  },
  {
    version: 3,
    migration: (panel, enums, signalTypesMap) => {
      panel.targets.signals = migrateSignalSettingSystemNamesToSignalTypes(
        panel.targets.signals,
        enums,
        signalTypesMap
      );
    }
  }
];

const sections: ModelFormSectionType<LocationMapPanelDataType>[] = [
  {
    label: T.admin.dashboards.sections.map,
    initiallyCollapsed: true,
    lines: [
      {
        models: [
          {
            key: (input) => input.hideControls,
            modelType: ModelType.BOOL,
            label: T.admin.dashboards.panels.types.map.hidecontrols
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.autoSelectFirstSignal,
            modelType: ModelType.BOOL,
            label: T.admin.dashboards.panels.types.map.autoselect
          }
        ]
      }
    ]
  }
];

const optionalSignalModels: ModelDefinition<SignalInputType>[] = [
  {
    key: (input) => input.displayName,
    modelType: ModelType.TEXT,
    label: T.signals.displayname,
    placeholder: T.signals.displaynameplaceholder
  }
];

export const LocationMapPanelData = {
  migrations: typedMigrations,
  helpPath: HelpPaths.docs.dashboard.dashboards.map,
  sections,
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS_LAST_VALUE]: {
      minItems: 0,
      optionalSignalModels
    }
  },
  emptyTargets: {
    signals: {
      sourceType: DataSourceTypes.SIGNALS_LAST_VALUE,
      useSiblings: true
    }
  }
};

export default React.memo(LocationMapPanel);
