import React, { useEffect, useCallback, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, {
  GeographicalPointResponseModel
} from 'ecto-common/lib/API/APIGen';

const formatCoordinate = (latitude: number, longitude: number) => {
  return longitude.toFixed(2) + ' ' + latitude.toFixed(2);
};

// The user might have modified the weather points through another UI or
// swagger. In this case there might be several weather points associated with a single
// node. So in this case, we have to return all of the weather points that are associated
// with the node. Normal case is just one node though.
const findOriginallyCheckedPoints = (
  points: GeographicalPointResponseModel[],
  currentNodeId: string
) => {
  if (currentNodeId) {
    return _.filter(points, (point) =>
      _.includes(point?.nodeIds, currentNodeId)
    );
  }

  return [];
};

const initialColumns: DataTableColumnProps<GeographicalPointResponseModel>[] = [
  {
    label: T.admin.meteorology.header.name,
    dataKey: 'name',
    flexGrow: 1,
    minWidth: 100,
    linkColumn: true,
    dataFormatter: (value: string) => (
      <>
        <Icons.Weather /> {value}
      </>
    )
  },
  {
    label: T.admin.meteorology.header.coordinates,
    dataKey: 'latitude',
    flexGrow: 0,
    minWidth: 140,
    dataFormatter: (_value, { longitude, latitude }) => {
      return formatCoordinate(latitude, longitude);
    }
  },
  {
    label: T.admin.meteorology.header.numberoflocations,
    dataKey: 'nodeIds',
    flexGrow: 0,
    minWidth: 160,
    align: HorizontalAlignments.CENTER,
    dataFormatter: (nodeIds: string[]) =>
      T.format(
        T.admin.meteorology.value.numberoflocationsformat,
        nodeIds.length
      )
  }
];

interface MeteorologyPointsProps {
  itemLink?(tenantId: string, itemId: string): string;
  onClickRow?(
    item: GeographicalPointResponseModel,
    originallyChecked: GeographicalPointResponseModel[]
  ): void;
  nameOnly?: boolean;
  checkable?: boolean;
  className?: string;
  searchString?: string;
  currentNodeId?: string;
  onPointsLoaded?(points: GeographicalPointResponseModel[]): void;
  checkedIds?: string[];
  setCheckedIds?(checkedIds: string[]): void;
}

const emptyMeteorologyPoints: GeographicalPointResponseModel[] = [];

const MeteorologyPoints = ({
  itemLink,
  onClickRow,
  nameOnly,
  checkable,
  className,
  searchString,
  currentNodeId,
  onPointsLoaded = _.noop,
  checkedIds,
  setCheckedIds = _.noop
}: MeteorologyPointsProps) => {
  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);

  const getQuery = APIGen.Meteorology.getGeographicalPoints.useQuery({});

  useEffect(() => {
    if (getQuery.error) {
      toastStore.addErrorToast(T.admin.meteorology.fetch.failed);
    }
  }, [getQuery.error]);

  const meteorologyPoints = getQuery.data ?? emptyMeteorologyPoints;

  useEffect(() => {
    onPointsLoaded(meteorologyPoints);
  }, [meteorologyPoints, onPointsLoaded]);

  const filteredMeteorologyPoints = useMemo(() => {
    return _.filter(
      meteorologyPoints,
      (item) =>
        item?.name?.toLowerCase().search(searchString?.toLowerCase()) !== -1
    );
  }, [meteorologyPoints, searchString]);

  const originallyCheckedPoints = useMemo(() => {
    return findOriginallyCheckedPoints(meteorologyPoints, currentNodeId);
  }, [currentNodeId, meteorologyPoints]);

  const rowSelected = useCallback(
    (data: GeographicalPointResponseModel) => {
      const wasChecked = _.includes(checkedIds, data.id);

      if (onClickRow) {
        const checkedData = wasChecked ? null : data;
        if (wasChecked) {
          setCheckedIds([]);
        } else {
          setCheckedIds([data.id]);
        }

        onClickRow(checkable ? checkedData : data, originallyCheckedPoints);
      } else if (itemLink) {
        navigate(itemLink(tenantId, data.id));
      }
    },
    [
      checkedIds,
      onClickRow,
      itemLink,
      checkable,
      originallyCheckedPoints,
      setCheckedIds,
      navigate,
      tenantId
    ]
  );

  const columns: DataTableColumnProps<GeographicalPointResponseModel>[] =
    useMemo(() => {
      let _columns = initialColumns;
      if (nameOnly) {
        _columns = [initialColumns[0]];
      }

      if (checkable) {
        _columns = [
          {
            dataKey: 'id',
            width: 34,
            flexGrow: 0,
            flexShrink: 0,
            dataFormatter: (id) => (
              <Checkbox checked={_.includes(checkedIds, id)} />
            )
          },
          ..._columns
        ];
      }

      return _columns;
    }, [checkable, checkedIds, nameOnly]);

  return (
    <DataTable<GeographicalPointResponseModel>
      className={className}
      disableHeader={itemLink == null}
      isLoading={getQuery.isLoading}
      onClickRow={rowSelected}
      data={filteredMeteorologyPoints}
      columns={columns}
    />
  );
};

export default React.memo(MeteorologyPoints);
