import {
  LOCATION_CHANGE,
  SET_EQUIPMENT_TYPES,
  SET_NODE_TAGS,
  SET_UI_LANGUAGE,
  TOGGLE_SIDEBAR_EXPANDED,
  SET_CURRENT_NODE,
  SET_LOCATION_PICKER_OPEN,
  SET_SIGNAL_TYPES,
  SET_ENUMS,
  SET_SIGNAL_TYPE_FOLDERS,
  PATCH_EQUIPMENT_TYPES,
  PATCH_SIGNAL_TYPES,
  PATCH_SIGNAL_TYPE_FOLDERS,
  DELETE_NODES_WITH_IDS
} from 'ecto-common/lib/actions/actionTypes';

import { matchPath } from 'react-router-dom';
import _ from 'lodash';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import {
  getLocationPickerOpen,
  getSelectedLanguage,
  setLocationPickerOpen
} from 'ecto-common/lib/utils/localStorageUtil';
import {
  EquipmentTypeResponseModel,
  GetEnumsAndFixedConfigurationsResponseModel,
  GridType,
  SignalTypeFolderResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';

const initialLanguage = getSelectedLanguage();

type GeneralState = {
  equipmentTypes: EquipmentTypeResponseModel[];
  equipmentTypesMap: Record<string, EquipmentTypeResponseModel>;
  locationPickerOpen: boolean;
  isAdmin: boolean;
  nodeId: string;
  gridTypes: GridType[];
  signalTypes: SignalTypeResponseModel[];
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalTypesNameMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypes: UnitResponseModel[];
  signalUnitTypesMap: Record<string, UnitResponseModel>;
  signalTypeFolders: SignalTypeFolderResponseModel[];
  signalTypeFoldersMap: Record<string, SignalTypeFolderResponseModel>;
  sideBarExpanded: boolean;
  language: string;
  enums: GetEnumsAndFixedConfigurationsResponseModel;
  nodeTags: string[];
  pathName: string;
};

const initialState: GeneralState = {
  equipmentTypes: [],
  equipmentTypesMap: {},
  locationPickerOpen: false,
  isAdmin: process.env.APP_NAME === 'admin',
  nodeId: null,
  gridTypes: [],
  signalTypes: [],
  signalTypesMap: {},
  signalTypesNameMap: {},
  signalUnitTypes: [],
  signalUnitTypesMap: {},
  signalTypeFolders: [],
  signalTypeFoldersMap: {},
  sideBarExpanded: true,
  language: initialLanguage,
  enums: null,
  nodeTags: null,
  pathName: null
};

type EquipmentRouteParams = 'tenantId' | 'nodeId';

type GeneralAction =
  | {
      type: typeof TOGGLE_SIDEBAR_EXPANDED;
      payload: unknown;
    }
  | {
      type: typeof SET_UI_LANGUAGE;
      payload: string;
    }
  | {
      type: typeof LOCATION_CHANGE;
      payload: string;
    }
  | {
      type: typeof SET_EQUIPMENT_TYPES;
      payload: EquipmentTypeResponseModel[];
    }
  | {
      type: typeof SET_LOCATION_PICKER_OPEN;
      payload: { locationPickerOpen: boolean };
    }
  | {
      type: typeof SET_NODE_TAGS;
      payload: { nodeTags: string[] };
    }
  | {
      type: typeof SET_SIGNAL_TYPES;
      payload: SignalTypeResponseModel[];
    }
  | {
      type: typeof SET_SIGNAL_TYPE_FOLDERS;
      payload: SignalTypeFolderResponseModel[];
    }
  | {
      type: typeof SET_ENUMS;
      payload: {
        enums: GetEnumsAndFixedConfigurationsResponseModel;
      };
    }
  | {
      type: typeof SET_CURRENT_NODE;
      payload: { nodeId: string };
    }
  | {
      type: typeof PATCH_EQUIPMENT_TYPES;
      payload: EquipmentTypeResponseModel[];
    }
  | {
      type: typeof PATCH_SIGNAL_TYPES;
      payload: {
        editedItems: SignalTypeResponseModel[];
        deletedItems: string[];
      };
    }
  | {
      type: typeof PATCH_SIGNAL_TYPE_FOLDERS;
      payload: {
        editedItems: SignalTypeFolderResponseModel[];
        deletedItems: string[];
      };
    }
  | {
      type: typeof DELETE_NODES_WITH_IDS;
      payload: string[];
    };

function getInitialState(): GeneralState {
  return {
    ...initialState,
    locationPickerOpen: getLocationPickerOpen()
  };
}

function general(
  state = getInitialState(),
  action: GeneralAction
): GeneralState {
  switch (action.type) {
    case TOGGLE_SIDEBAR_EXPANDED:
      _.defer(() => {
        window.dispatchEvent(new Event('resize'));
      });

      return { ...state, sideBarExpanded: !state.sideBarExpanded };
    case SET_UI_LANGUAGE:
      return { ...state, language: action.payload };
    case LOCATION_CHANGE: {
      const pathName = action.payload;

      if (pathName === state.pathName) {
        return state;
      }

      // TODO: This equipment ID route handling should be done better
      let match = matchPath<EquipmentRouteParams, string>(
        '/:tenantId/home/:nodeId/dashboard/*',
        pathName
      );

      if (match && state.isAdmin) {
        // Only admin app has equipment on dashboard
        return {
          ...state,
          pathName,
          nodeId: match.params.nodeId
        };
      }

      match = matchPath<EquipmentRouteParams, string>(
        '/:tenantId/home/:nodeId/signalproviders/*',
        pathName
      );

      if (match) {
        return {
          ...state,
          pathName,
          nodeId: match.params.nodeId
        };
      }

      match = matchPath<EquipmentRouteParams, string>(
        '/:tenantId/home/:nodeId/*',
        pathName
      );

      if (match) {
        return {
          ...state,
          pathName,
          nodeId: match.params.nodeId
        };
      }

      return state;
    }
    case SET_EQUIPMENT_TYPES:
      action.payload.sort((a, b) => a.name.localeCompare(b.name));

      return Object.assign({}, state, {
        equipmentTypes: action.payload,
        equipmentTypesMap: _.keyBy(action.payload, 'equipmentTypeId')
      });
    // TODO: Move this from global state?
    case PATCH_EQUIPMENT_TYPES: {
      const typesToPatch = action.payload;
      const equipmentTypes = state.equipmentTypes.slice();

      typesToPatch.forEach((equipmentType) => {
        const index = equipmentTypes.findIndex(
          (eqt) => eqt.equipmentTypeId === equipmentType.equipmentTypeId
        );
        if (index !== -1) {
          equipmentTypes[index] = equipmentType;
        } else {
          equipmentTypes.push(equipmentType);
        }
      });

      return {
        ...state,
        equipmentTypes,
        equipmentTypesMap: _.keyBy(equipmentTypes, 'equipmentTypeId')
      };
    }
    case SET_LOCATION_PICKER_OPEN: {
      const { locationPickerOpen } = action.payload;
      _.defer(() => {
        window.dispatchEvent(new Event('resize'));
      });

      setLocationPickerOpen(locationPickerOpen);

      return {
        ...state,
        locationPickerOpen
      };
    }
    case SET_NODE_TAGS:
      return {
        ...state,
        nodeTags: sortByLocaleCompare(action.payload.nodeTags)
      };
    case SET_SIGNAL_TYPES:
      return {
        ...state,
        signalTypes: action.payload,
        signalTypesMap: _.keyBy(action.payload, 'id'),
        signalTypesNameMap: _.keyBy(action.payload, 'name')
      };
    case SET_SIGNAL_TYPE_FOLDERS:
      return {
        ...state,
        signalTypeFolders: action.payload,
        signalTypeFoldersMap: _.keyBy(action.payload, 'id')
      };
    case PATCH_SIGNAL_TYPES: {
      const newIds = _.map(action.payload.editedItems, 'id');
      const deletedIds = action.payload.deletedItems;
      const newTypes = _.reject<SignalTypeResponseModel>(
        state.signalTypes,
        (type) => newIds.includes(type.id) || deletedIds.includes(type.id)
      ).concat(action.payload.editedItems);

      return {
        ...state,
        signalTypes: newTypes,
        signalTypesMap: _.keyBy(newTypes, 'id'),
        signalTypesNameMap: _.keyBy(newTypes, 'name')
      };
    }
    case PATCH_SIGNAL_TYPE_FOLDERS: {
      const newIds = _.map(action.payload.editedItems, 'id');
      const deletedIds = action.payload.deletedItems;

      const newFolders = _.reject<SignalTypeFolderResponseModel>(
        state.signalTypeFolders,
        (type) => newIds.includes(type.id) || deletedIds.includes(type.id)
      ).concat(action.payload.editedItems);
      return {
        ...state,
        signalTypeFolders: newFolders,
        signalTypeFoldersMap: _.keyBy(newFolders, 'id')
      };
    }
    case SET_ENUMS: {
      const enums: GetEnumsAndFixedConfigurationsResponseModel =
        action.payload.enums;
      // TODO: Remove these
      delete enums.connectionModbusConfigDefaults.id;
      enums.toolTypes = sortByLocaleCompare(enums.toolTypes);

      return {
        ...state,
        enums,
        signalUnitTypes: sortByLocaleCompare(enums.units, 'name'),
        signalUnitTypesMap: _.keyBy(enums.units, 'id')
      };
    }
    case SET_CURRENT_NODE: {
      const { nodeId } = action.payload;
      return { ...state, nodeId };
    }
    default:
      return state;
  }
}

export default general;
