import React from 'react';
import classNames from 'classnames';
import parse, { Element } from 'html-react-parser';
import styles from './markdown.module.css';
import path from 'path-browserify';
import _ from 'lodash';
import { DataNode } from 'domhandler';

const ROOT_PATH = 'docs/';

interface MarkdownContentProps {
  currentPageId?: string;
  markdownFile?: string;
  onClick(docName: string): void;
}

const MarkdownContent = ({
  currentPageId,
  markdownFile,
  onClick
}: MarkdownContentProps) => {
  return (
    <div className={classNames(styles.markdownContainer, styles.markdown)}>
      {markdownFile && (
        <>
          {parse(markdownFile, {
            replace: (domNode) => {
              if (
                domNode instanceof Element &&
                domNode.attribs &&
                domNode.name === 'a'
              ) {
                const href = domNode.attribs.href;

                /* Replaces links to internal markdown docs to prevent page reload. */
                if (href) {
                  let url = null;

                  try {
                    url = new URL(href);
                  } catch {
                    // Do nothing
                  }

                  const firstChild = domNode.children?.[0];
                  let value = null;

                  if (firstChild instanceof DataNode) {
                    value = firstChild.data;
                  } else if ('nodeValue' in firstChild) {
                    value = firstChild.nodeValue as string;
                  }

                  if (!url) {
                    if (_.startsWith(href, '#')) {
                      return <a href={href}>{value}</a>;
                    }

                    // Replace href by either using relative or absolute path
                    const fileName =
                      _.startsWith(href, ROOT_PATH) || currentPageId == null
                        ? href
                        : path.join(path.dirname(currentPageId), href);
                    return (
                      <span
                        className={styles.markdownLink}
                        onClick={() => onClick(fileName)}
                      >
                        {value}
                      </span>
                    );
                  }

                  if (url.hash && !url.host) {
                    return <a href={url.hash}>{value}</a>;
                  }

                  /* Making sure that all other links opens a new tab. */
                  return (
                    <a href={href} target={'_blank'}>
                      {value}
                    </a>
                  );
                }
              }
            }
          })}
        </>
      )}
    </div>
  );
};

export default React.memo(MarkdownContent);
