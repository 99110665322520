import React, {
  useCallback,
  useEffect,
  useMemo,
  useContext,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import _ from 'lodash';
import { LocationTreeViewNodeWithChildren } from './LocationTreeViewRow';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useNavigate } from 'react-router-dom';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import APIGen, { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import dimensions from 'ecto-common/lib/styles/dimensions';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useStore } from 'zustand';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { ROOT_NODE_ID } from '../constants/index';
import { NodeTreeStoreContext } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';
import Icons from 'ecto-common/lib/Icons/Icons';
import { DeviceFilled } from 'ecto-common/lib/Icon/svg/Device';

type NodeWithParentId = {
  nodeId: string;
  parentId?: string;
};

export function getNodeParents<T extends NodeWithParentId>(
  firstParentId: string,
  getNode: (nodeId: string) => T
) {
  let currentParentId = firstParentId;

  let foundAll = true;
  let parents: T[] = [];

  // Check for circular references
  const addedParents: Record<string, boolean> = {};

  while (currentParentId != null) {
    const parent = getNode(currentParentId);

    if (parent != null && !addedParents[parent.nodeId]) {
      parents = [...parents, parent];

      addedParents[parent.nodeId] = true;
      currentParentId = parent.parentId;
    } else {
      foundAll = false;
      break;
    }
  }

  return [foundAll, parents] as const;
}

export function updateGetNodeCache(
  nodes: NodeV2ResponseModel[],
  allNodesMap: Record<string, NodeV2ResponseModel>,
  contextSettings: ApiContextSettings,
  queryClient: QueryClient
) {
  for (const node of nodes) {
    const queryKey = APIGen.NodesV2.getNodesByIds.path(contextSettings, {
      nodeIds: [node.nodeId]
    });
    if (!queryClient.getQueryData(queryKey)) {
      const [foundAllParents, allParents] = getNodeParents(
        node.parentId,
        (reqParentId) => {
          return allNodesMap[reqParentId];
        }
      );

      if (foundAllParents) {
        queryClient.setQueryData(queryKey, {
          nodes: [node],
          parents: allParents.map((parent) => {
            return {
              nodeId: parent.nodeId,
              name: parent.name,
              parentId: parent.parentId
            };
          })
        });
      } else {
        console.error('Unable to find parent for node', node.nodeId);
      }
    }
  }
}

function updateGetNodeHierachyCache(
  nodes: NodeV2ResponseModel[],
  nodeId: string,
  contextSettings: ApiContextSettings,
  queryClient: QueryClient
) {
  const currentNode = nodes.find((node) => node.nodeId === nodeId);

  let currentParentId = currentNode?.parentId;

  let currentNodes = _.cloneDeep(nodes);

  while (currentParentId) {
    const nodesWithSameParent = currentNodes.filter(
      (node) => node.parentId === currentParentId
    );

    for (const node of nodesWithSameParent) {
      const queryKey = APIGen.NodesV2.getNodeWithAncestorsAndSiblings.path(
        contextSettings,
        {
          nodeId: node.nodeId
        }
      );

      if (!queryClient.getQueryData(queryKey)) {
        queryClient.setQueryData(queryKey, currentNodes);
      }
    }

    const parent = currentNodes.find((node) => node.nodeId === currentParentId);

    currentParentId = parent?.parentId;

    currentNodes = currentNodes.filter(
      (node) => !nodesWithSameParent.includes(node)
    );
  }
}

const queryOptions = {
  staleTime: 1000 * 60 * 5
};

export const useExpandedTree = ({
  nodeId,
  nodes
}: {
  nodeId: string;
  nodes: { nodeId?: string; parentId?: string }[];
}): [
  Record<string, boolean>,
  Dispatch<SetStateAction<Record<string, boolean>>>
] => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const currentNode = nodes.find((node) => node.nodeId === nodeId);
    setExpanded?.((oldExpanded) => {
      if (currentNode != null && oldExpanded[currentNode.nodeId] == null) {
        const newExpanded = { ...oldExpanded };
        newExpanded[currentNode.nodeId] = true;

        const addedParents: Record<string, boolean> = {};

        let parentId = currentNode.parentId;
        while (parentId != null && !addedParents[parentId]) {
          newExpanded[parentId] = true;
          addedParents[parentId] = true;
          parentId = nodes.find((node) => node.nodeId === parentId)?.parentId;
        }

        return newExpanded;
      }
      return oldExpanded;
    });
  }, [nodes, nodeId]);

  return [expanded, setExpanded];
};

export default function useNodeTree(
  nodeId: string,
  onNavigateToRootNode: (rootNode: NodeV2ResponseModel) => void,
  filterNodes?: (node: NodeV2ResponseModel) => boolean
) {
  const nodeTreeStore = useContext(NodeTreeStoreContext);

  const allNodes = useStore(nodeTreeStore, (store) => store.allNodes);
  const allNodesMap = useStore(nodeTreeStore, (store) => store.allNodesMap);
  const addNodes = useStore(nodeTreeStore, (store) => store.addNodes);
  const emptyChildren = useStore(nodeTreeStore, (store) => store.emptyChildren);

  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const { tenantId } = useContext(TenantContext);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const existingNode = allNodesMap[nodeId];
  const initialQuery = APIGen.NodesV2.getNodeWithAncestorsAndSiblings.useQuery(
    {
      nodeId
    },
    {
      ...queryOptions,
      enabled:
        nodeId != null &&
        nodeId !== 'undefined' &&
        nodeId !== ROOT_NODE_ID &&
        (existingNode == null ||
          // If we're looking at a root node it was fetched via the root level nodes query, but its children are
          // not fetched via that query. Trigger the this query to do so.
          (existingNode.hasChildren &&
            existingNode.parentId == null &&
            allNodes.find((x) => x.parentId === nodeId) == null))
    }
  );

  useEffect(() => {
    if (nodeId === ROOT_NODE_ID && rootLevelNodes.length > 0) {
      addNodes(rootLevelNodes);
    }
  }, [addNodes, nodeId, rootLevelNodes]);

  useEffect(() => {
    if (initialQuery.data) {
      if (initialQuery.data.length === 0) {
        const rootLevelNode = rootLevelNodes[0];
        if (rootLevelNode) {
          onNavigateToRootNode?.(rootLevelNode);
        }
      }

      addNodes(initialQuery.data);
    }
  }, [
    addNodes,
    contextSettings,
    filterNodes,
    initialQuery.data,
    navigate,
    nodeId,
    onNavigateToRootNode,
    rootLevelNodes,
    tenantId
  ]);

  useEffect(() => {
    if (initialQuery.data) {
      const newAllNodesMap = _.keyBy(
        allNodes.concat(initialQuery.data ?? []),
        (node) => node.nodeId
      );

      updateGetNodeCache(
        initialQuery.data ?? [],
        newAllNodesMap,
        contextSettings,
        queryClient
      );
      updateGetNodeHierachyCache(
        initialQuery.data ?? [],
        nodeId,
        contextSettings,
        queryClient
      );
    }
  }, [allNodes, contextSettings, initialQuery.data, nodeId, queryClient]);

  const loadChildrenMutation = APIGen.NodesV2.getNodeChildren.useMutation({
    onSuccess: (data, args) => {
      updateGetNodeCache(data, allNodesMap, contextSettings, queryClient);

      setLoadingState((oldState) => {
        return {
          ...oldState,
          [_.head(args.nodeIds)]: false
        };
      });

      addNodes(data);
    }
  });

  const childrenAreEmpty = useCallback(
    (parentId: string) => {
      return emptyChildren[parentId];
    },
    [emptyChildren]
  );

  const hasLoadedChildren = useCallback(
    (parentId: string) => {
      return allNodes.some((node) => node.parentId === parentId);
    },
    [allNodes]
  );

  const loadChildren = useCallback(
    (newNodeId: string) => {
      setLoadingState((oldState) => {
        return {
          ...oldState,
          [newNodeId]: true
        };
      });

      loadChildrenMutation.mutateAsync({
        nodeIds: [newNodeId]
      });
    },
    [loadChildrenMutation]
  );

  // Assume nodes has children until we've loaded them and know for sure
  const nodeHasChildren = useCallback(
    (node: LocationTreeViewNodeWithChildren) => {
      return !childrenAreEmpty(node.nodeId);
    },
    [childrenAreEmpty]
  );
  const filteredNodes = useMemo(() => {
    if (filterNodes) {
      return sortByLocaleCompare(allNodes.filter(filterNodes), 'name');
    }

    return sortByLocaleCompare(allNodes, 'name');
  }, [allNodes, filterNodes]);
  const [expanded, setExpanded] = useExpandedTree({
    nodeId,
    nodes: filteredNodes
  });

  // Used when the cache is cleared completely (for instance when parent is changed)
  if (allNodes.length === 0 && !_.isEmpty(expanded)) {
    setExpanded({});
  }

  const onExpandedStateChange = useCallback(
    (expandedNodeId: string, nodeIsExpanded: boolean) => {
      if (!hasLoadedChildren(expandedNodeId)) {
        loadChildren(expandedNodeId);
      }

      setExpanded((oldExpanded) => {
        return {
          ...oldExpanded,
          [expandedNodeId]: nodeIsExpanded
        };
      });
    },
    [hasLoadedChildren, loadChildren, setExpanded]
  );

  const renderRowSideIcons = useCallback(
    (node: LocationTreeViewNodeWithChildren) => {
      let loadingIcon: React.ReactNode = null;

      if (loadingState[node.nodeId]) {
        loadingIcon = (
          <div
            style={{
              flexShrink: 0,
              marginLeft: dimensions.smallMargin,
              marginRight: 10
            }}
          >
            <Spinner
              size={SpinnerSize.TREE_VIEW}
              color={node.nodeId === nodeId ? 'white' : 'green'}
            />
          </div>
        );
      }

      return (
        <>
          <div style={{ flex: 1 }} />
          <div
            style={{
              minWidth: 30,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            {loadingIcon}
          </div>
        </>
      );
    },
    [loadingState, nodeId]
  );

  const nodeTraitsByNodeId = useMemo(() => {
    const ret: Record<string, string[]> = {};

    for (const node of allNodes) {
      ret[node.nodeId] = node.nodeTraits.map((trait) => trait.nodeTraitId);
    }

    return ret;
  }, [allNodes]);

  const renderRowIcons = useCallback(
    (node: LocationTreeViewNodeWithChildren) => {
      const traits = nodeTraitsByNodeId[node.nodeId];
      if (traits != null) {
        if (traits.includes(NodeTraitIds.ENERGY_MANAGER)) {
          return <Icons.EnergyManager />;
        } else if (traits.includes(NodeTraitIds.EQUIPMENT)) {
          return <DeviceFilled />;
        }
      }
      return null;
    },
    [nodeTraitsByNodeId]
  );

  return {
    nodes: filteredNodes,
    isLoadingHierarchy: initialQuery.isLoading,
    loadingQueryHasError: initialQuery.isError,
    loadingState,
    nodeHasChildren,
    onExpandedStateChange,
    expanded,
    setExpanded,
    renderRowSideIcons,
    renderRowIcons
  };
}
