import React, { useCallback, useContext, useMemo } from 'react';

import T from 'ecto-common/lib/lang/Language';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import HelpPaths from 'ecto-common/help/tocKeys';

import { EditEquipmentToolsActions } from 'js/modules/editEquipmentTools/editEquipmentTools';
import { useAdminDispatch } from 'js/reducers/storeAdmin';
import { LinearOptimisationProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useQueryClient } from '@tanstack/react-query';

interface EditLinearOptimisationDialogProps {
  isOpen?: boolean;
  linearOptimisation?: LinearOptimisationProviderResponseModel;
}

const EditLinearOptimisationDialog = ({
  isOpen,
  linearOptimisation
}: EditLinearOptimisationDialogProps) => {
  const dispatch = useAdminDispatch();
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const updateTool = useCallback(() => {
    dispatch(EditEquipmentToolsActions.setEditableLinearOptimisation(null));
    dispatch(
      EditEquipmentToolsActions.updateLinearOptimisation(
        contextSettings,
        linearOptimisation,
        queryClient
      )
    );
  }, [dispatch, contextSettings, linearOptimisation, queryClient]);

  const title = useMemo(() => {
    if (linearOptimisation) {
      if (linearOptimisation?.signalProviderId) {
        return T.admin.equipment.editlinearoptimisation;
      }

      return T.admin.equipment.addnewlinearoptimisation;
    }

    return '';
  }, [linearOptimisation]);

  const onModalClose = useCallback(() => {
    dispatch(EditEquipmentToolsActions.setEditableLinearOptimisation(null));
  }, [dispatch]);

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const value = parseInt(event.target.value, 10);
      dispatch(
        EditEquipmentToolsActions.setEditableLinearOptimisation({
          ...linearOptimisation,
          numberOfPoints: isNaN(value) || value == null ? 0 : value
        })
      );
    },
    [dispatch, linearOptimisation]
  );

  const disableActionButton =
    !linearOptimisation || linearOptimisation.numberOfPoints === 0;

  return (
    <ActionModal
      onModalClose={onModalClose}
      isOpen={isOpen}
      title={title}
      headerIcon={linearOptimisation?.signalProviderId ? Icons.Edit : Icons.Add}
      disableActionButton={disableActionButton}
      onConfirmClick={updateTool}
      helpPath={HelpPaths.docs.admin.manage.equipment.tools.linear_optimization}
    >
      {linearOptimisation && (
        <KeyValueLine>
          <KeyValueInput
            value={linearOptimisation.numberOfPoints + ''}
            onChange={onInputChange}
            onEnterKeyPressed={updateTool}
            keyText={T.admin.equipment.numberofpoints}
          />
        </KeyValueLine>
      )}

      {!linearOptimisation && <span />}
    </ActionModal>
  );
};

export default EditLinearOptimisationDialog;
