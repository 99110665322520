import React, { useContext, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/EnergyManagers/EnergyManagersPage.module.css';
import StatusCircle from 'ecto-common/lib/StatusCircle/StatusCircle';
import {
  STATUS_CIRCLE_OK,
  STATUS_CIRCLE_ERROR
} from 'ecto-common/lib/StatusCircle/StatusCircle';
import { relativeDateString } from 'js/components/EnergyManagers/Models/formatting';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { IoTQueryTypes } from 'js/components/EnergyManagers/energyManagersUtil';
import { NavLink } from 'react-router-dom';

import { getEquipmentPageUrl } from 'js/utils/linkUtil';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import _ from 'lodash';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import dimensions from 'ecto-common/lib/styles/dimensions';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  IoTDeviceDeviceResponseModel,
  IoTDevicesSummaryResponseModel,
  IoTDeviceWithDeviceResponseModel
} from 'ecto-common/lib/API/APIGen';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import { DeviceListFilterParams } from 'js/components/EnergyManagers/DeviceListFilter';
import { DeviceProvisioningSummary } from 'ecto-common/lib/types/EctoCommonTypes';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import { useNodes } from 'ecto-common/lib/hooks/useCurrentNode';

const DEVICE_COLUMNS: DataTableColumnProps<IoTDeviceWithDeviceResponseModel>[] =
  [
    {
      label: T.admin.provisioning.header.online,
      dataKey: 'isOnline',
      minWidth: 50,
      maxWidth: 60,
      dataFormatter: (isOnline) => (
        <div>
          <StatusCircle
            status={isOnline ? STATUS_CIRCLE_OK : STATUS_CIRCLE_ERROR}
          />
        </div>
      )
    },
    {
      label: T.admin.provisioning.header.name,
      dataKey: 'ioTDevice.deviceName',
      minWidth: 90,
      maxWidth: 270,
      dataFormatter: (deviceName: string, { heartbeatTimestamp }, _index) => {
        return (
          <div>
            <div className={styles.nameContainer}>
              <div>
                <CopyToClipboardTooltip valueToCopy={deviceName}>
                  {deviceName}
                </CopyToClipboardTooltip>
              </div>

              <br />
            </div>

            <div className={styles.dateSubtitle}>
              {T.format(
                T.admin.provisioning.lastheartbeatformat,
                relativeDateString(heartbeatTimestamp)
              )}
            </div>
          </div>
        );
      }
    },
    {
      label: T.admin.provisioning.header.tags,
      dataKey: 'ioTDevice.tags',
      minWidth: 100,
      maxWidth: 350,
      dataFormatter: (_tags: string[]) => {
        return (
          <div className={styles.tagContainer}>
            <TagsGroup tags={_tags} />
          </div>
        );
      }
    },
    {
      label: T.admin.provisioning.header.type,
      dataKey: 'ioTDevice.manufacturer',
      minWidth: 100,
      maxWidth: 200,
      dataFormatter: (manufacturer: string, device) => {
        const { model } = device.ioTDevice;

        return (
          <Tooltip text={manufacturer + ' ' + model} multiline>
            <div className={styles.limitLine}>{manufacturer}</div>
            <div className={styles.limitLine}>{model}</div>
          </Tooltip>
        );
      }
    },
    {
      label: T.admin.provisioning.header.notes,
      dataKey: 'ioTDevice.notes',
      flexGrow: 1,
      minWidth: 100,
      truncateText: true
    }
  ];

// Just an approximation
export const ROW_HEIGHT = 60;

interface DeviceListTableProps {
  filterParams?: DeviceListFilterParams;
  devices?: IoTDeviceWithDeviceResponseModel[];
  summary?: DeviceProvisioningSummary | IoTDevicesSummaryResponseModel;
  queryType?: string;
  onPageChange(page: number): void;
  page?: number;
  pageSize?: number;
  useAsSelector?: boolean;
  onDeviceSelected?(
    device: IoTDeviceWithDeviceResponseModel,
    row: number,
    column: number
  ): void;
  isLoading?: boolean;
  hasError?: boolean;
}

const DeviceListTable = ({
  filterParams,
  devices,
  summary,
  onPageChange,
  pageSize,
  useAsSelector,
  onDeviceSelected,
  isLoading,
  hasError
}: DeviceListTableProps) => {
  const { queryType, page } = filterParams;
  const { tenantId } = useContext(TenantContext);

  const referencedNodes = useNodes(
    _.compact(_.uniq(_.flatMap(devices, (d) => d.device?.equipmentIds)))
  );

  const columns = useMemo(() => {
    if (queryType !== IoTQueryTypes.UNPAIRED) {
      const newColumns = [...DEVICE_COLUMNS];

      newColumns.splice(3, 0, {
        label: T.admin.provisioning.header.locations,
        dataKey: 'device',
        minWidth: 100,
        flexGrow: 1,
        dataFormatter: (device: IoTDeviceDeviceResponseModel) => {
          return (
            <div className={styles.tagContainer}>
              {device &&
                device.equipmentIds.map((equipmentId, idx) => {
                  const node = referencedNodes.nodes.find(
                    (n) => n.nodeId === equipmentId
                  );
                  const isLast = idx === device.equipmentIds.length - 1;
                  const suffix = isLast ? '' : ', ';
                  return (
                    node && (
                      <NavLink
                        to={getEquipmentPageUrl(
                          tenantId,
                          equipmentId,
                          'iotdevice'
                        )}
                        key={'nodelink_' + node.nodeId + '_' + idx}
                      >
                        {node.name}
                        {suffix}
                      </NavLink>
                    )
                  );
                })}
            </div>
          );
        }
      });

      return newColumns;
    }

    return DEVICE_COLUMNS;
  }, [queryType, referencedNodes.nodes, tenantId]);

  const shouldShowLoading = _.isEmpty(devices) && isLoading;

  const totalPages = Math.ceil(summary?.devicesCount / pageSize);

  return (
    <div>
      <LoadingContainer isLoading={shouldShowLoading} showSpinner={false}>
        <div className={styles.summary}>
          <div className={styles.summaryItem}>
            <label>{T.admin.provisioning.summary.online}</label>
            <span className={styles.summaryText}>
              {summary?.onlineDevicesCount}
            </span>
          </div>

          <div className={styles.summaryItem}>
            <label>{T.admin.provisioning.summary.unprovisioned}</label>
            <span className={styles.summaryText}>
              {summary?.unprovisionedDevicesCount}
            </span>
          </div>

          <div className={styles.summaryItem}>
            <label>{T.admin.provisioning.summary.total}</label>
            <span className={styles.summaryText}>{summary?.devicesCount}</span>
          </div>
        </div>
      </LoadingContainer>

      <DataTable<IoTDeviceWithDeviceResponseModel>
        data={devices}
        hasError={hasError}
        columns={columns}
        noDataText={
          useAsSelector
            ? T.admin.provisioning.nofreedevicesdatatext
            : T.admin.provisioning.nodatatext
        }
        isLoading={shouldShowLoading}
        minHeight={ROW_HEIGHT * pageSize + dimensions.tableHeaderHeight}
        onClickRow={onDeviceSelected}
      />

      <PagingFooter
        disableBackNavigation={isLoading}
        disableForwardNavigation={devices.length === 0 || page >= totalPages}
        onPageChange={onPageChange}
        page={page}
        totalPages={totalPages}
      />
    </div>
  );
};

export default React.memo(DeviceListTable);
