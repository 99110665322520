import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import {
  EquipmentTypes,
  getEnergyManagerEquipmentTypeId,
  isEquipmentTypeEnergyManager
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import UUID from 'uuidjs';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import APIGen, {
  AddOrUpdateEnergyManagerByEquipmentTypeRequestModel,
  ConnectionDefaultModbusConfigResponseModel,
  EquipmentTypeResponseModel,
  NodeV2ResponseModel,
  ToolType
} from 'ecto-common/lib/API/APIGen';
import { updateNodeTreeAfterNodeAddedOrUpdated } from 'js/modules/provisioningCommon/provisioningCommon';
import { QueryClient } from '@tanstack/react-query';

const SET_ADD_EQUIPMENT_INIT = 'SET_ADD_EQUIPMENT_INIT';

const SET_ADD_EQUIPMENT_FORM_NAME = 'SET_ADD_EQUIPMENT_FORM_NAME';
const SET_ADD_EQUIPMENT_FORM_DESCRIPTION = 'SET_ADD_EQUIPMENT_FORM_DESCRIPTION';

const SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR =
  'SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR';
const SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID =
  'SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID';
const SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID =
  'SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID';
const SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS =
  'SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS';
const SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID =
  'SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID';

type AddEquipmentReducerProps = {
  name: string;
  description: string;
  deviceEquipmentId: string;
  equipmentTypeId: string;
  selectedToolTypeIds: string[];
  alarmSignalGroupTemplateId: string;
  forceAddEnergyManager: boolean;
  showDeviceSelector: boolean;
  existingEnergyManager: NodeV2ResponseModel;
  devices: NodeV2ResponseModel[];
  equipmentTypes: EquipmentTypeResponseModel[];
  targetNodeId: string;
  isValid: boolean;
};

const initialState: AddEquipmentReducerProps = {
  name: '',
  description: '',
  deviceEquipmentId: null,
  equipmentTypeId: null,
  selectedToolTypeIds: [],
  alarmSignalGroupTemplateId: null,
  forceAddEnergyManager: false,
  showDeviceSelector: false,
  existingEnergyManager: null,
  devices: [],
  equipmentTypes: [],
  targetNodeId: null,
  isValid: false
};

const _checkIsValid = (state: AddEquipmentReducerProps) => {
  if (state.equipmentTypeId == null || isNullOrWhitespace(state.name)) {
    return false;
  }

  if (
    state.equipmentTypeId ===
    getEnergyManagerEquipmentTypeId(state.equipmentTypes)
  ) {
    return true;
  }

  return state.deviceEquipmentId != null;
};

export default createReducer(initialState, {
  [SET_ADD_EQUIPMENT_FORM_NAME]: (state, { name }) => {
    const newState = { ...state, name };
    return { ...newState, isValid: _checkIsValid(newState) };
  },
  [SET_ADD_EQUIPMENT_FORM_DESCRIPTION]: (state, { description }) => {
    return { ...state, description };
  },
  [SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR]: (state, { showDeviceSelector }) => {
    return { ...state, showDeviceSelector };
  },
  [SET_ADD_EQUIPMENT_INIT]: (
    state,
    { forceAddEnergyManager, devices, equipmentTypes, targetNodeId }
  ) => {
    let stateToUse = state;

    stateToUse = initialState;

    if (forceAddEnergyManager) {
      stateToUse = {
        ...stateToUse,
        equipmentTypeId: EquipmentTypes.ENERGY_MANAGER,
        alarmSignalGroupTemplateId:
          AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
      };
    } else if (equipmentTypes.length > 0 && devices.length > 0) {
      stateToUse = {
        ...stateToUse,
        equipmentTypeId: equipmentTypes[0].equipmentTypeId,
        deviceEquipmentId: devices[0].nodeId
      };
    }

    return {
      ...stateToUse,
      forceAddEnergyManager,
      devices,
      equipmentTypes,
      targetNodeId,
      isValid: _checkIsValid(stateToUse)
    };
  },
  [SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID]: (state, { deviceEquipmentId }) => {
    const newState = { ...state, deviceEquipmentId };
    return { ...newState, isValid: _checkIsValid(newState) };
  },
  [SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID]: (state, { equipmentTypeId }) => {
    const newState = { ...state, equipmentTypeId };
    let { alarmSignalGroupTemplateId } = state;

    if (equipmentTypeId === EquipmentTypes.ENERGY_MANAGER) {
      alarmSignalGroupTemplateId =
        AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS;
    } else if (
      alarmSignalGroupTemplateId ===
      AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
    ) {
      alarmSignalGroupTemplateId = null;
    }

    return {
      ...newState,
      alarmSignalGroupTemplateId,
      isValid: _checkIsValid(newState)
    };
  },
  [SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS]: (
    state,
    { selectedToolTypeIds }
  ) => {
    return { ...state, selectedToolTypeIds };
  },
  [SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID]: (
    state,
    { alarmSignalGroupTemplateId }
  ) => {
    return { ...state, alarmSignalGroupTemplateId };
  }
});

export const AddEquipmentFormActions = {
  setName: createAction(SET_ADD_EQUIPMENT_FORM_NAME, 'name'),
  setDescription: createAction(
    SET_ADD_EQUIPMENT_FORM_DESCRIPTION,
    'description'
  ),
  setShowDeviceSelector: createAction(
    SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR,
    'showDeviceSelector'
  ),
  init: createAction(
    SET_ADD_EQUIPMENT_INIT,
    'forceAddEnergyManager',
    'devices',
    'equipmentTypes',
    'targetNodeId'
  ),
  reset: () => AddEquipmentFormActions.init(false, [], [], null, null),
  setDeviceEquipmentId: createAction(
    SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID,
    'deviceEquipmentId'
  ),
  setEquipmentTypeId: createAction(
    SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID,
    'equipmentTypeId'
  ),
  setSelectedToolTypeIds: createAction(
    SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS,
    'selectedToolTypeIds'
  ),
  setAlarmSignalGroupTemplateId: createAction(
    SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID,
    'alarmSignalGroupTemplateId'
  )
};

export async function addEquipmentPromise({
  connectionModbusConfigDefaults,
  equipmentTypes,
  name,
  description,
  targetNodeId,
  equipmentTypeId,
  deviceEquipmentId,
  selectedToolTypeIds,
  alarmSignalGroupTemplateId,
  contextSettings,
  existingEnergyManager,
  queryClient,
  addNodes,
  allNodesMap
}: {
  contextSettings: ApiContextSettings;
  existingEnergyManager: NodeV2ResponseModel;
  description: string;
  connectionModbusConfigDefaults: ConnectionDefaultModbusConfigResponseModel;
  equipmentTypes: EquipmentTypeResponseModel[];
  name: string;
  targetNodeId: string;
  equipmentTypeId: string;
  deviceEquipmentId: string;
  selectedToolTypeIds: string[];
  alarmSignalGroupTemplateId: string;
  queryClient: QueryClient;
  addNodes: (nodes: NodeV2ResponseModel[]) => void;
  allNodesMap: Record<string, NodeV2ResponseModel>;
}) {
  const isEnergyManager = isEquipmentTypeEnergyManager(
    equipmentTypeId,
    equipmentTypes
  );

  const sharedBody = {
    name,
    description,
    equipmentTypeId,
    equipmentId: UUID.generate(),
    nodeId: targetNodeId,
    alarmSignalGroupTemplateId
  };

  let nodeId: string = null;

  if (isEnergyManager) {
    let deviceId = UUID.generate();

    if (existingEnergyManager) {
      const deviceInfo =
        await APIGen.AdminDevices.getDeviceEquipmentInfo.promise(
          contextSettings,
          {
            EquipmentId: existingEnergyManager.nodeId
          },
          null
        );
      deviceId = deviceInfo.deviceId;
    }

    const emBody: AddOrUpdateEnergyManagerByEquipmentTypeRequestModel = {
      ...sharedBody,
      deviceId,
      connectionModbusConfig: connectionModbusConfigDefaults,
      toolTypes: []
    };

    const res =
      await APIGen.AdminEquipments.addOrUpdateEnergyManagerByEquipmentType.promise(
        contextSettings,
        emBody,
        null
      );

    nodeId = res.equipmentId;
  } else {
    const eqBody = {
      ...sharedBody,
      deviceEquipmentId: deviceEquipmentId,
      toolTypes: selectedToolTypeIds as ToolType[]
    };

    const res =
      await APIGen.AdminEquipments.addOrUpdateEquipmentsByEquipmentTypes.promise(
        contextSettings,
        [eqBody],
        null
      );

    nodeId = res?.[0].equipmentId;
  }

  return await updateNodeTreeAfterNodeAddedOrUpdated(
    contextSettings,
    targetNodeId,
    nodeId,
    queryClient,
    addNodes,
    allNodesMap
  );
}
