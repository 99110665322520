import React, { useCallback, useState, useMemo, useContext } from 'react';
import _ from 'lodash';
import { useProcessMapSignals } from 'ecto-common/lib/ProcessMap/ProcessMapHooks';
import SignalsTableModal from 'ecto-common/lib/SignalsTable/SignalsTableModal';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import styles from 'ecto-common/lib/ProcessMap/ProcessMapView.module.css';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import { useLiveEquipmentSignals } from 'ecto-common/lib/hooks/useLiveEquipmentSignals';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { SignalProvidersDataSourceResult } from 'ecto-common/lib/Dashboard/datasources/SignalProvidersDataSource';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import Icons from 'ecto-common/lib/Icons/Icons';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import ProcessMapEditor from 'ecto-common/lib/ProcessMaps/ProcessMapEditor';
import {
  ProcessMapState,
  initProcessMapState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { useImmer } from 'use-immer';
import T from 'ecto-common/lib/lang/Language';
import { Base64 } from 'js-base64';
import UnsavedChangesDialog from 'ecto-common/lib/UnsavedChangesDialog/UnsavedChangesDialog';
import {
  ProcessMapNewVersionWrapper,
  useMouseActions
} from 'ecto-common/lib/ProcessMap/ProcessMap';
import { useProcessMapLibrary } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorHooks';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import useProcessMapDropdown from 'ecto-common/lib/ProcessMap/useProcessMapDropdown';
import useEquipmentLinkAction from 'ecto-common/lib/ProcessMap/useEquipmentLinkAction';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import fnv from 'fnv-plus';
import useNodeNavigationLinkAction from 'ecto-common/lib/ProcessMap/useNodeNavigationLinkAction';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { nodeIsEquipment } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeTreeStoreContext } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

type ProcessMapPanelConfig = {
  processMap?: string;
};

type ProcessMapPanelEditorProps = Omit<ModelEditorProps, 'rawValue'> & {
  rawValue: string;
};

const initialProcessMapPanelWidth = 400;
const initialProcessMapPanelHeight = 200;

const ProcessMapPanelEditor = ({
  rawValue,
  updateItem
}: ProcessMapPanelEditorProps) => {
  const [isShowingModal, showModal, hideModal] = useSimpleDialogState();
  const { libraryRequest, libraryItems } = useProcessMapLibrary();

  const { tenantId } = useContext(TenantContext);

  const [isShowingConfirmDialog, showConfirmDialog, hideConfirmDialog] =
    useSimpleDialogState();
  const [processMapState, setProcessMapState] = useImmer<ProcessMapState>(
    () => {
      return initProcessMapState(
        rawValue,
        initialProcessMapPanelWidth,
        initialProcessMapPanelHeight
      );
    }
  );

  const onSave = useCallback(
    (dataBase64: string) => {
      updateItem(dataBase64);
    },
    [updateItem]
  );

  const onConfirm = () => {
    const isBothEmpty =
      rawValue == null && processMapState.processMap.objects.length === 0;
    const curState = Base64.encode(JSON.stringify(processMapState.processMap));

    if (!isBothEmpty && curState !== rawValue) {
      showConfirmDialog();
    } else {
      hideModal();
    }
  };

  const onConfirmDialogClose = () => {
    setProcessMapState(initProcessMapState(rawValue));
    hideConfirmDialog();
    hideModal();
  };

  const onConfirmDialogConfirm = () => {
    updateItem(Base64.encode(JSON.stringify(processMapState.processMap)));
    hideConfirmDialog();
    hideModal();
  };

  const nodeTreeStore = useContext(NodeTreeStoreContext);

  return (
    <>
      <KeyValueFixedSelectableInput
        keyText={T.admin.dashboards.panels.customprocessmap}
        value={rawValue ? T.common.clicktoedit : null}
        placeholder={T.common.clicktoedit}
        onClick={showModal}
        isClearable
        onClear={() => {
          updateItem(null);
          setProcessMapState(
            initProcessMapState(
              null,
              initialProcessMapPanelWidth,
              initialProcessMapPanelHeight
            )
          );
        }}
      />
      <ActionModal
        isOpen={isShowingModal}
        onModalClose={hideModal}
        title={T.admin.dashboards.panels.customprocessmap}
        headerIcon={Icons.Edit}
        onConfirmClick={onConfirm}
        isLoading={libraryRequest.isLoading}
        large
        messageBodyClassName={styles.panelEditorBody}
        className={styles.panelEditorModal}
        disableCancel
        actionText={T.common.done}
      >
        {!libraryRequest.isLoading && libraryRequest.isSuccess && (
          <ProcessMapEditor
            processMapState={processMapState}
            setProcessMapState={setProcessMapState}
            onSave={onSave}
            library={libraryItems}
            previewTenantId={tenantId}
            nodeTreeStore={nodeTreeStore}
          />
        )}
      </ActionModal>
      <UnsavedChangesDialog
        isOpen={isShowingConfirmDialog}
        onSave={onConfirmDialogConfirm}
        onCancel={hideConfirmDialog}
        onDiscardChanges={onConfirmDialogClose}
      />
    </>
  );
};

type ProcessMapPanelProps = CustomPanelProps & {
  data?: {
    signalProviders?: SignalProvidersDataSourceResult;
    equipment?: SignalProvidersDataSourceResult;
    processMap?: string;
  };
};

const ProcessMapPanel = ({ data }: ProcessMapPanelProps) => {
  // Backward compatibility for equipment variable
  const providers = data?.equipment ?? data?.signalProviders;

  const node = providers.node;
  const useNode = providers.useNode;
  const providersAreLoading = providers.isLoading;
  const equipmentError = providers.hasError;
  const signalProviders = providers.signalProviders;
  const { setNode } = useContext(DashboardDataContext);

  const [currentEditSignal, setCurrentEditSignal] =
    useState<SignalProviderSignalWithProviderResponseModel>(null);

  const onDoneEditingSignal = useCallback(() => {
    setCurrentEditSignal(null);
  }, []);

  const relevantProviders = useMemo(() => {
    // If node is used, then only use nodes signal providers for signals
    if (useNode) {
      return _.filter(signalProviders, (provider) =>
        _.includes(provider.nodeIds, node?.nodeId)
      );
    }

    return signalProviders;
  }, [node?.nodeId, signalProviders, useNode]);

  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const customProcessMap = useMemo(() => {
    return data?.processMap != null
      ? JSON.parse(Base64.decode(data.processMap))
      : null;
  }, [data.processMap]);

  const {
    image: nodeImage,
    error: signalError,
    signalData,
    isLoading
  } = useProcessMapSignals({
    nodeId: node?.nodeId,
    signalProviders: relevantProviders,
    onlyMappedSignals: true,
    fromDate: null,
    externalDocument: customProcessMap
  });

  const [checksum, image] = useMemo(() => {
    const ret = customProcessMap ?? nodeImage;

    return [fnv.hash(ret).str(), ret] as const;
  }, [customProcessMap, nodeImage]);

  const error = signalError || (equipmentError && T.common.error);

  useLiveEquipmentSignals(
    useMemo(
      () => _.compact([nodeIsEquipment(node) ? node?.nodeId : null]),
      [node]
    )
  );

  const [container, setContainer] = useState<HTMLDivElement>(null);

  const _isLoading = isLoading || providersAreLoading;
  const {
    ProcessMapDropdown: EquipmentDropdown,
    showMenu: showEquipmentMenu,
    hideMenu: hideEquipmentMenu
  } = useProcessMapDropdown(container);

  const {
    ProcessMapDropdown: NodeDropdown,
    showMenu: showNodeMenu,
    hideMenu: hideNodeMenu
  } = useProcessMapDropdown(container);

  const confirmNavigationToNodeId = useCallback(
    (newNodeId: string) => {
      setNode(newNodeId);
    },
    [setNode]
  );

  const onClickNavigateNode = useNodeNavigationLinkAction({
    showMenu: showNodeMenu,
    hideMenu: hideNodeMenu,
    confirmNavigationToNodeId
  });

  const onOpenEquipmentType = useEquipmentLinkAction({
    nodeId: node?.nodeId,
    showMenu: showEquipmentMenu,
    hideMenu: hideEquipmentMenu
  });

  const mouseActions = useMouseActions({
    setCurrentEditSignal,
    setCurrentSignal: _.noop,
    openEquipmentPage: onOpenEquipmentType,
    onNavigateToNodeId: onClickNavigateNode
  });

  const onMouseUpContainer = useCallback(() => {
    hideEquipmentMenu();
    hideNodeMenu();
  }, [hideEquipmentMenu, hideNodeMenu]);

  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className={styles.panelContent}
      onMouseOver={() => {
        setIsHovering(true);
      }}
      onMouseOut={() => {
        setIsHovering(false);
      }}
    >
      {error && <ErrorNotice className={styles.error}>{error}</ErrorNotice>}
      {isLoading && <Spinner />}
      {!error && image == null && _isLoading === false && <NoDataMessage />}
      {!error && image && !isLoading && (
        <div
          ref={setContainer}
          onMouseUp={onMouseUpContainer}
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <ProcessMapNewVersionWrapper
            image={image}
            key={checksum}
            mouseActions={mouseActions}
            signalProviders={signalProviders}
            signalData={signalData}
            signalTypesMap={signalTypesMap}
            signalUnitTypesMap={signalUnitTypesMap}
            isLoading={false}
            zoomButtonVerticalOffset={0}
            showDeleteConnections={false}
            adjustViewportZoom
            isHoveringParent={isHovering}
          />

          <SignalsTableModal
            signal={currentEditSignal}
            otherSignals={signalProviders?.[0]?.signals}
            onDoneEditingSignal={onDoneEditingSignal}
            signalValuesById={signalData}
          />
          <EquipmentDropdown />
          <NodeDropdown />
        </div>
      )}
    </div>
  );
};

const sections: ModelFormSectionType<ProcessMapPanelConfig>[] = [
  {
    lines: [
      {
        models: [
          {
            key: (input) => input.processMap,
            modelType: ModelType.CUSTOM,
            render: (props) => <ProcessMapPanelEditor {...props} />
          }
        ]
      }
    ]
  }
];

export const ProcessMapPanelData = {
  emptyTargets: {
    signalProviders: {
      sourceType: DataSourceTypes.SIGNAL_PROVIDERS
    }
  },
  sections,
  minWidth: 450,
  helpPath: HelpPaths.docs.dashboard.dashboards.process_map
};

export default React.memo(ProcessMapPanel);
