import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';

export const EnergyManagerSubpage = {
  DETAILS: 'details',
  SIGNALS: 'signals',
  DEPLOYMENT: 'deployment',
  CONNECTIONS: 'connections',
  IOTDEVICE: 'iotdevice'
};

export const EquipmentSubpage = {
  NODE: 'node',
  SIGNALS: 'signals',
  TOOLS: 'tools'
};

export const SpaceSubpage = {
  NOTIFICATIONS: 'notifications',
  COMFORT: 'comfort',
  TEMPERATURE_IMPACT: 'tempimpact',
  INTEGRATION_POINTS: 'integrationpoints',
  METEOROLOGY: 'meteorology'
};

export const equipmentSubpages = enumValues(EquipmentSubpage);
export const energyManagerSubpages = enumValues(EnergyManagerSubpage);
