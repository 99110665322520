import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo
} from 'react';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ResourceType } from 'ecto-common/lib/constants';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import { keyValueFixedSelectableMenuComponents } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import SelectNodeDialog from 'ecto-common/lib/SelectNodeDialog/SelectNodeDialog';
import T from 'ecto-common/lib/lang/Language';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import styles from './PreviewNodeToolbarItems.module.css';
import { isNullOrWhitespace } from '../utils/stringUtils';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import { NestedTenantContainerWithoutEventHubService } from 'ecto-common/lib/Application/TenantContainer';
import {
  NodeTreeStoreContext,
  NodeTreeStoreType
} from 'ecto-common/lib/LocationTreeView/NodeTreeStore';
import APIGen from 'ecto-common/lib/API/APIGen';
import { useStore } from 'zustand';

type PreviewNodeToolbarItemsProps = {
  previewNodeId: string;
  setPreviewNodeId: Dispatch<SetStateAction<string>>;
  previewTenantId: string;
  setPreviewTenantId: Dispatch<SetStateAction<string>>;
  nodeTreeStore: NodeTreeStoreType;
};

const PreviewNodeSelector = ({
  previewNodeId,
  setPreviewNodeId,
  disabled,
  isLoading
}: {
  previewNodeId: string;
  setPreviewNodeId: (nodeId: string) => void;
  disabled: boolean;
  isLoading: boolean;
}) => {
  const [showingDialog, showDialog, hideDialog] = useSimpleDialogState();

  const { node: previewNode } = useNode(previewNodeId);
  const label = previewNodeId == null ? null : previewNode?.name;

  const rootLevelNodesQuery = APIGen.NodesV2.getNodeChildren.useQuery(
    {
      nodeIds: []
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false
    }
  );

  const nodeTreeStore = useContext(NodeTreeStoreContext);
  const addNodes = useStore(nodeTreeStore, (store) => store.addNodes);

  const setRootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.setRootLevelNodes
  );

  useEffect(() => {
    if (rootLevelNodesQuery.data) {
      setRootLevelNodes(rootLevelNodesQuery.data);
      addNodes(rootLevelNodesQuery.data);
    }
  }, [addNodes, rootLevelNodesQuery.data, setRootLevelNodes]);

  const nodeIds = useMemo(() => {
    return previewNodeId ? [previewNodeId] : [];
  }, [previewNodeId]);

  const options = useMemo((): GenericSelectOption[] => {
    return [
      {
        label,
        value: null
      }
    ];
  }, [label]);

  return (
    <>
      <Tooltip
        disableTooltip={showingDialog}
        text={T.admin.previewtenant.previewnodetooltip}
      >
        <Select
          isDisabled={isLoading || disabled}
          value={previewNodeId == null ? null : options[0]}
          options={options}
          onMenuOpen={isLoading ? null : showDialog}
          disabled={disabled}
          placeholder={T.admin.previewtenant.previewnode}
          isSearchable={false}
          components={keyValueFixedSelectableMenuComponents}
          isClearable
          isLoading={isLoading}
          onChange={(e) => {
            setPreviewNodeId(e?.value);
          }}
        />
      </Tooltip>
      <SelectNodeDialog
        isOpen={showingDialog}
        onModalClose={hideDialog}
        nodeIds={nodeIds}
        onNodesSelected={(newNodeIds) => setPreviewNodeId(newNodeIds[0])}
        multiSelect={false}
      />
    </>
  );
};

const PreviewNodeToolbarItems = ({
  previewTenantId,
  setPreviewTenantId,
  previewNodeId,
  setPreviewNodeId,
  nodeTreeStore
}: PreviewNodeToolbarItemsProps) => {
  const { tenants } = useContext(TenantContext);

  const tenantOptions: GenericSelectOption<string>[] = useMemo(() => {
    return tenants
      .filter((x) =>
        x.resources.some((resource) => resource.name === ResourceType.CORE)
      )
      .map((tenant) => ({
        label: tenant.name,
        value: tenant.id
      }));
  }, [tenants]);
  const selectedOption = tenantOptions.find(
    (option) => option.value === previewTenantId
  );

  return (
    <>
      {setPreviewTenantId && (
        <ToolbarItem>
          <Tooltip text={T.admin.previewtenant.previewtenanttooltip}>
            <Select
              options={tenantOptions}
              value={selectedOption}
              onChange={(option) => setPreviewTenantId(option.value)}
              placeholder={T.admin.previewtenant.previewtenant}
              className={styles.select}
            />
          </Tooltip>
        </ToolbarItem>
      )}
      <ToolbarItem>
        <NestedTenantContainerWithoutEventHubService
          tenantId={previewTenantId}
          nodeTreeStore={nodeTreeStore}
        >
          <PreviewNodeSelector
            previewNodeId={previewNodeId}
            setPreviewNodeId={setPreviewNodeId}
            disabled={isNullOrWhitespace(previewTenantId)}
            isLoading={false}
          />
        </NestedTenantContainerWithoutEventHubService>
      </ToolbarItem>
    </>
  );
};

export default React.memo(PreviewNodeToolbarItems);
