import React, { Fragment, useContext, useMemo } from 'react';
import _ from 'lodash';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';

import EditNotificationDialog from './EditNotificationDialog';
import { NotificationModelsWithNodePicker } from './NotificationModels';
import ModelDisplay from 'ecto-common/lib/ModelForm/ModelDisplay';
import APIGen, {
  AlarmNotificationResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { getPathStringFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useNodes } from 'ecto-common/lib/hooks/useCurrentNode';

const getNotifications = (
  contextSettings: ApiContextSettings,
  nodeId: string,
  signal: AbortSignal
) => {
  if (nodeId) {
    return APIGen.AdminNotifications.getAlarmNotificationsByNodeIds.promise(
      contextSettings,
      {
        NodeIds: [nodeId]
      },
      signal
    );
  }

  return APIGen.AdminNotifications.getAlarmNotifications.promise(
    contextSettings,
    signal
  );
};

interface NotificationTableProps {
  nodeId?: string;
  searchString?: string;
  editItemId?: string;
  setEditItemId: (id: string, replace?: boolean) => void;
}

const emptyNotifications: AlarmNotificationResponseModel[] = [];
const NotificationTable = ({
  nodeId,
  searchString,
  editItemId,
  setEditItemId
}: NotificationTableProps) => {
  const [confirmDeleteId, setConfirmDeleteId] = useSearchParamState(
    'delete-notification-id',
    null
  );
  const { contextSettings } = useContext(TenantContext);

  const getItemsQuery = useQuery({
    queryKey: ['notificationTable', nodeId],

    queryFn: ({ signal }) => {
      return getNotifications(contextSettings, nodeId, signal);
    }
  });
  const notifications =
    getItemsQuery.data?.alarmNotifications ?? emptyNotifications;

  const queryClient = useQueryClient();

  const createMutation =
    APIGen.AdminNotifications.addOrUpdateAlarmNotifications.useMutation({
      onSuccess: (unused, item) => {
        const isNew = editItemId === 'new';
        toastStore.addSuccessToastForUpdatedItem(
          _.head(item.alarmNotifications)?.name,
          isNew
        );

        queryClient.invalidateQueries({
          queryKey:
            APIGen.AdminNotifications.getAlarmNotificationsByNodeIds.path(
              contextSettings,
              {
                NodeIds: [nodeId]
              }
            )
        });
        getItemsQuery.refetch();
        setEditItemId(null, isNew);
      },
      onError: (unused, item) => {
        toastStore.addErrorToastForUpdatedItem(
          _.head(item.alarmNotifications)?.name,
          editItemId === 'new'
        );
      }
    });

  const deleteMutation =
    APIGen.AdminNotifications.deleteAlarmNotifications.useMutation({
      onSuccess: () => {
        const item = notifications.find(
          (deleteItem) => deleteItem.id === confirmDeleteId
        );
        toastStore.addSuccessToastForDeletedItem(item.name);
        setEditItemId(null, true);
        getItemsQuery.refetch();
        setConfirmDeleteId(null, true);
        queryClient.invalidateQueries({
          queryKey:
            APIGen.AdminNotifications.getAlarmNotificationsByNodeIds.path(
              contextSettings,
              {
                NodeIds: [nodeId]
              }
            )
        });
      },
      onError: () => {
        const item = notifications.find(
          (deleteItem) => deleteItem.id === confirmDeleteId
        );
        toastStore.addErrorToastForDeletedItem(item?.name);
      }
    });

  const modelColumns: DataTableColumnProps<AlarmNotificationResponseModel>[] =
    useMemo(() => {
      const selectedColumns = nodeId ? ['name'] : ['name', 'nodeIds'];

      return _.reduce(
        NotificationModelsWithNodePicker,
        (columns, model) => {
          const keyString = getPathStringFromModelKeyFunc(model.key);

          if (!selectedColumns.includes(keyString)) {
            return columns;
          }

          return [
            ...columns,
            {
              label: model.label,
              dataKey: getPathStringFromModelKeyFunc(model.key),
              minWidth: 100,
              dataFormatter: (unused: unknown, item) => (
                <ModelDisplay model={model} rawValue={_.get(item, keyString)} />
              )
            }
          ];
        },
        [] as DataTableColumnProps<AlarmNotificationResponseModel>[]
      );
    }, [nodeId]);

  const columns = [
    ...modelColumns,
    ...standardColumns<AlarmNotificationResponseModel>({
      onDelete: (item) => setConfirmDeleteId(item.id),
      onEdit: (item) => setEditItemId(item.id)
    })
  ];

  const referencedNodes = useNodes(notifications.map((n) => _.head(n.nodeIds)));

  const data = useMemo(() => {
    const _searchString = _.toLower(searchString);

    return _.isEmpty(_searchString)
      ? notifications
      : notifications.filter((notification) => {
          return (
            _.defaultTo(_.toLower(notification.name), '').search(
              _searchString
            ) !== -1 ||
            _.get(
              referencedNodes.nodes.find(
                (x) => x.nodeId === _.head(notification.nodeIds)
              ),
              'name',
              ''
            )
              .toLowerCase()
              .search(_searchString) !== -1
          );
        });
  }, [notifications, referencedNodes.nodes, searchString]);

  const confirmDeleteItem = notifications.find(
    (item) => item.id === confirmDeleteId
  );

  return (
    <Fragment>
      <ConfirmDeleteDialog
        isOpen={confirmDeleteItem != null}
        onModalClose={() => setConfirmDeleteId(null)}
        isLoading={deleteMutation.isPending}
        itemName={confirmDeleteItem?.name}
        onDelete={() => deleteMutation.mutate({ ids: [confirmDeleteItem.id] })}
      />
      {!getItemsQuery.isLoading && (
        <EditNotificationDialog
          nodeId={nodeId}
          isOpen={editItemId != null}
          notification={notifications.find((item) => item.id === editItemId)}
          isLoading={createMutation.isPending}
          onModalClose={() => setEditItemId(null)}
          onAddInput={(input) =>
            createMutation.mutate({
              alarmNotifications: [
                // @ts-ignore-next-line
                input as AlarmNotificationResponseModel
              ]
            })
          }
          onDelete={(item) => setConfirmDeleteId(item.id)}
        />
      )}

      <DataTable<AlarmNotificationResponseModel>
        hasError={getItemsQuery.error != null}
        isLoading={getItemsQuery.isLoading}
        data={data}
        columns={columns}
      />
    </Fragment>
  );
};

export default React.memo(NotificationTable);
