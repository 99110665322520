import React, { useContext, useEffect } from 'react';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import CRUDView, { useCrudViewData } from 'ecto-common/lib/CRUDView/CRUDView';
import { useMutation } from '@tanstack/react-query';
import AlarmsAPIGen, {
  AlarmRuleModel,
  ComparisonOperatorEnum
} from 'ecto-common/lib/API/AlarmsAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';

const ComparisonOperatorEnumTranslation: Record<
  ComparisonOperatorEnum,
  string
> = {
  [ComparisonOperatorEnum.Equal]: T.common.compare.equal,
  [ComparisonOperatorEnum.NotEqual]: T.common.compare.notequal,
  [ComparisonOperatorEnum.GreaterThan]: T.common.compare.greaterthan,
  [ComparisonOperatorEnum.GreaterThanOrEqual]:
    T.common.compare.greaterthanorequal,
  [ComparisonOperatorEnum.LessThan]: T.common.compare.lessthan,
  [ComparisonOperatorEnum.LessThanOrEqual]: T.common.compare.lessthanorequal
};

const CompareOptions = enumValues(ComparisonOperatorEnum).map((value) => ({
  label: ComparisonOperatorEnumTranslation[value] ?? value,
  value
}));

const models: ModelDefinition<AlarmRuleModel>[] = [
  {
    key: (input) => input.name,
    modelType: ModelType.TEXT,
    label: T.common.name,
    placeholder: T.common.name,
    hasError: isNullOrWhitespace
  },

  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.common.description,
    placeholder: T.common.description,
    hasError: isNullOrWhitespace
  },
  {
    key: (input) => input.dataPointId,
    modelType: ModelType.SIGNAL,
    label: T.alarms.columns.signal,
    hasError: _.isEmpty
  },
  {
    key: (input) => input.severity,
    modelType: ModelType.NUMBER,
    label: T.admin.alarmtemplates.severity,
    hasError: (input) => input == null || input < 0
  },
  {
    key: (input) => input.thresholdValue,
    modelType: ModelType.NUMBER,
    label: T.admin.alarmrules.thresholdvalue,
    hasError: _.isNull
  },
  {
    key: (input) => input.thresholdOperator,
    modelType: ModelType.OPTIONS,
    label: T.admin.alarmrules.thresholdoperator,
    options: CompareOptions
  },
  {
    key: (input) => input.activationDelay,
    modelType: ModelType.NUMBER,
    label: T.admin.alarmrules.activationdelay,
    hasError: (input) => input == null || input < 0
  },
  {
    key: (input) => input.resetValue,
    modelType: ModelType.NUMBER,
    label: T.admin.alarmrules.resetvalue,
    hasError: (input) => input == null || input < 0
  },
  {
    key: (input) => input.resetOperator,
    modelType: ModelType.OPTIONS,
    label: T.admin.alarmrules.resetoperator,
    options: CompareOptions
  }
];

const columns: DataTableColumnProps<AlarmRuleModel>[] = [
  {
    dataKey: 'name',
    label: T.common.name,
    canSort: true
  },
  {
    dataKey: 'description',
    label: T.common.description,
    canSort: true
  }
];

const createNewItem = (): AlarmRuleModel => ({
  name: '',
  description: '',
  severity: 0,
  thresholdValue: 0,
  thresholdOperator: ComparisonOperatorEnum.Equal,
  activationDelay: 0,
  resetValue: 0,
  resetOperator: ComparisonOperatorEnum.Equal
});

const AlarmRules = () => {
  useEffect(() => {
    document.title = T.admin.tabs.alarmrules;
  }, []);

  const { contextSettings } = useContext(TenantContext);
  const deleteItemMutation = useMutation({
    mutationFn: (item: AlarmRuleModel) => {
      return AlarmsAPIGen.AlarmRules.deleteAlarmRule.promise(
        contextSettings,
        { id: item.id },
        null
      );
    }
  });

  const updateItemMutation =
    AlarmsAPIGen.AlarmRules.updateAlarmRule.useMutation({});
  const createItemMutation =
    AlarmsAPIGen.AlarmRules.createAlarmRule.useMutation({});
  const listQueryHook = AlarmsAPIGen.AlarmRules.listAlarmRules.useInfiniteQuery;

  const crudData = useCrudViewData({
    listQueryHook,
    searchItems: ['name', 'description'],
    sortBy: 'name'
  });

  return (
    <CRUDView
      models={models}
      columns={columns}
      createNewItem={createNewItem}
      itemName={'name'}
      title={T.admin.tabs.alarmrules}
      editTitle={T.admin.alarmrules.editrule}
      addTitle={T.admin.alarmrules.addrule}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
      {...crudData}
    />
  );
};

export default AlarmRules;
