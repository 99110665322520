import React, { useCallback, useContext, useMemo } from 'react';
import _ from 'lodash';

import { AllAlarmSeverities } from 'ecto-common/lib/constants';
import { useAlarmCountEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from 'ecto-common/lib/Dashboard/panels/AlarmStatusPanel.module.css';
import { NavLink } from 'react-router-dom';

import {
  getSignalProvidersPage,
  getAlarmsPage
} from 'ecto-common/lib/utils/commonLinkUtil';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { ASC } from 'ecto-common/lib/DataTable/SortDirection';
import useSort from 'ecto-common/lib/hooks/useSort';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import AlarmSeverityCount from 'ecto-common/lib/Alarms/AlarmSeverityCount';
import { beautifyEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import HelpPaths from 'ecto-common/help/tocKeys';

import { buildingStatusSection } from './panelUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  BuildingStatus,
  EquipmentTypeResponseModel,
  GridType,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import {
  nodeIsBuilding,
  nodeIsEquipment,
  nodeIsSite,
  useNodeChildren
} from 'ecto-common/lib/hooks/useCurrentNode';

const pathName = window.location.pathname;

const getAlarmsRoute = (tenantId: string, node: AlarmNode, isAdmin: boolean) =>
  !isAdmin ? getAlarmsPage(tenantId, node.nodeId) : pathName;

interface NameColumnTitleProps {
  node: AlarmNode;
}

const NameColumnTitle = ({ node }: NameColumnTitleProps) => {
  const { isAdmin, setNode } = useContext(DashboardDataContext);
  const { tenantId } = useContext(TenantContext);

  const _setNode = useCallback(() => {
    if (
      (isAdmin && nodeIsSite(node.node)) ||
      nodeIsBuilding(node.node) ||
      !isAdmin
    ) {
      setNode(node.nodeId);
    }
  }, [isAdmin, node.node, node.nodeId, setNode]);

  // TODO: Remove this cast
  if (!nodeIsEquipment(node.node)) {
    return (
      <a onClick={_setNode} className={styles.nameWrapper}>
        {node.name}
      </a>
    );
  }

  if (!isAdmin) {
    // TODO: Remove cast
    return (
      <NavLink
        to={getSignalProvidersPage(tenantId, node.nodeId)}
        className={styles.nameWrapper}
      >
        {node.name}
      </NavLink>
    );
  }

  return <>{node.name}</>;
};

interface NameColumnProps {
  node: AlarmNode;
}

const NameColumn = ({ node }: NameColumnProps) => (
  <TableColumn
    className={styles.nameWrapper}
    title={<NameColumnTitle node={node} />}
    subtitle={node.subtitle}
  />
);

const approxWidthForSeverityColumn = (maxNumSeverities: number) => {
  // Approx width for severity with two digit value
  const SEVERITY_WIDTH_APPROX = 55;
  // Approx width for navigation arrow
  const SEVERITY_ARROW_APPROX = 25;

  return maxNumSeverities * SEVERITY_WIDTH_APPROX + SEVERITY_ARROW_APPROX;
};

interface SeveritiesColumnProps {
  node: AlarmNode;
}

const SeveritiesColumn = ({ node }: SeveritiesColumnProps) => {
  const { isAdmin } = useContext(DashboardDataContext);
  const { tenantId } = useContext(TenantContext);

  return (
    <NavLink
      to={getAlarmsRoute(tenantId, node, isAdmin)}
      className={styles.alarmSeverities}
    >
      {_.map(node.severities, (count, severity) => (
        <AlarmSeverityCount key={severity} severity={severity} count={count} />
      ))}

      {_.isEmpty(node?.severities) && (
        <Tooltip text={T.admin.alarmstatuspanel.tooltip.noactivealarms}>
          <Icons.CheckmarkCircle className={styles.noSeveritiesIcon} />
        </Tooltip>
      )}

      <div className={styles.arrow}>
        <Icons.NavigationArrowRight />
      </div>
    </NavLink>
  );
};

type AlarmDataTableColumnProps = DataTableColumnProps<AlarmNode> & {
  sortBy?: string[];
};

const getColumns = (maxNumSeverities: number): AlarmDataTableColumnProps[] => {
  // Table columns must have constant width in order to avoid
  // overlap. Make an approximate width for the severity column (which is dynamic),
  const severitiesMinWidth = approxWidthForSeverityColumn(maxNumSeverities);

  return [
    {
      dataKey: 'name',
      label: T.common.nodename,
      canSort: true,
      truncateText: true,
      flexGrow: 2,
      flexShrink: 2,
      dataFormatter: (unused: unknown, node) => <NameColumn node={node} />
    },
    {
      dataKey: 'severities',
      label: T.common.status,
      align: 'right',
      canSort: true,
      flexGrow: 0,
      flexShrink: 0,
      minWidth: severitiesMinWidth,
      maxWidth: severitiesMinWidth,
      sortBy: _.map(AllAlarmSeverities, (severity) => 'severities.' + severity),
      dataFormatter: (unused: unknown, node) => <SeveritiesColumn node={node} />
    }
  ];
};

const getSubtitle = (
  node: NodeV2ResponseModel,
  equipmentTypes: EquipmentTypeResponseModel[]
) => {
  if (nodeIsSite(node)) {
    return T.nodetypes.site;
  }

  if (nodeIsBuilding(node)) {
    return T.nodetypes.building;
  }

  const equipmentTypeId = node.nodeTraits.find(({ nodeTraitId }) =>
    equipmentTypes.find(
      (equipmentType) => equipmentType.equipmentTypeId === nodeTraitId
    )
  )?.nodeTraitId;

  if (equipmentTypeId) {
    return beautifyEquipmentName(
      _.find(equipmentTypes, { equipmentTypeId })?.name
    );
  }

  return '';
};

type AlarmNode = {
  name: string;
  nodeId: string;
  severities: number[];
  subtitle: string;
  parentNodeId: string;
  node: NodeV2ResponseModel;
};

type AlarmStatusPanelProps = CustomPanelProps & {
  data: {
    node: NodeV2ResponseModel;
    buildingStatus: BuildingStatus[];
  };
};

const AlarmStatusPanel = ({ data }: AlarmStatusPanelProps) => {
  const parentNode = data.node;
  const isStandingOnASite = data.node && nodeIsSite(data.node);

  const children = useNodeChildren(_.compact([parentNode?.nodeId]));

  const { equipmentTypes } = useContext(DashboardDataContext);

  const nodeIds = useMemo(() => {
    if (children.isLoading) {
      return [];
    }

    return [parentNode.nodeId, ..._.map(children.nodeChildren, 'nodeId')];
  }, [children.isLoading, children.nodeChildren, parentNode.nodeId]);

  const alarmCounts = useAlarmCountEventHubSubscription(
    GridType.Generic,
    nodeIds,
    !isStandingOnASite,
    data?.buildingStatus
  );

  const nodesWithAlarmSeverities: AlarmNode[] = useMemo(() => {
    return _.compact(
      _.map(alarmCounts.bySeverity, (item, key) => {
        const node =
          children.nodeChildren.find((x) => x.nodeId === key) || parentNode;

        if (!node) {
          return null;
        }

        return {
          name: node?.name,
          nodeId: node?.nodeId,
          severities: item,
          subtitle: getSubtitle(node, equipmentTypes),
          parentNodeId: node.parentId,
          node
        };
      })
    );
  }, [
    alarmCounts.bySeverity,
    children.nodeChildren,
    equipmentTypes,
    parentNode
  ]);

  const maxNumSeverities = Math.max(
    1,
    _.max(
      _.map(nodesWithAlarmSeverities, (node) => _.keys(node.severities).length)
    ) ?? 0
  );

  const columns = useMemo(
    () => getColumns(maxNumSeverities),
    [maxNumSeverities]
  );

  const [sortBy, sortDirection, setSortParams, sortedData] = useSort<AlarmNode>(
    'severities',
    ASC,
    columns,
    nodesWithAlarmSeverities
  );

  const isLoading = alarmCounts.isLoading;

  return (
    <DataTable<AlarmNode>
      data={sortedData}
      columns={columns}
      isLoading={isLoading}
      noDataText={
        isLoading ? null : T.alarms.noalarmswerefoundinthecurrentlocation
      }
      onSortChange={setSortParams}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  );
};

export const AlarmStatusPanelData = {
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  },
  sections: [buildingStatusSection],
  minWidth: 280,
  helpPath: HelpPaths.docs.dashboard.dashboards.alarm_status_list
};

export default React.memo(AlarmStatusPanel);
