import React, {
  Fragment,
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import _ from 'lodash';

import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import KeyValueInternalTextInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalTextInput';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';

import SelectSignalsDialog from 'ecto-common/lib/SelectSignalsDialog/SelectSignalsDialog';

import styles from './SignalSection.module.css';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import {
  ChartSignal,
  getNodeName
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import {
  DestinationConfigSignalResponseModel,
  SignalProviderSignalResponseModel,
  SourceConfigSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalWithProvider } from 'ecto-common/lib/types/EctoCommonTypes';
import { useNodes } from 'ecto-common/lib/hooks/useCurrentNode';

interface SignalInputRawProps {
  value?: string;
  dataKey?: string;
  index?: number;
  placeholder?: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (index: number, key: string, value: any) => void;
}

// Memoized input with callback changes
const SignalInputRaw = ({
  value,
  dataKey,
  index,
  placeholder,
  label,
  onChange
}: SignalInputRawProps) => {
  const _onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => onChange(index, dataKey, e.target.value),
    [index, dataKey, onChange]
  );
  return (
    <KeyValueInternalTextInput
      value={value}
      placeholder={_.defaultTo(placeholder, label)}
      onChange={_onChange}
    />
  );
};

const SignalInput = React.memo(SignalInputRaw);

export type SignalSectionModelDefinitionWithOnChange = {
  key: string;
  label: string;
  onChange?: (index: number, key: string, value: unknown) => void;
};

const createInput = ({
  label,
  key,
  onChange
}: SignalSectionModelDefinitionWithOnChange) => {
  return {
    label,
    dataKey: key,
    dataFormatter: (value: unknown, _item: unknown, index: number) => {
      return (
        <SignalInput
          value={_.toString(value)}
          dataKey={key}
          index={index}
          label={label}
          onChange={onChange}
        />
      );
    }
  };
};

type ObjectWithSignalId = {
  signalId?: string;
  item?: {
    signalId?: string;
  };
};

// There are two ways to get signalId, one directly from signal, if it was loaded from server,
// and the other one is from item.signalId, if it was added from signal selection dialog.
export const getSignalId = (signal: ObjectWithSignalId) =>
  _.get(signal, 'signalId', _.get(signal, 'item.signalId'));

export type DestOrSourceMappingSignal =
  | DestinationConfigSignalResponseModel
  | SourceConfigSignalResponseModel;

interface SignalSectionProps {
  /**
   * Title of the action button for this section
   */
  title: string;
  /**
   * Callback for when the signal list or when an item in the list changes.
   */
  onSignalsChanged(signals: DestOrSourceMappingSignal[]): void;
  /**
   * Model for signal item.
   */
  signalInputs?: SignalSectionModelDefinitionWithOnChange[];
  /**
   * Initial signal values
   */
  initialSignals?: DestOrSourceMappingSignal[];
  /**
   * Whether the signal data is still loading
   */
  isLoading: boolean;
  /**
   * Contains additional information for each signal, e.g signal name to signal id mappings
   */
  signalInfo?: Record<string, SignalWithProvider>;
}

const SignalSection = ({
  title,
  initialSignals = [],
  onSignalsChanged,
  signalInputs = [],
  isLoading,
  signalInfo
}: SignalSectionProps) => {
  const [isDialogOpen, showSignalDialog, hideSignalDialog] =
    useSimpleDialogState();

  const [signals, setSignals] =
    useState<DestOrSourceMappingSignal[]>(initialSignals);
  const [newSignalInfo, setNewSignalInfo] =
    useState<Record<string, SignalWithProvider>>(signalInfo);
  const nodeId = useAdminSelector((state) => state.general.nodeId);

  useEffect(() => {
    setNewSignalInfo(signalInfo);
  }, [signalInfo]);

  // Get the signal input keys, so they can be used when remapping signal selection input from signal picker
  const signalInputKeys: string[] = useMemo(
    () => ['id', ..._.map(signalInputs, 'key')],
    [signalInputs]
  );

  // Since we just store DestOrSourceMappingSignal values without all signal related metadata
  // we fetch that data from the lookup table newSignalInfo, and create fully annotated ChartSignals
  // from that
  const getItemAndGroup = useCallback(
    (signal: DestOrSourceMappingSignal) => {
      const lookupEntry = newSignalInfo[signal.signalId];

      const item: SignalProviderSignalResponseModel = lookupEntry;
      const signalProvider = lookupEntry.provider;
      const group = _.omit(signalProvider, 'signals');

      // Use signal ID as chartSignalId since there cannot be duplicates of signals and we have no other good option
      const chartSignal: ChartSignal = {
        item,
        group,
        chartSignalId: signal.signalId
      };
      return chartSignal;
    },
    [newSignalInfo]
  );

  const dialogSignals: ChartSignal[] = useMemo(() => {
    // Make sure we have signal information before sending selected signals to the dialog
    // this will avoid the property error on group and signal info
    if (!_.isEmpty(newSignalInfo)) {
      // Remap signals to match select signal dialog format ([ { item: <signal info>, group: <signal provider > }, ... ]
      return _.map(signals, getItemAndGroup);
    }
    return [];
  }, [getItemAndGroup, signals, newSignalInfo]);

  const updateSignals = useCallback(
    (newSignals: ChartSignal[]) => {
      // Remap signals to array of { ...'signalKeys', signalId }
      // Pick only signal input's keys (from SignalInput values) and add signalId from signal picker
      const value: DestOrSourceMappingSignal[] = _.map(
        newSignals,
        (signal) => ({
          id: undefined as string,
          ..._.pick(signal, signalInputKeys),
          signalId: getSignalId(signal)
        })
      );
      onSignalsChanged(value);

      const newGroupInfo = _.reduce(
        newSignals,
        (dict, signal) => {
          const { item, group } = signal;
          dict[item.signalId] = { provider: group, ...item };
          return dict;
        },
        {} as Record<string, SignalWithProvider>
      );

      setNewSignalInfo((otherInfo) => ({ ...otherInfo, ...newGroupInfo }));
      setSignals(value);
    },
    [onSignalsChanged, signalInputKeys]
  );

  const onChangeSignalValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (index: number, key: string, value: any) => {
      const newSignals = [...signals];
      const updatedSignal = newSignals[index];
      _.set(updatedSignal, key, value);
      onSignalsChanged(newSignals);
      setSignals(newSignals);

      setNewSignalInfo((oldInfo) => {
        const { item, group } = getItemAndGroup(updatedSignal);
        const newInfo = { ...oldInfo };
        newInfo[updatedSignal.signalId] = { ...item, provider: group };
        return newInfo;
      });
    },
    [signals, getItemAndGroup, onSignalsChanged]
  );

  const onSignalsSelected = useCallback(
    (newSignals: ChartSignal[]) => {
      updateSignals(newSignals);
      hideSignalDialog();
    },
    [updateSignals, hideSignalDialog]
  );

  const referencedNodes = useNodes(
    _.flatMap(
      signals,
      (s) => newSignalInfo?.[getSignalId(s)]?.provider?.nodeIds ?? []
    )
  );

  const columns: DataTableColumnProps<DestOrSourceMappingSignal>[] = [
    {
      label: T.admin.signalmapping.common.name,
      dataKey: 'item.name',
      flexGrow: 1,
      dataFormatter: (unusedname: string, signalIn) => {
        const signal = {
          item: { ..._.get(newSignalInfo, getSignalId(signalIn)) },
          group: _.omit(
            _.get(newSignalInfo, [getSignalId(signalIn), 'provider']),
            'signals'
          )
        };

        // Nothing to show if we do not have any signal provider
        if (signal.group == null || isNullOrWhitespace(signal.item?.name)) {
          return <div />;
        }

        return (
          <TableColumn
            title={signal.item.name}
            subtitle={getNodeName(
              signal.group,
              referencedNodes.nodes,
              referencedNodes.parents
            )}
          />
        );
      }
    },
    ..._.map(signalInputs, (input) =>
      createInput({ ...input, onChange: onChangeSignalValue })
    )
  ];

  return (
    <Fragment>
      <Button
        disabled={isLoading}
        className={styles.signalSectionButton}
        onClick={showSignalDialog}
      >
        {title}
      </Button>

      <SelectSignalsDialog
        isOpen={isDialogOpen}
        onModalClose={hideSignalDialog}
        onSignalsSelected={onSignalsSelected}
        selectedSignals={dialogSignals}
        nodeId={nodeId}
      />

      {!_.isEmpty(signals) && (
        <KeyValueGeneric
          keyText={T.admin.signalmapping.signalselection.title}
          className={styles.signalSectionTable}
        >
          <DataTable
            isLoading={isLoading}
            data={isLoading ? [] : signals}
            columns={columns}
          />
        </KeyValueGeneric>
      )}
    </Fragment>
  );
};

export default React.memo(SignalSection);
