import React, { useMemo, useCallback, useState, useContext } from 'react';
import _ from 'lodash';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import colors from 'ecto-common/lib/styles/variables/colors';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import {
  createStockChartConfigFromSeries,
  yAxisFormatter
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import PanelDataType from 'ecto-common/lib/Dashboard/panels/PanelDataType';
import {
  createTelemetryFromSignalValues,
  getSignalAggregationModels,
  SignalValuesDataSourceResult
} from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { migrateSignalSettingSystemNamesToSignalTypes } from 'ecto-common/lib/Dashboard/migrations/datasourceUtil';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import {
  CustomPanelProps,
  DashboardPanel,
  PanelSizeType
} from 'ecto-common/lib/Dashboard/Panel';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { GraphMinMaxSettings } from 'ecto-common/lib/types/EctoCommonTypes';
import { graphMinMaxSection } from 'ecto-common/lib/Dashboard/util/GraphMinMaxModelEditor';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';

const MIN_REFRESH_INTERVAL = 15;
const DEFAULT_REFRESH_INTERVAL = MIN_REFRESH_INTERVAL;

type LineChartPanelConfig = {
  showCurrentTime?: boolean;
  refreshInterval?: number;
  onRefresh: () => void;
  size?: PanelSizeType;
  paddingLeft?: number;
  paddingRight?: number;
  minMaxSettings?: Record<string, GraphMinMaxSettings>;
};

type LineChartContentProps = SignalValuesDataSourceResult &
  LineChartPanelConfig;

const LineChartContent = ({
  signalValues,
  signalInfo,
  isLoading,
  dateFrom,
  dateTo,
  hasError,
  hasPointsOverflow,
  showCurrentTime = false,
  refreshInterval = DEFAULT_REFRESH_INTERVAL,
  paddingLeft,
  paddingRight,
  onRefresh,
  minMaxSettings,
  size,
  nodes,
  equipmentChildren
}: LineChartContentProps) => {
  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const telemetry = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return createTelemetryFromSignalValues(
      signalInfo,
      signalValues,
      signalTypesMap,
      signalUnitTypesMap,
      nodes.concat(equipmentChildren),
      nodes
    );
  }, [
    isLoading,
    signalInfo,
    signalValues,
    signalTypesMap,
    signalUnitTypesMap,
    nodes,
    equipmentChildren
  ]);

  const _dateFrom = useMemo(() => dateFrom.valueOf(), [dateFrom]);
  const _dateTo = useMemo(() => dateTo.valueOf(), [dateTo]);

  // Reload data, smallest interval 15 minutes
  useInterval(
    onRefresh,
    1000 * 60 * Math.max(refreshInterval, MIN_REFRESH_INTERVAL)
  );

  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const updateCurrentTime = useCallback(
    () => setCurrentTime(new Date().getTime()),
    []
  );
  // update the current time line every minute
  useInterval(updateCurrentTime, 1000 * 60);

  const config: Highcharts.Options = useMemo(
    () => ({
      xAxis: {
        plotLines: showCurrentTime
          ? [
              {
                value: currentTime,
                color: colors.darkSide2ColorTransparent,
                width: 1,
                dashStyle: 'Dash'
              }
            ]
          : []
      },
      navigator: {
        enabled: false
      },
      yAxis: {
        crosshair: false,
        opposite: false,
        labels: {
          formatter: yAxisFormatter
        }
      },
      plotOptions: {
        series: {
          animation: true
        }
      },
      chart: {
        zoomType: false,
        spacingLeft: paddingLeft,
        spacingRight: paddingRight
      },
      ...createStockChartConfigFromSeries(telemetry, null, minMaxSettings)
    }),
    [
      showCurrentTime,
      currentTime,
      paddingLeft,
      paddingRight,
      telemetry,
      minMaxSettings
    ]
  );

  const seriesIds = useMemo(() => {
    return telemetry.map((item) => item.chartSignalId);
  }, [telemetry]);

  return (
    <StockChart
      seriesIds={seriesIds}
      dateFrom={_dateFrom}
      dateTo={_dateTo}
      hasError={hasError}
      isLoading={isLoading}
      config={config}
      hasPointsOverflow={hasPointsOverflow}
      containerWidth={size.width}
    />
  );
};

type LineChartPanelProps = CustomPanelProps & {
  data: {
    refreshInterval: number;
    showCurrentTime: boolean;
    values: SignalValuesDataSourceResult;
    paddingLeft?: number;
    paddingRight?: number;
  };
};

const LineChartPanel = ({ panelApi, data }: LineChartPanelProps) => {
  const { values, ...other } = data;
  return (
    <LineChartContent
      {...values}
      {...other}
      onRefresh={panelApi.reload}
      size={panelApi.size}
    />
  );
};

const sections: ModelFormSectionType<LineChartPanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.linechart,
    lines: [
      {
        models: [
          {
            key: (input) => input.refreshInterval,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.linechart.refreshinterval,
            placeholder: _.toString(DEFAULT_REFRESH_INTERVAL),
            hasError: (value) => value < MIN_REFRESH_INTERVAL
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.showCurrentTime,
            modelType: ModelType.BOOL,
            label: T.admin.dashboards.panels.types.linechart.showcurrenttime
          }
        ]
      }
    ]
  },
  graphMinMaxSection,
  {
    label: T.admin.dashboards.panels.types.linechart.chartlayout,
    initiallyCollapsed: true,
    lines: [
      {
        models: [
          {
            key: (input) => input.paddingLeft,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.linechart.paddingleft,
            placeholder: '0'
          },
          {
            key: (input) => input.paddingRight,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.linechart.paddingright,
            placeholder: '0'
          }
        ]
      }
    ]
  }
];

export const LineChartPanelData = {
  helpPath: HelpPaths.docs.dashboard.dashboards.line_chart,
  dataType: PanelDataType.CHART,
  migrations: [
    {
      version: 2,
      migration: (
        panel: DashboardPanel,
        enums: GetEnumsAndFixedConfigurationsResponseModel,
        signalTypesMap: Record<string, SignalTypeResponseModel>
      ) => {
        panel.targets.values = migrateSignalSettingSystemNamesToSignalTypes(
          panel.targets.values,
          enums,
          signalTypesMap
        );
      }
    }
  ],
  sections,
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS]: {
      optionalSignalModels: getSignalAggregationModels(true)
    }
  },
  emptyTargets: {
    values: {
      sourceType: DataSourceTypes.SIGNALS
    }
  }
};

export default React.memo(LineChartPanel);
