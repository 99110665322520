import React, { useContext, useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import T from 'ecto-common/lib/lang/Language';
import { Navigate } from 'react-router-dom';
import {
  getLastTenantId,
  setLastTenantId
} from 'ecto-common/lib/utils/localStorageUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import _ from 'lodash';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import IdentityServiceAPIGenV2, {
  ResourceModel,
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { TenantModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import Page from 'ecto-common/lib/Page/Page';
import { PageNavLink } from '../Page/Page';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useCommonSelector } from '../reducers/storeCommon';
import { useQuery } from '@tanstack/react-query';
import { toastStore } from '../Toast/ToastContainer';
import { EventHubServiceContainer } from 'ecto-common/lib/EventHubConnection/EventHubService';
import { ADMIN_SPECIFIC_TENANT_IDS } from '../constants';
import {
  NodeTreeStoreContext,
  NodeTreeStoreType
} from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

const tenantPath = '/:tenantId/*';
type TenantParams = 'tenantId';

const getTenantFromPathname = (pathname: string) => {
  return matchPath<TenantParams, string>(tenantPath, pathname)?.params
    ?.tenantId;
};

const loadTenantDataPromise = (
  contextSettings: ApiContextSettings,
  signal: AbortSignal
) => {
  return Promise.all([
    IdentityServiceAPIGenV2.User.getTenantsForUser.promise(
      contextSettings,
      signal
    ),
    IdentityServiceAPIGenV2.Resources.listResources.promise(
      contextSettings,
      {},
      signal
    ),
    IdentityServiceAPIGenV2.Roles.listRoles.promise(
      contextSettings,
      {},
      signal
    ),
    IdentityServiceAPIGenV2.User.getUser.promise(contextSettings, signal),
    IdentityServiceAPIGenV2.User.getTenantUsersForUser.promise(
      contextSettings,
      signal
    )
  ] as const).then(([tenants, resources, roles, userData, tenantUsers]) => {
    return {
      tenants,
      resources,
      roles,
      userData,
      tenantUsers
    };
  });
};

interface TenantContainerProps {
  children?: React.ReactNode;
}

const emptyNavLinks: PageNavLink[] = [];

const NoTenantsError = () => {
  const content = (
    <ToolbarContentPage title={T.common.error} showLocationPicker={false}>
      {T.tenants.noaccess}
    </ToolbarContentPage>
  );

  return (
    <div style={{ width: '100%' }}>
      <Page navLinks={emptyNavLinks} content={content} />
    </div>
  );
};

const emptyRoles: TenantModel[] = [];
const emptyUsers: TenantUserModel[] = [];
const emptyResources: ResourceModel[] = [];

const TenantContainer = ({ children }: TenantContainerProps) => {
  const { userId, setUserData } = useContext(UserContext);
  const location = useLocation();
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);

  // No routes here yet, extract tenant name manually from pathname
  const parsedTenantId = getTenantFromPathname(location.pathname);

  const loadTenantDataQuery = useQuery({
    queryKey: ['loadTenantData', userId],

    queryFn: ({ signal }) => {
      return loadTenantDataPromise({ tenantId }, signal);
    },

    refetchOnWindowFocus: false
  });

  const tenantUsers = loadTenantDataQuery.data?.tenantUsers ?? emptyUsers;
  const availableTenantRoles =
    loadTenantDataQuery.data?.roles.roles ?? emptyRoles;
  const availableTenantResources =
    loadTenantDataQuery.data?.resources.resources ?? emptyResources;

  const tenantList = useMemo(() => {
    const tenants = loadTenantDataQuery.data?.tenants ?? [];
    if (!isAdmin) {
      return _.reject(tenants, (tenant) =>
        _.includes(ADMIN_SPECIFIC_TENANT_IDS, tenant.id)
      );
    }

    return tenants;
  }, [isAdmin, loadTenantDataQuery.data]);

  const tenantObject = _.find(tenantList, ['id', parsedTenantId]);
  const tenantUser = _.find(tenantUsers, ['tenantId', parsedTenantId]);

  useEffect(() => {
    if (loadTenantDataQuery.data != null) {
      setUserData(loadTenantDataQuery.data.userData);
    }
  }, [loadTenantDataQuery.data, setUserData]);

  const loadUserDataMutation = IdentityServiceAPIGenV2.User.getUser.useMutation(
    {
      onSuccess: (_userData) => {
        setUserData(_userData);
      },
      onError: () => {
        toastStore.addErrorToast(T.common.error);
      }
    }
  );

  let redirectTenantId: string = null;
  let tenantId: string = null;
  let error: React.ReactNode = null;
  if (loadTenantDataQuery.error != null) {
    // Let MainContainer handle error instead.
  } else if (!loadTenantDataQuery.isLoading) {
    if (tenantList.length === 0) {
      // Redirect to "no tenants available" screen
      error = <NoTenantsError />;
    } else {
      const foundParsedTenantId = _.find(tenantList, [
        'id',
        parsedTenantId
      ])?.id;
      const oldTenantId = _.find(tenantList, ['id', getLastTenantId()])?.id;
      if (foundParsedTenantId == null) {
        const newTenantId = oldTenantId ?? tenantList[0].id;
        tenantId = newTenantId;
        redirectTenantId = newTenantId;
      } else {
        tenantId = foundParsedTenantId;
      }

      setLastTenantId(tenantId);
    }

    // The specified tenant does not exist
  }

  const tenantData = useMemo(() => {
    return {
      availableTenantRoles,
      availableTenantResources,
      tenantId,
      tenantResources: tenantObject?.resources ?? [],
      tenants: tenantList,
      tenantUserRoles: tenantUser?.roles ?? [],
      isLoadingTenants: loadTenantDataQuery.isLoading,
      tenantsFailedToLoad: loadTenantDataQuery.error != null,
      reloadTenants: loadTenantDataQuery.refetch,
      reloadUser: loadUserDataMutation.mutate,
      contextSettings: {
        tenantId
      }
    };
  }, [
    availableTenantRoles,
    availableTenantResources,
    tenantId,
    tenantObject?.resources,
    tenantList,
    tenantUser?.roles,
    loadTenantDataQuery.isLoading,
    loadTenantDataQuery.error,
    loadTenantDataQuery.refetch,
    loadUserDataMutation.mutate
  ]);

  if (redirectTenantId) {
    return <Navigate to={'/' + redirectTenantId} replace />;
  }

  let content: React.ReactNode = null;
  if (error != null) {
    content = error;
  } else {
    content = children;
  }

  return (
    <TenantContext.Provider value={tenantData}>
      {content}
    </TenantContext.Provider>
  );
};

export const NestedTenantContainer = ({
  tenantId,
  children,
  nodeTreeStore
}: {
  tenantId: string;
  children: React.ReactNode;
  nodeTreeStore: NodeTreeStoreType;
}) => {
  const tenantValue = useContext(TenantContext);
  const overrideValue = useMemo(() => {
    if (tenantId == null) {
      return tenantValue;
    }

    return {
      ...tenantValue,
      tenantId,
      contextSettings: {
        ...tenantValue.contextSettings,
        tenantId
      }
    };
  }, [tenantValue, tenantId]);

  return (
    <TenantContext.Provider value={overrideValue}>
      <NodeTreeStoreContext.Provider value={nodeTreeStore}>
        <EventHubServiceContainer>{children}</EventHubServiceContainer>
      </NodeTreeStoreContext.Provider>
    </TenantContext.Provider>
  );
};

export const NestedTenantContainerWithoutEventHubService = ({
  tenantId,
  children,
  nodeTreeStore
}: {
  tenantId: string;
  children: React.ReactNode;
  nodeTreeStore: NodeTreeStoreType;
}) => {
  const tenantValue = useContext(TenantContext);
  const overrideValue = useMemo(() => {
    if (tenantId == null) {
      return tenantValue;
    }

    return {
      ...tenantValue,
      tenantId,
      contextSettings: {
        ...tenantValue.contextSettings,
        tenantId
      }
    };
  }, [tenantValue, tenantId]);

  return (
    <TenantContext.Provider value={overrideValue}>
      <NodeTreeStoreContext.Provider value={nodeTreeStore}>
        {children}
      </NodeTreeStoreContext.Provider>
    </TenantContext.Provider>
  );
};

export default React.memo(TenantContainer);
