import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import CommunicationAPIGen, {
  CommunicationType,
  EmailReceiverModel,
  EventIdEnum,
  NodeFilterModel,
  NotificationModelRequest
} from 'ecto-common/lib/API/CommunicationAPIGen';
import UUID from 'uuidjs';
import _ from 'lodash';
import CRUDView, { useCrudViewData } from 'ecto-common/lib/CRUDView/CRUDView';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  CustomModelDefinition,
  ModelDefinition
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelEditorNodeList, {
  NodeListModelDefinition
} from 'ecto-common/lib/ModelForm/Plugins/ModelEditorNodeList';
import AdminModelEditorUsersFromNode from '../AdminModelForm/Plugins/AdminModelEditorUsersFromNode';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import IdentityServiceAPIGen, {
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGen';

const NotificationV2NodeList = ({
  props,
  model,
  input
}: {
  props: ModelEditorProps<object, NodeFilterModel[]>;
  model: CustomModelDefinition<NotificationModelRequest, object, unknown>;
  input: NotificationModelRequest;
}) => {
  const nodeIds = useMemo(
    () => _.map(input?.nodeFilters, 'nodeId'),
    [input?.nodeFilters]
  );
  const onUpdateItem = useCallback(
    (value: string[]) => {
      const newFilters = _.map(value, (nodeId) => ({ nodeId }));
      // Eslint reports updateItem as missing in props, but it is there
      // eslint-disable-next-line react/prop-types
      props.updateItem(newFilters);
    },
    [props]
  );
  const fakeModel: NodeListModelDefinition<{ nodeIds: [] }, object> = {
    key: (input2) => input2.nodeIds,
    label: model.label,
    modelType: ModelType.NODE_LIST,
    multiSelect: true
  };
  return (
    <ModelEditorNodeList
      {...props}
      rawValue={nodeIds}
      updateItem={onUpdateItem}
      model={fakeModel}
    />
  );
};

const emptyUsers: TenantUserModel[] = [];

const NotificationV2SelectUsersDialog = ({
  props,
  model,
  input
}: {
  props: ModelEditorProps<object, EmailReceiverModel[]>;
  model: CustomModelDefinition<NotificationModelRequest, object, unknown>;
  input: NotificationModelRequest;
}) => {
  const nodeIds = useMemo(
    () => _.map(input?.nodeFilters, 'nodeId'),
    [input?.nodeFilters]
  );
  const usersQuery = IdentityServiceAPIGen.Tenant.listTenantUsers.useQuery({});

  const users = usersQuery.data?.tenantUsers ?? emptyUsers;

  const rawValue = useMemo(() => {
    return _.map(input?.receivers, (receiver) => {
      return users?.find((user) => user.email === receiver.email)?.userId;
    });
  }, [users, input?.receivers]);

  const updateItem = useCallback(
    (value: string[]) => {
      const newReceivers = _.map(value, (userId) => ({
        email: users?.find((user) => user.userId === userId)?.email,
        name: users?.find((user) => user.userId === userId)?.displayName,
        enabled: true
      }));
      // Eslint reports updateItem as missing in props, but it is there
      // eslint-disable-next-line react/prop-types
      props.updateItem(newReceivers);
    },
    [props, users]
  );

  return (
    <AdminModelEditorUsersFromNode
      {...props}
      rawValue={rawValue}
      updateItem={updateItem}
      model={model}
      nodeId={_.head(nodeIds)}
      selectLabel={T.admin.notifications.selectusers}
    />
  );
};

const models: ModelDefinition<NotificationModelRequest>[] = [
  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.admin.notifications.header.name,
    placeholder: T.admin.notifications.header.name,
    hasError: (input) => _.isEmpty(input)
  },
  {
    key: (input) => input.severityThresholdMin,
    modelType: ModelType.NUMBER,
    label: T.alarms.severityMin,
    hasError: (input) => input == null || input < 0
  },

  {
    key: (input) => input.severityThresholdMax,
    modelType: ModelType.NUMBER,
    label: T.alarms.severityMax,
    hasError: (input) => input == null || input < 0
  },
  {
    key: (input) => input.eventId,
    modelType: ModelType.OPTIONS,
    label: T.alarms.eventid,
    options: enumValues(EventIdEnum).map((value) => ({
      label: value,
      value
    }))
  },
  {
    key: (input) => input.nodeFilters,
    label: T.admin.notifications.header.location,
    modelType: ModelType.CUSTOM,
    render: (props, model, input) => {
      return (
        <NotificationV2NodeList props={props} model={model} input={input} />
      );
    },
    hasError: (input: NodeFilterModel[]) => _.isEmpty(_.map(input, 'nodeId'))
  },
  {
    key: (input) => input.receivers,
    modelType: ModelType.CUSTOM,
    render: (props, model, input) => {
      return (
        <NotificationV2SelectUsersDialog
          props={props}
          model={model}
          input={input}
        />
      );
    },
    label: T.admin.notifications.header.users,
    enabled: (input) => !_.isEmpty(_.map(input?.nodeFilters, 'nodeIds')),
    hasError: (input) => _.isEmpty(input)
  }
];

const columns: DataTableColumnProps<NotificationModelRequest>[] = [
  {
    dataKey: 'description',
    label: T.admin.notifications.header.name,
    canSort: true
  }
];

const createNewItem = (): NotificationModelRequest => ({
  id: UUID.generate(),
  nodeFilters: [],
  receivers: [],
  message: '',
  subject: '',
  communicationType: CommunicationType.Email,
  eventId: EventIdEnum.AlarmActivated
});

const NotificationsV2 = () => {
  useEffect(() => {
    document.title = T.admin.notifications.title + ' V2';
  }, []);

  const listQueryHook =
    CommunicationAPIGen.Notifications.listNotifications.useInfiniteQuery;

  const updateItemMutation =
    CommunicationAPIGen.Notifications.updateNotification.useMutation({});

  const createItemMutation =
    CommunicationAPIGen.Notifications.createNotification.useMutation({});

  const { contextSettings } = useContext(TenantContext);

  const deleteItemMutation = useMutation({
    mutationFn: (item: NotificationModelRequest) => {
      return CommunicationAPIGen.Notifications.deleteNotification.promise(
        contextSettings,
        { id: item.id },
        null
      );
    }
  });

  const crudData = useCrudViewData({
    listQueryHook,
    sortBy: 'description',
    searchItems: ['description']
  });

  return (
    <CRUDView
      models={models}
      columns={columns}
      createNewItem={createNewItem}
      itemName={'description'}
      title={T.admin.notifications.title}
      editTitle={T.admin.notifications.editnotification}
      addTitle={T.admin.notifications.addnotification}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
      {...crudData}
    />
  );
};

export default NotificationsV2;
