import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { getHelpFile, getToc } from 'ecto-common/help/markdownFiles';
import treeFind from 'ecto-common/lib/utils/treeFind';
import { getHelpRoute } from 'ecto-common/lib/utils/routeConstants';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getTitle = (toc: any, helpPath: string) =>
  treeFind(toc, 'children', { id: helpPath })?.title;

const getMarkdownFileUrl = (docName: string) =>
  'docs/' + _.replace(docName, /^.+\.\//, '');

export type UseHelpContentResult = {
  helpPath: string;
  file: string;
  onLinkClick: (docName: string) => void;
  title: string;
  fullPageLink: React.ReactNode;
};
/**
 * @param helpPath Key for unique filename
 */
const useHelpContent = (helpPath: string): UseHelpContentResult | null => {
  const { tenantId } = useContext(TenantContext);
  const language = useCommonSelector((state) => state.general.language);
  const navigate = useNavigate();

  return useMemo(() => {
    if (helpPath) {
      const file = getHelpFile(language, helpPath);
      const toc = getToc(language);
      const title = getTitle(toc, helpPath);
      const to = getHelpRoute(tenantId, helpPath);
      const fullPageLink = <Link to={to}>{T.helptooltip.gotohelp}</Link>;

      const onLinkClick = (docName: string) => {
        const route = getHelpRoute(tenantId, getMarkdownFileUrl(docName));
        navigate(route);
      };

      return { helpPath, file, onLinkClick, title, fullPageLink };
    }

    return null;
  }, [helpPath, language, tenantId, navigate]);
};

export default useHelpContent;
