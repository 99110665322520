import React, { useContext, useEffect, useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import localStore from 'store';
import {
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { Provider } from 'react-redux';
import { generatePath, Navigate, useLocation } from 'react-router-dom';

import {
  NODE_IDS_STORAGE_ADMIN,
  NODE_IDS_STORAGE_OPERATOR,
  NODES_STORAGE_ADMIN,
  NODES_STORAGE_OPERATOR
} from 'ecto-common/lib/utils/persistentNodeState';
import UserContainer from 'ecto-common/lib/Application/UserContainer';
import _ from 'lodash';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import UrlContext, {
  UrlContextInformation
} from 'ecto-common/lib/hooks/UrlContext';
import {
  DefaultError,
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  useQuery
} from '@tanstack/react-query';
import {
  hasSelectedAPIEnvironment,
  setApiEnvironment
} from 'ecto-common/lib/utils/apiEnvironment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import createAuthenticationConfiguration from 'ecto-common/lib/utils/createAuthenticationConfiguration';
import DebugLogin from 'ecto-common/lib/DebugLogin/DebugLogin';
import { getCurrentApiEnvironmentDefinition } from '../utils/apiEnvironment';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { ApiEnvironmentConfig } from 'ecto-common/lib/API/ApiConfigType';
import { toastStore } from '../Toast/ToastContainer';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticatedArea } from 'ecto-common/lib/hooks/useAuthentication';
import TenantContainer from 'ecto-common/lib/Application/TenantContainer';
import { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import locationChange from 'ecto-common/lib/actions/locationChange';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { NoValidResourcesPage } from 'ecto-common/lib/BaseContainer/NoValidResourcesPage';
import { ResourceModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ResourceType, ROOT_NODE_ID } from 'ecto-common/lib/constants';
import T from 'ecto-common/lib/lang/Language';
import Page from 'ecto-common/lib/Page/Page';
import { RouteItem } from 'ecto-common/lib/BaseContainer/BaseContainer';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { getLastNodeId } from 'ecto-common/lib/utils/cacheKeys';
import {
  createNodeTreeStore,
  NodeTreeStoreContext
} from 'ecto-common/lib/LocationTreeView/NodeTreeStore';
import { useStore } from 'zustand';

// Import and inject moment timezone into moment object.
_.noop(moment);
_.noop(momentTimezone);

type AppContainerProps = {
  store: ToolkitStore;
  urlData: UrlContextInformation;
  children: React.ReactNode;
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: DefaultError, query: Query) => {
      if (query.meta?.errorString) {
        toastStore.addErrorToast(query.meta.errorString as string);
      } else {
        console.error('Query error', error);
      }
    }
  }),
  mutationCache: new MutationCache({
    onError: (
      error: DefaultError,
      _variable: unknown,
      _context: unknown,
      mutation: Mutation
    ) => {
      if (mutation.meta?.errorString) {
        toastStore.addErrorToast(mutation.meta.errorString as string);
      } else {
        console.error('Mutation error', error);
      }
    }
  })
});

const EnvironmentPickerWrapper = ({
  mainContainer
}: {
  mainContainer: React.ReactNode;
}) => {
  if (process.env.DEV_BUILD && !hasSelectedAPIEnvironment()) {
    return <DebugLogin />;
  }
  return <>{mainContainer}</>;
};

const ApplicationRootWithEnvSettings = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const dispatch = useCommonDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(locationChange(location));
  }, [dispatch, location]);

  return <TenantContainer>{children}</TenantContainer>;
};

export const ApplicationRoot = ({
  store,
  urlData,
  children
}: AppContainerProps) => {
  const definition = getCurrentApiEnvironmentDefinition();

  const envQuery = useQuery({
    queryKey: ['environment', definition.filename],

    queryFn: async () => {
      return fetch('/' + definition.filename).then((r) => {
        if (r.status !== 200) {
          throw new Error();
        }
        return r.json() as unknown as ApiEnvironmentConfig;
      });
    },

    retry: 1,
    refetchOnWindowFocus: false
  });

  const msalConfiguration: IPublicClientApplication = useMemo(() => {
    if (envQuery.data == null) {
      return null;
    }

    const configuration = createAuthenticationConfiguration(
      envQuery.data.authority,
      envQuery.data.clientId,
      envQuery.data.knownAuthorities
    );
    return new PublicClientApplication(configuration);
  }, [envQuery.data]);

  const nodeTreeStore = useMemo(() => {
    return createNodeTreeStore();
  }, []);

  if (!envQuery.data) {
    if (envQuery.error) {
      return (
        <div>
          <h1>{T.common.environmenterror} </h1>
        </div>
      );
    }
    return null;
  }

  setApiEnvironment(envQuery.data);

  return (
    <MsalProvider instance={msalConfiguration}>
      <Provider store={store}>
        <NodeTreeStoreContext.Provider value={nodeTreeStore}>
          <UrlContext.Provider value={urlData}>
            <UserContainer msalConfiguration={msalConfiguration}>
              <AuthenticatedArea>
                <ApplicationRootWithEnvSettings children={children} />
              </AuthenticatedArea>
            </UserContainer>
          </UrlContext.Provider>
        </NodeTreeStoreContext.Provider>
      </Provider>
    </MsalProvider>
  );
};

export default (mainContainer: React.ReactNode) => {
  // Clear out old local store keys. Non-prefixed variants of these are deprecated, they were
  // used when we did not have tenant support.
  localStore.remove(NODES_STORAGE_OPERATOR);
  localStore.remove(NODE_IDS_STORAGE_OPERATOR);
  localStore.remove(NODES_STORAGE_ADMIN);
  localStore.remove(NODE_IDS_STORAGE_ADMIN);

  function handleFirstTab(e: KeyboardEvent) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
    }
  }

  function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  }

  window.addEventListener('keydown', handleFirstTab);

  window.onload = () => {
    const container = document.getElementById('main');
    const root = createRoot(container!);

    root.render(
      <QueryClientProvider client={queryClient}>
        <EnvironmentPickerWrapper mainContainer={mainContainer} />
      </QueryClientProvider>
    );
  };
};

export const FallbackRouteHandler = ({
  allowNoLocation = false,
  allRoutes
}: {
  allowNoLocation?: boolean;
  allRoutes: RouteItem[];
}) => {
  const { tenantId, tenantResources } = useContext(TenantContext);

  const nodeTreeStore = useContext(NodeTreeStoreContext);

  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  return calculateFallbackLocation(
    tenantId,
    tenantResources,
    allRoutes,
    rootLevelNodes,
    allowNoLocation
  );
};

const hasAccessToRoute = (
  route: RouteItem,
  tenantResources: ResourceModel[]
) => {
  return (
    (route.resourceTypes == null ||
      _.every(route.resourceTypes, (resourceType) =>
        hasAccessToResource(resourceType, tenantResources)
      )) &&
    (route.hideIfHasResourceTypes == null ||
      _.every(
        route.hideIfHasResourceTypes,
        (resourceType) => !hasAccessToResource(resourceType, tenantResources)
      ))
  );
};

export const calculateFallbackLocation = (
  tenantId: string,
  tenantResources: ResourceModel[],
  allRoutes: RouteItem[],
  rootLevelNodes: NodeV2ResponseModel[],
  allowNoLocation: boolean
) => {
  let newLocation: string = null;

  if (
    hasAccessToResource(ResourceType.CORE, tenantResources) &&
    !hasAccessToResource(ResourceType.TEMPLATE_MANAGEMENT, tenantResources)
  ) {
    const { nodeId: lastNodeId } = getLastNodeId(tenantId);
    const nodeId = lastNodeId === ROOT_NODE_ID ? null : lastNodeId;

    if (rootLevelNodes.length === 0) {
      if (allowNoLocation) {
        newLocation = `/${tenantId}/noLocations`;
      } else {
        newLocation = `/${tenantId}/home/${ROOT_NODE_ID}/dashboard`;
      }
    } else if (nodeId != null && nodeId !== 'null' && nodeId !== 'undefined') {
      newLocation = `/${tenantId}/home/${nodeId}/dashboard`;
    } else {
      newLocation = `/${tenantId}/home/${rootLevelNodes[0].nodeId}/dashboard`;
    }

    if (newLocation != null) {
      return <Navigate to={newLocation} replace />;
    }
  }

  const validPages = _.filter(
    allRoutes,
    (page) =>
      !page.path.endsWith('noLocations') &&
      hasAccessToRoute(page, tenantResources)
  );

  const firstValidPage = _.head(validPages);

  if (firstValidPage == null) {
    return <NoValidResourcesPage />;
  }

  newLocation = generatePath(firstValidPage.path, { tenantId });

  if (newLocation != null) {
    if (!newLocation.endsWith('/')) {
      newLocation += '/';
    }
    return <Navigate to={newLocation} replace />;
  }

  return (
    <Page content={<ToolbarContentPage title={T.common.unknownerror} />} />
  );
};

export const AccessControlledRoute = ({
  route,
  allRoutes,
  allowNoLocation = false
}: {
  route: RouteItem;
  allRoutes: RouteItem[];
  allowNoLocation?: boolean;
}) => {
  const { tenantId, tenantResources } = useContext(TenantContext);
  const hasAccess = hasAccessToRoute(route, tenantResources);
  const nodeTreeStore = useContext(NodeTreeStoreContext);

  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  if (!hasAccess) {
    return calculateFallbackLocation(
      tenantId,
      tenantResources,
      allRoutes,
      rootLevelNodes,
      allowNoLocation
    );
  }

  return <> {route.element} </>;
};
