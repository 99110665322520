import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import EditButton from 'ecto-common/lib/Button/EditButton';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { addOrEditColumnsColumn } from 'ecto-common/lib/utils/dataTableUtils';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import EditComfortTool from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditComfortTool';
import { EditState } from 'js/components/ManageEquipment/EditEquipment/EditEquipmentTools';
import BatchEditComfortTools from 'js/components/EditLocation/Tools/Comfort/BatchEditComfortTools';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import EditComfortDeployDialog from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortDeployDialog';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import APIGen, {
  ComfortHeatingProviderResponseModel,
  DeviceInfoResponseModel,
  EquipmentTypeResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DropdownButtonOptionType } from 'ecto-common/lib/DropdownButton/DropdownButton';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useNode, useNodeChildren } from 'ecto-common/lib/hooks/useCurrentNode';

const RadiatorEquipmentTypeIds = [
  'efaafbbd-ea3a-e711-81f6-2816ad57bfd8',
  'a2b1fb46-a98f-4422-bd25-a6c34a53b7a8',
  'dcc747f5-11de-495c-b904-9ac443466e06'
];

const getColumns = (
  selectedEquipmentIds: string[],
  setSelectedEquipmentIds: Dispatch<SetStateAction<string[]>>,
  allEquipments: ComfortEquipmentWithToolData[],
  createEditOptions: (
    item: ComfortEquipmentWithToolData
  ) => DropdownButtonOptionType[],
  onDelete: (item: ComfortEquipmentWithToolData) => void,
  onAdd: (item: ComfortEquipmentWithToolData) => void
): DataTableColumnProps<ComfortEquipmentWithToolData>[] => {
  const allEquipmentIds = _.map(allEquipments, 'nodeId');
  const allSelected = allEquipmentIds.length === selectedEquipmentIds.length;
  const anySelected = selectedEquipmentIds.length > 0;
  const shouldShowAdd = (eq: ComfortEquipmentWithToolData) => eq.tool == null;

  return [
    {
      label: (
        <Checkbox
          checked={allSelected}
          partiallyChecked={!allSelected && anySelected}
          onChange={(checked) =>
            setSelectedEquipmentIds(checked ? allEquipmentIds : [])
          }
        />
      ),
      dataKey: 'nodeId',
      minWidth: 44,
      maxWidth: 44,
      dataFormatter: (equipmentId: string) => {
        return (
          <Checkbox
            checked={selectedEquipmentIds.includes(equipmentId)}
            onChange={(checked) => {
              setSelectedEquipmentIds((oldSelected) => {
                const filtered = _.without(oldSelected, equipmentId);

                if (checked) {
                  return [...filtered, equipmentId];
                }

                return filtered;
              });
            }}
          />
        );
      }
    },
    {
      label: T.admin.editlocation.tools.comfort.columns.radiatorname,
      dataKey: 'name',
      maxWidth: 250,
      minWidth: 250
    },
    {
      label: T.admin.editlocation.tools.status,
      dataKey: 'tool',
      dataFormatter: (tool, equipment) => {
        if (equipment.toolsIsLoading) {
          return T.common.loading;
        }

        return tool != null
          ? T.admin.editlocation.tools.configured
          : T.admin.editlocation.tools.readytoadd;
      }
    },
    ...addOrEditColumnsColumn(
      null,
      createEditOptions,
      onDelete,
      onAdd,
      null,
      shouldShowAdd
    )
  ];
};

const equipmentTypeIsRadiator = (type: EquipmentTypeResponseModel) => {
  return RadiatorEquipmentTypeIds.includes(type.equipmentTypeId);
};

interface ManageComfortToolsProps {
  nodeId?: string;
}

export type ComfortEquipmentWithToolData = NodeV2ResponseModel & {
  tool: ComfortHeatingProviderResponseModel;
  toolsIsLoading: boolean;
};

const ManageComfortTools = ({ nodeId }: ManageComfortToolsProps) => {
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const { node: location } = useNode(nodeId);
  const [comfortTools, setComfortTools] = useState<
    ComfortHeatingProviderResponseModel[]
  >([]);
  const [selectedEquipmentIdsToEdit, setSelectedEquipmentIdsToEdit] = useState<
    string[]
  >([]);
  const [selectedEquipment, setSelectedEquipment] =
    useState<ComfortEquipmentWithToolData>(null);
  const [editState, setEditState] = useState({
    state: EditState.IDLE,
    data: null
  });
  const [batchEquipments, setBatchEquipments] = useState<
    ComfortEquipmentWithToolData[]
  >([]);
  const cancelBatchEditing = useCallback(() => setBatchEquipments([]), []);
  const [reloadTrigger, triggerReload] = useReloadTrigger();
  const [isShowingBatchDeploy, showBatchDeploy, hideBatchDeploy] =
    useSimpleDialogState();
  const [isShowingAskToDeploy, showAskToDeploy, hideAskToDeploy] =
    useSimpleDialogState();

  const locationChildrenQuery = useNodeChildren([location?.nodeId]);

  const radiators = useMemo(() => {
    const radiatorEquipmentTypeIds = _(equipmentTypes)
      .filter((type) => equipmentTypeIsRadiator(type))
      .map('equipmentTypeId')
      .value();

    return _(locationChildrenQuery.nodeChildren ?? [])
      .filter((equipment) => {
        return _.some(equipment.nodeTraits, ({ nodeTraitId }) => {
          return radiatorEquipmentTypeIds.includes(nodeTraitId);
        });
      })
      .orderBy(['name'])
      .value();
  }, [equipmentTypes, locationChildrenQuery.nodeChildren]);

  const deviceInfoQuery =
    APIGen.AdminDevices.getDeviceEquipmentInfoFromEquipmentIds.useQuery({
      equipmentIds: _.map(radiators, 'nodeId')
    });

  const equipmentDeviceMap: Record<string, DeviceInfoResponseModel> =
    useMemo(() => {
      return _.keyBy(deviceInfoQuery.data, 'nodeId');
    }, [deviceInfoQuery.data]);

  const comfortToolsQuery =
    APIGen.AdminComfort.getComfortHeatingProvidersByNodeId.useQuery({
      nodeIds: [nodeId]
    });

  useEffect(() => {
    setComfortTools(comfortToolsQuery.data || []);
  }, [comfortToolsQuery.data]);

  const toolsIsLoading =
    comfortToolsQuery.isLoading || deviceInfoQuery.isLoading;

  const radiatorsWithToolData: ComfortEquipmentWithToolData[] = useMemo(() => {
    return _.map(radiators, (equipment) => ({
      ...equipment,
      tool: _.find(comfortTools, ['equipmentId', equipment.nodeId]),
      toolsIsLoading
    }));
  }, [comfortTools, toolsIsLoading, radiators]);

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  useEffect(() => {
    setSelectedEquipmentIdsToEdit([]);
    queryClient.invalidateQueries({
      queryKey:
        APIGen.AdminComfort.getComfortHeatingProvidersByNodeId.path(
          contextSettings
        )
    });
  }, [reloadTrigger, queryClient, contextSettings]);

  useEffect(() => {
    if (editState.state === EditState.IDLE) {
      setSelectedEquipment(null);
    }
  }, [editState]);

  const onAdd = useCallback((item: ComfortEquipmentWithToolData) => {
    setSelectedEquipment(item);
    setEditState({ state: EditState.CREATE, data: null });
  }, []);

  const onDelete = useCallback((item: ComfortEquipmentWithToolData) => {
    setEditState({ state: EditState.DELETE, data: item.tool });
  }, []);

  const createEditOptions = useCallback(
    (equipment: ComfortEquipmentWithToolData) => [
      {
        icon: <Icons.Edit />,
        label: T.common.edit,
        action: () => {
          setSelectedEquipment(equipment);
          setEditState({ state: EditState.EDIT, data: equipment.tool });
        }
      },
      {
        icon: <Icons.Deploy />,
        label: T.admin.equipment.deploytool,
        action: () => {
          setSelectedEquipment(equipment);
          setEditState({ state: EditState.DEPLOY, data: equipment.tool });
        }
      }
    ],
    []
  );

  const columns: DataTableColumnProps<ComfortEquipmentWithToolData>[] = useMemo(
    () =>
      getColumns(
        selectedEquipmentIdsToEdit,
        setSelectedEquipmentIdsToEdit,
        radiatorsWithToolData,
        createEditOptions,
        onDelete,
        onAdd
      ),
    [
      onAdd,
      onDelete,
      selectedEquipmentIdsToEdit,
      setSelectedEquipmentIdsToEdit,
      radiatorsWithToolData,
      createEditOptions
    ]
  );

  const onToolsSaved = useCallback(
    (newTools: ComfortHeatingProviderResponseModel[]) => {
      const eqIds = _.map(newTools, 'equipmentId');
      showAskToDeploy();

      setComfortTools((oldComfortTools) => {
        return [
          ..._.reject(oldComfortTools, (x) => eqIds.includes(x.equipmentId)),
          ...newTools
        ];
      });
    },
    [showAskToDeploy]
  );

  const batchEdit = useCallback(() => {
    const selectedRelations = _.pick(
      equipmentDeviceMap,
      selectedEquipmentIdsToEdit
    );
    const deviceIds = _(selectedRelations)
      .map('deviceId')
      .uniqBy(_.identity)
      .value();

    if (deviceIds.length === 1) {
      setBatchEquipments(
        _.filter(radiatorsWithToolData, (x) =>
          selectedEquipmentIdsToEdit.includes(x.nodeId)
        )
      );
    } else {
      toastStore.addErrorToast(
        T.admin.editlocation.tools.comfort.error.batchmultipledevices
      );
    }
  }, [selectedEquipmentIdsToEdit, radiatorsWithToolData, equipmentDeviceMap]);

  const confirmAskToDeploy = useCallback(() => {
    hideAskToDeploy();
    showBatchDeploy();
  }, [hideAskToDeploy, showBatchDeploy]);

  const setEditStateWrapped = useCallback(
    (_type: string, value: typeof editState) => {
      setEditState(value);
    },
    [setEditState]
  );

  const hasError = comfortToolsQuery.isError || deviceInfoQuery.isError;

  return (
    <div>
      {!hasError && (
        <>
          <DataTable<ComfortEquipmentWithToolData>
            data={radiatorsWithToolData}
            columns={columns}
            isLoading={toolsIsLoading}
            noDataText={T.admin.editlocation.tools.comfort.noradiators}
          />
          <DataTableFooter alignRight>
            <GreyButton
              disabled={radiatorsWithToolData.length === 0}
              onClick={showBatchDeploy}
            >
              <Icons.Deploy />
              {T.admin.editlocation.tools.comfort.batchdeploy}
            </GreyButton>
            <EditButton
              disabled={selectedEquipmentIdsToEdit.length < 2}
              onClick={batchEdit}
            >
              {T.admin.editlocation.tools.comfort.batchedittools}
            </EditButton>
          </DataTableFooter>
        </>
      )}
      {hasError && <ErrorNotice> {T.admin.comfort.error.loading}</ErrorNotice>}
      <EditComfortTool
        nodeId={nodeId}
        equipmentId={selectedEquipment?.nodeId}
        editState={editState}
        setEditState={setEditStateWrapped}
        reloadTools={triggerReload}
      />
      <BatchEditComfortTools
        equipments={batchEquipments}
        isOpen={batchEquipments.length > 0}
        onModalClose={cancelBatchEditing}
        onToolsSaved={onToolsSaved}
      />
      <EditComfortDeployDialog
        tools={comfortTools}
        deviceStatusReloadTrigger={reloadTrigger}
        onModalClose={hideBatchDeploy}
        isOpen={isShowingBatchDeploy}
      />
      <ActionModal
        isOpen={isShowingAskToDeploy}
        onModalClose={hideAskToDeploy}
        title={T.admin.comfort.askdeploytitle}
        onConfirmClick={confirmAskToDeploy}
      >
        {T.admin.comfort.askdeploymessage}
      </ActionModal>
    </div>
  );
};

export default ManageComfortTools;
