import { useMemo } from 'react';
import _ from 'lodash';

import { useAvailableSignalsForNodes } from 'ecto-common/lib/hooks/useAvailableSignals';
import T from 'ecto-common/lib/lang/Language';
import NodeDataSource, { nodeDataSourceSections } from './NodeDataSource';
import { text } from 'ecto-common/lib/ModelForm/formUtils';
import {
  FullSignalProviderResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { PromiseCacheContext } from 'ecto-common/lib/Dashboard/datasources/signalUtils';
import { useNodeEquipmentChildren } from 'ecto-common/lib/hooks/useCurrentNode';

interface SignalProvidersDataSourceProps {
  nodeId?: string;
  equipmentName?: string;
  cacheContext: PromiseCacheContext;
}

export type SignalProvidersDataSourceResult = {
  node: NodeV2ResponseModel;
  useNode: boolean;
  // equipmentId?: string;
  signalProviders: FullSignalProviderResponseModel[];
  isLoading: boolean;
  hasError: boolean;
};

const SignalProvidersDataSource = ({
  nodeId,
  equipmentName,
  cacheContext
}: SignalProvidersDataSourceProps) => {
  const node = NodeDataSource({ nodeId });
  const useNode = _.isEmpty(_.trim(equipmentName));
  const nodeList = useMemo(() => _.compact([node]), [node]);

  const nodeIds = useMemo(() => {
    return _.compact([nodeId]);
  }, [nodeId]);

  const { equipmentChildren } = useNodeEquipmentChildren(nodeIds);

  const equipment = _.find(equipmentChildren, ['name', equipmentName]);
  const equipmentList = useMemo(() => _.compact([equipment]), [equipment]);

  const [isLoading, hasError, signalsForNode] = useAvailableSignalsForNodes(
    useNode ? nodeList : equipmentList,
    equipmentChildren,
    cacheContext
  );
  const signalProviders = useMemo(
    () => _.map(signalsForNode?.signalProviders),
    [signalsForNode]
  );
  return {
    node,
    useNode,
    signalProviders,
    isLoading,
    hasError
  };
};

export const signalProvidersDataSourceSections: () => ModelFormSectionType<SignalProvidersDataSourceProps>[] =
  () => [
    ...nodeDataSourceSections(),
    {
      lines: [
        {
          models: [text((input) => input.equipmentName, T.admin.equipment.name)]
        }
      ]
    }
  ];

export default SignalProvidersDataSource;
