import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { createStore } from 'zustand';
import _ from 'lodash';
import { createContext } from 'react';

type NodeTreeStore = {
  rootLevelNodes: NodeV2ResponseModel[];
  allNodes: NodeV2ResponseModel[];
  allNodesMap: Record<string, NodeV2ResponseModel>;
  loadingState: Record<string, boolean>;
  emptyChildren: Record<string, boolean>;

  setRootLevelNodes: (nodes: NodeV2ResponseModel[]) => void;
  clearAllNodes: () => void;
  addNodes: (nodes: NodeV2ResponseModel[]) => void;
  removeNodesWithIds: (nodeIds: string[]) => void;
  removeNode: (nodeId: string) => void;
  setLoading(nodeId: string, isLoading: boolean): void;
  setNoChildren(nodeId: string, hasNoChildren: boolean): void;
};

export function createNodeTreeStore() {
  return createStore<NodeTreeStore>((set) => ({
    allNodes: [],
    allNodesMap: {},
    rootLevelNodes: [],
    loadingState: {},
    emptyChildren: {},
    clearAllNodes: () => {
      set(() => ({
        allNodes: [],
        allNodesMap: {},
        rootLevelNodes: [],
        loadingState: {},
        emptyChildren: {}
      }));
    },
    removeNode: (nodeId: string) => {
      set((state) => {
        const newNodes = state.allNodes.filter(
          (node) => node.nodeId !== nodeId
        );

        const newRootLeelNodes = state.rootLevelNodes.filter(
          (node) => node.nodeId !== nodeId
        );

        return {
          ...state,
          allNodes: newNodes,
          allNodesMap: _.omit(state.allNodesMap, nodeId),
          rootLevelNodes: newRootLeelNodes
        };
      });
    },
    removeNodesWithIds: (nodeIds: string[]) => {
      set((state) => {
        const newNodes = state.allNodes.filter(
          (node) => !nodeIds.includes(node.nodeId)
        );

        const newRootLeelNodes = state.rootLevelNodes.filter(
          (node) => !nodeIds.includes(node.nodeId)
        );

        return {
          ...state,
          allNodes: newNodes,
          allNodesMap: _.omit(state.allNodesMap, nodeIds),
          rootLevelNodes: newRootLeelNodes
        };
      });
    },
    setRootLevelNodes: (nodes: NodeV2ResponseModel[]) => {
      set((state) => {
        const newNodeIds = nodes.map((node) => node.nodeId);
        const newNodes = state.rootLevelNodes
          .filter((node) => !newNodeIds.includes(node.nodeId))
          .concat(nodes);

        return {
          ...state,
          rootLevelNodes: newNodes
        };
      });
    },
    addNodes: (nodes: NodeV2ResponseModel[]) => {
      set((state) => {
        const newNodeIds = nodes.map((node) => node.nodeId);
        const newNodes = state.allNodes
          .filter((node) => !newNodeIds.includes(node.nodeId))
          .concat(nodes);

        const newEmptyChildren = { ...state.emptyChildren };

        for (const node of nodes) {
          newEmptyChildren[node.nodeId] = !node.hasChildren;
          if (node.parentId != null) {
            newEmptyChildren[node.parentId] = false;
          }
        }

        return {
          ...state,
          allNodes: newNodes,
          allNodesMap: _.keyBy(newNodes, (item) => item.nodeId),
          emptyChildren: newEmptyChildren
        };
      });
    },
    setLoading(nodeId: string, isLoading: boolean) {
      set((state) => ({
        ...state,
        loadingState: {
          ...state.loadingState,
          [nodeId]: isLoading
        }
      }));
    },
    setNoChildren(nodeId: string, hasNoChildren: boolean) {
      set((state) => ({
        ...state,
        emptyChildren: {
          ...state.emptyChildren,
          [nodeId]: hasNoChildren
        }
      }));
    }
  }));
}

export type NodeTreeStoreType = ReturnType<typeof createNodeTreeStore>;
export const NodeTreeStoreContext = createContext<NodeTreeStoreType>(null);
