import React, { useCallback, useContext, useMemo } from 'react';
import styles from './PageTreeView.module.css';
import { useNavigate, matchPath, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import APIGen, { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import {
  useCommonDispatch,
  useCommonSelector
} from 'ecto-common/lib/reducers/storeCommon';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import UrlContext from 'ecto-common/lib/hooks/UrlContext';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { SET_CURRENT_NODE } from 'ecto-common/lib/actions/actionTypes';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import T from 'ecto-common/lib/lang/Language';
import useDialogState, {
  useSearchParamState
} from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import LocationSearch from 'ecto-common/lib/Page/LocationSearch';

import GreyButton from 'ecto-common/lib/Button/GreyButton';
import NodesAdvancedSearchDialog from 'ecto-common/lib/Page/NodesAdvancedSearchDialog';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import useNodeTree from 'ecto-common/lib/LocationTreeView/useNodeTree';
import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';
import { clearNodeSearchTableURLState } from 'ecto-common/lib/Page/NodeSearchTable';

interface PageTreeViewProps {
  isOpen?: boolean;
  urlBuilder?(
    tenantId: string,
    nodeId: string,
    subPage?: string,
    searchParams?: URLSearchParams
  ): string;
  selectEquipment?: boolean;
  disablePageChange?: boolean;
}

function PageTreeView({
  isOpen,
  urlBuilder,
  selectEquipment,
  disablePageChange
}: PageTreeViewProps) {
  _.noop(selectEquipment, disablePageChange);
  const [searchTerm, setSearchTerm] = useSearchParamState('search', null);

  const { locationRoute } = useContext(UrlContext);
  const defaultUrlBuilder = useCallback(
    (newTenantId: string, newNodeId: string, _subPage: string) => {
      const params = matchPath<NodeParams, string>(
        locationRoute.path,
        window.location.pathname
      )?.params;

      return `/${newTenantId}/home/${newNodeId}/${params?.page ?? ''}`;
    },
    [locationRoute]
  );

  const nodeId = useCommonSelector((state) => state.general.nodeId);

  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);

  const onNavigateToRootNode = useCallback(
    (rootLevelNode: NodeV2ResponseModel) => {
      const newLocation = `/${tenantId}/home/${rootLevelNode.nodeId}/dashboard`;
      navigate(newLocation);
    },
    [navigate, tenantId]
  );

  const selectedIds = useMemo(() => {
    return [nodeId];
  }, [nodeId]);

  const {
    nodes,
    renderRowSideIcons,
    isLoadingHierarchy,
    nodeHasChildren,
    onExpandedStateChange,
    expanded,
    renderRowIcons
  } = useNodeTree(nodeId, onNavigateToRootNode);

  const _urlBuilder = urlBuilder ?? defaultUrlBuilder;

  const dispatch = useCommonDispatch();
  const [searchParams] = useSearchParams();

  const updateLocation = useCallback(
    (newNodeId: string) => {
      const startingUrl = window.location.href;
      if (!disablePageChange) {
        navigate(_urlBuilder(tenantId, newNodeId, null, searchParams));
      }
      if (window.location.href !== startingUrl || disablePageChange) {
        dispatch({
          type: SET_CURRENT_NODE,
          payload: {
            nodeId: newNodeId
          }
        });
      }
    },
    [_urlBuilder, disablePageChange, dispatch, navigate, searchParams, tenantId]
  );

  const hasSearchTerm = !isNullOrWhitespace(searchTerm);

  const searchQuery = APIGen.NodesV2.searchForNodes.useQuery(
    {
      nodeTraitIds: [],
      nodePropertySearchRequestModels: [],
      searchPhrase: searchTerm,
      pageSize: 20
    },
    {
      enabled: hasSearchTerm
    }
  );

  const [showingAdvancedSearch, showAdvancedSearch, hideAdvancedSearch] =
    useDialogState('advanced-search', clearNodeSearchTableURLState);

  return (
    <div className={classNames(styles.locationTree, isOpen && styles.open)}>
      <SearchInput
        autoFocus={isOpen}
        tabIndex={isOpen ? undefined : -1}
        wrapperClassName={styles.searchFieldContainer}
        placeholder={T.sidebar.location.search.placeholder}
        value={searchTerm}
        onChange={setSearchTerm}
        clearButton
      />

      <div className={styles.buttonSearchContainer}>
        <GreyButton onClick={showAdvancedSearch} style={{ width: '100%' }}>
          <Icons.Search />
          {T.nodes.advancedsearch}...
        </GreyButton>
      </div>
      {isLoadingHierarchy && !hasSearchTerm && (
        <div className={styles.loadingContainer}>
          <Spinner size={SpinnerSize.SMALL} />
        </div>
      )}
      {hasSearchTerm && (
        <LocationSearch
          isLoading={searchQuery.isLoading}
          searchResults={searchQuery.data}
          onNodeSelected={updateLocation}
        />
      )}
      {!isLoadingHierarchy && !hasSearchTerm && (
        <LocationTreeView
          nodeList={nodes}
          onChangeSelectedState={updateLocation}
          selectedIds={selectedIds}
          renderRowSideIcons={renderRowSideIcons}
          expanded={expanded}
          onExpandedStateChange={onExpandedStateChange}
          nodeHasChildren={nodeHasChildren}
          focusedId={nodeId}
          renderRowIcons={renderRowIcons}
        />
      )}
      <NodesAdvancedSearchDialog
        isOpen={showingAdvancedSearch}
        onClose={hideAdvancedSearch}
        onClickRow={(node) => {
          updateLocation(node.nodeId);
          hideAdvancedSearch();
          setSearchTerm(null);
        }}
      />
    </div>
  );
}

export default PageTreeView;
