import React from 'react';
import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { AuthenticationContext } from 'ecto-common/lib/hooks/useAuthentication';
import {
  SimpleEventHubMethod,
  useSimpleEventHubConnection
} from 'ecto-common/lib/EventHubConnection/useSimpleEventHubConnection';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { HubConnection } from '@microsoft/signalr';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';
import { AlarmCountEvent } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import EventEmitter from 'ecto-common/lib/utils/EventEmitter';

export const AlarmEventTypes = {
  Created: 0,
  Activated: 1,
  Acknowledged: 2,
  Inactivated: 3
};

export type AlarmEventType = ObjectValues<typeof AlarmEventTypes>;

export type AlarmEvent = {
  nodeId: string;
  sourceUri: string;
  signalId?: string;
  signalName?: string;
  severity: number;
  eventType: AlarmEventType;
};

export const AlarmUpdateContext = createContext<{
  alarmEvents: EventEmitter<AlarmEvent>;
}>({
  alarmEvents: new EventEmitter<AlarmEvent>()
});

const useAlarmSubscription = ({
  methods,
  onStart,
  enabled = true
}: {
  methods: SimpleEventHubMethod[];
  onStart: (connection: HubConnection) => void;
  enabled?: boolean;
}) => {
  const { scopes, msalConfiguration, currentAccount } = useContext(
    AuthenticationContext
  );
  const onError = useCallback((error: unknown) => {
    console.error(error);
  }, []);

  const alarmScopes = useMemo(() => [scopes.alarms], [scopes]);
  const url = useMemo(
    () =>
      getApiEnvironment().urls.alarmsSignalRUrl?.replace('/api', '') +
      '/alarmEventsHub',
    []
  );
  const { contextSettings } = useContext(TenantContext);

  useSimpleEventHubConnection(
    contextSettings,
    onError,
    url,
    alarmScopes,
    msalConfiguration,
    currentAccount,
    methods,
    onStart,
    enabled
  );
};

export const useAlarmCountUpdates = ({
  nodeIds,
  isRecursive,
  onAlarmCountsChanged
}: {
  nodeIds: string[];
  isRecursive: boolean;
  onAlarmCountsChanged?: (event: AlarmCountEvent[]) => void;
}) => {
  const methods = useMemo(
    () => [
      {
        method: 'AlarmCountsChanged',
        handler: (data: unknown) => {
          onAlarmCountsChanged?.(data as AlarmCountEvent[]);
        }
      }
    ],
    [onAlarmCountsChanged]
  );

  const { tenantId } = useContext(TenantContext);

  const connectionRef = useRef<HubConnection>(null);

  const onStart = useCallback(
    (connection: HubConnection) => {
      connectionRef.current = connection;
      connection.invoke(
        'SubscribeToAlarmCountEvents',
        nodeIds,
        tenantId,
        isRecursive
      );
    },
    [nodeIds, isRecursive, tenantId]
  );

  useAlarmSubscription({ methods, onStart, enabled: nodeIds.length > 0 });
};

export const useAlarmUpdates = ({
  onAlarmEvent
}: {
  onAlarmEvent?: (event: AlarmEvent) => void;
}) => {
  const methods = useMemo(
    () => [
      {
        method: 'SendAlarmEvent',
        handler: (data: unknown) => {
          onAlarmEvent?.(data as AlarmEvent);
        }
      }
    ],
    [onAlarmEvent]
  );

  const { tenantId } = useContext(TenantContext);

  const onStart = useCallback(
    (connection: HubConnection) => {
      const severity = 0;
      connection.invoke('Subscribe', tenantId, severity);
    },
    [tenantId]
  );
  useAlarmSubscription({ methods, onStart });
};

export const AlarmUpdateProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const contextData = useMemo(() => {
    return {
      alarmEvents: new EventEmitter<AlarmEvent>()
    };
  }, []);

  const onAlarmEvent = useCallback(
    (event: AlarmEvent) => {
      contextData.alarmEvents.emit(event);
    },
    [contextData.alarmEvents]
  );

  useAlarmUpdates({ onAlarmEvent });
  return (
    <AlarmUpdateContext.Provider value={contextData}>
      {children}
    </AlarmUpdateContext.Provider>
  );
};
