import React from 'react';
import styles from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import CopyToClipboardTooltip from '../CopyToClipboardTooltip/CopyToClipboardTooltip';
import ToolbarNavControl from 'ecto-common/lib/ToolbarContentPage/ToolbarNavControl';
import {
  nodeIsBuilding,
  nodeIsEnergyManager,
  nodeIsEquipment,
  nodeIsSite,
  useCurrentNode
} from 'ecto-common/lib/hooks/useCurrentNode';
import {
  NodeV2ResponseModel,
  NodeParentInformationResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DeviceIcon } from 'ecto-common/lib/Icon';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import _ from 'lodash';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';

export const getSubtitle = (
  node: NodeV2ResponseModel,
  parents: NodeParentInformationResponseModel[],
  hasEquipment = false
) => {
  if (node == null) {
    return ['', ''];
  }

  const rootParent = parents.find((parent) => parent.nodeId === node.parentId);
  let parent = rootParent;
  let parentText = '';

  const MAX_PARENT_TEXT_LENGTH = 70;

  const indices = [];

  const visitedParents: Record<string, boolean> = {};

  while (parent != null && !visitedParents[parent.nodeId]) {
    visitedParents[parent.nodeId] = true;
    parentText = parent.name + parentText;
    parent = parents.find((p) => p.nodeId === parent.parentId);

    if (parent) {
      parentText = ' > ' + parentText;
      indices.push(parentText.length);
    }
  }

  if (hasEquipment) {
    parentText += ' > ' + node.name;
  }

  if (parentText.length > MAX_PARENT_TEXT_LENGTH && rootParent != null) {
    if (hasEquipment) {
      let abbreviated = '... > ' + rootParent.name + ' > ' + node.name;
      if (abbreviated.length > MAX_PARENT_TEXT_LENGTH) {
        abbreviated = '... > ' + node.name;
      }

      return [abbreviated, parentText];
    }

    return ['... > ' + rootParent.name, parentText];
  }

  return [parentText, parentText];
};

interface ToolbarLocationPickerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ToolbarLocationPicker = ({
  isOpen,
  setIsOpen
}: ToolbarLocationPickerProps) => {
  const { nodeId, currentNode, parents } = useCurrentNode();

  let icon = null;
  let largeIcon = false;
  const isEquipment = nodeIsEquipment(currentNode);

  if (nodeIsBuilding(currentNode)) {
    icon = <Icons.Building />;
  } else if (nodeIsSite(currentNode)) {
    icon = <Icons.Site />;
  } else if (nodeIsEnergyManager(currentNode)) {
    icon = <Icons.EnergyManager />;
  } else if (isEquipment) {
    largeIcon = true;
    icon = <DeviceIcon />;
  }

  const currentNodeName = nodeId === ROOT_NODE_ID ? '' : currentNode?.name;

  const locationText =
    currentNodeName != null ? (
      currentNodeName
    ) : (
      <Spinner size={SpinnerSize.TINY} />
    );
  const [subtitle, fullSubtitle] = getSubtitle(
    currentNode,
    parents,
    isEquipment
  );

  const navTitle = (
    <>
      <CopyToClipboardTooltip valueToCopy={currentNode?.name ?? ''}>
        <label>{locationText}</label>
      </CopyToClipboardTooltip>
    </>
  );

  const navSubtitle = (
    <>
      <CopyToClipboardTooltip valueToCopy={fullSubtitle}>
        {subtitle}
      </CopyToClipboardTooltip>
    </>
  );

  return (
    <div className={styles.container}>
      <ToolbarNavControl
        icon={icon}
        title={navTitle}
        subtitle={navSubtitle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        largeIcon={largeIcon}
      />
    </div>
  );
};

export default ToolbarLocationPicker;
