import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import APIGen, { NodeTraitResponseModel } from 'ecto-common/lib/API/APIGen';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import UUID from 'uuidjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import AdminPage from 'js/components/AdminPage';
import { BatchedGetNodesQueryKey } from 'ecto-common/lib/hooks/useCurrentNode';
import CRUDView, {
  useSimpleCrudViewData
} from 'ecto-common/lib/CRUDView/CRUDView';
import CheckMark from 'ecto-common/lib/Icon/svg/CheckMark';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';

const NodeTraitsList = () => {
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const columns = useMemo<
    DataTableColumnProps<NodeTraitResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'isGlobal',
        label: T.nodes.global,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        dataFormatter: (value: boolean) => (value ? <CheckMark /> : null)
      },
      {
        dataKey: 'isLocked',
        label: T.nodes.locked,
        minWidth: 80,
        maxWidth: 80,
        align: 'center',
        dataFormatter: (value) => (value ? <Icons.Lock /> : null)
      },
      {
        dataKey: 'name',
        label: T.common.name,
        linkColumn: true
      }
    ];
  }, []);
  const propertiesQuery = APIGen.NodesV2.listNodeProperties.useQuery();

  const propertyOptions = useMemo(() => {
    return sortByLocaleCompare(
      _.map(propertiesQuery.data?.items, (property) => {
        return {
          label: property.name,
          value: property.id
        };
      }),
      'label'
    );
  }, [propertiesQuery.data]);

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
    });

    queryClient.invalidateQueries({
      queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
    });
    queryClient.invalidateQueries({
      queryKey: [BatchedGetNodesQueryKey]
    });
  }, [contextSettings, queryClient]);

  const traitModels: ModelDefinition<NodeTraitResponseModel>[] = useMemo(
    () => [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT,
        enabled: (input) => !input.isLocked,
        hasError: isNullOrWhitespace
      },
      {
        key: (input) => input.propertyIds,
        label: T.nodes.nodeproperties,
        modelType: ModelType.OPTIONS,
        isMultiOption: true,
        options: propertyOptions,
        isHorizontal: true,
        enabled: (input) => !input.isLocked
      }
    ],
    [propertyOptions]
  );

  const crudData = useSimpleCrudViewData({
    listQuery: traitsQuery,
    searchItems: ['name'],
    sortBy: 'name'
  });

  const createItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeTraits.promise(
        contextSettings,
        { nodeTraits: [item] },
        null
      )
  });

  const updateItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.addOrUpdateNodeTraits.promise(
        contextSettings,
        { nodeTraits: [item] },
        null
      )
  });

  const deleteItemMutation = useMutation({
    mutationFn: (item: NodeTraitResponseModel) =>
      APIGen.NodesV2.deleteNodeTraits.promise(
        contextSettings,
        { nodeTraitIds: [item.id] },
        null
      )
  });

  const createNewItem = (): NodeTraitResponseModel => ({
    id: UUID.generate(),
    name: 'New trait',
    isGlobal: false,
    isLocked: false,
    propertyIds: []
  });

  return (
    <CRUDView
      columns={columns}
      createNewItem={createNewItem}
      itemName={'name'}
      title={T.nodes.nodetraits}
      editTitle={T.traits.edittrait}
      addTitle={T.traits.addtrait}
      deleteItemMutation={deleteItemMutation}
      updateItemMutation={updateItemMutation}
      createItemMutation={createItemMutation}
      models={traitModels}
      onAdded={invalidateCache}
      onUpdated={invalidateCache}
      {...crudData}
    />
  );
};

const NodeTraits = () => {
  useEffect(() => {
    document.title = T.nodes.nodetraits;
  }, []);

  return <AdminPage content={<NodeTraitsList />} />;
};

export default React.memo(NodeTraits);
