import React, { useCallback, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { editablePowerControlSignalTypes } from 'ecto-common/lib/utils/constants';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';

const isSignalWritable = (signal: SignalsTableSignal) => {
  if (editablePowerControlSignalTypes.includes(signal.signalTypeId)) {
    return true;
  }

  return signal.isWritable;
};

import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { checkboxColumn } from 'ecto-common/lib/utils/dataTableUtils';
import {
  hoverDataFormatter,
  showUpdateSignalError
} from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import T, { UnlocalizedString } from 'ecto-common/lib/lang/Language';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';

import SignalsTableModal from './SignalsTableModal';
import styles from './SignalsTable.module.css';
import { formatNumber, Unit } from 'ecto-common/lib/utils/stringUtils';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import Switch from 'ecto-common/lib/Switch/Switch';
import Button from 'ecto-common/lib/Button/Button';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import useSignalSetter from 'ecto-common/lib/hooks/useSignalSetter';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  FullSignalProviderResponseModel,
  SignalProviderSignalResponseModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import {
  LastSignalValuesResult,
  SignalValueType
} from 'ecto-common/lib/hooks/useLatestSignalValues';
import CopyToClipboardTooltip from 'ecto-common/lib/CopyToClipboardTooltip/CopyToClipboardTooltip';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import MessageDialog from 'ecto-common/lib/MessageDialog/MessageDialog';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { SortDirectionType } from '../DataTable/SortDirection';
import {
  getDefaultDateTimeFormat,
  TimeFormats
} from 'ecto-common/lib/utils/dateUtils';
import { MatchedSignalInput } from '../Dashboard/datasources/SignalValuesDataSource';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';

const standardColumnProps = { minWidth: 100, flexGrow: 3 };

export const SignalTableColumnsKey = Object.freeze({
  NAME: 'name',
  VALUE: 'value',
  DESCRIPTION: 'description',
  SIGNAL_TYPE_NAME: 'signalType.name',
  SIGNAL_TYPE_DESCRIPTION: 'signalType.description',
  SIGNAL_PROVIDER_NAME: 'signalProvider.signalProviderName',
  UNIT: 'unit'
});

export const NonSelectableColumns = [SignalTableColumnsKey.VALUE];

const SignalsTableUnitColumn = ({
  signal
}: {
  signal: InternalSignalsTableSignal;
}) => {
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const unit = getSignalTypeUnit(
    signal.signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  );

  return hoverDataFormatter(unit);
};

interface SignalsTableValueColumnProps {
  value?: number;
  signal?: InternalSignalsTableSignal & { signalInfo: MatchedSignalInput };
  isHovering?: boolean;
}

const SignalsTableValueColumn = ({
  value,
  signal
}: SignalsTableValueColumnProps) => {
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const unit = getSignalTypeUnit(
    signal.signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  );

  let valueStr =
    value &&
    formatNumber(
      value,
      signal?.signalInfo?.rounding != null
        ? signal.signalInfo.rounding
        : numDecimalsForUnit(unit)
    );
  if (valueStr == null) {
    valueStr = '-';
  }

  let tooltipText = null;
  if (signal.step || signal.time) {
    let timeVal = '-';

    if (signal.time) {
      timeVal = moment(signal.time)
        .tz(DEFAULT_TIMEZONE)
        .format(getDefaultDateTimeFormat(TimeFormats.SECONDS));
    }

    tooltipText = T.format(
      T.equipment.tooltipformat,
      <strong key="time-text">{timeVal} </strong>,
      <strong key="step-text">{signal.step || '-'}</strong>
    );
  } else {
    tooltipText = T.equipment.novalueset;
  }

  let content;

  if (signal.editSignal || signal.toggleSignal) {
    if (unit === Unit.BINARY) {
      const isOn = value === 1;
      content = (
        <div className={styles.editRow}>
          <CopyToClipboardTooltip
            valueToCopy={valueStr}
            additionalText={tooltipText}
          >
            <div className={styles.editSwitch}>
              <Switch
                isOn={isOn}
                onClick={signal.toggleSignal}
                isLoading={signal.isPending}
              />
            </div>
          </CopyToClipboardTooltip>
        </div>
      );
    } else {
      content = (
        <div className={styles.editRow}>
          <CopyToClipboardTooltip
            valueToCopy={valueStr}
            additionalText={tooltipText}
          >
            <Button
              className={styles.editSignalButton}
              loading={signal.isPending}
              onClick={signal.editSignal}
            >
              {valueStr}
            </Button>
          </CopyToClipboardTooltip>
        </div>
      );
    }
  } else {
    content = (
      <CopyToClipboardTooltip
        valueToCopy={valueStr}
        additionalText={tooltipText}
      >
        <span data-testid={'value-' + signal.signalId}>{valueStr}</span>
      </CopyToClipboardTooltip>
    );
  }

  return content;
};

interface EquipmentColumnProps {
  signalProvider?: FullSignalProviderResponseModel;
  name?: string;
}

const EquipmentColumn = ({ signalProvider, name }: EquipmentColumnProps) => {
  const equipmentNodeId = signalProvider?.nodeIds?.[0];

  const equipmentNode = useNode(equipmentNodeId);

  if (signalProvider?.signalProviderType !== SignalProviderType.Equipment) {
    if (equipmentNode.isLoading) {
      return <Spinner size={SpinnerSize.INLINE} />;
    }
    if (equipmentNode.node != null) {
      return <>{name + ' - ' + equipmentNode.node.name}</>;
    }
    return <>{name + ''}</>;
  }

  return hoverDataFormatter(signalProvider.signalProviderName, true);
};

type ColumnWithSettings = DataTableColumnProps<InternalSignalsTableSignal> & {
  settings?: {
    includeEquipmentName: boolean;
  };
};

export const SIGNALS_TABLE_COLUMNS: DataTableColumnProps<
  InternalSignalsTableSignal & { signalInfo: MatchedSignalInput }
>[] = [
  {
    label: T.equipment.displayname,
    dataKey: SignalTableColumnsKey.NAME,
    ...standardColumnProps,
    dataFormatter: (value: string) => hoverDataFormatter(value),
    canSort: true
  },
  {
    label: T.common.description,
    dataKey: SignalTableColumnsKey.DESCRIPTION,
    ...standardColumnProps,
    dataFormatter: (value: string) => hoverDataFormatter(value),
    canSort: true
  },
  {
    label: T.signals.signaltype,
    dataKey: SignalTableColumnsKey.SIGNAL_TYPE_NAME,
    minWidth: 100,
    flexGrow: 2,
    canSort: true,
    dataFormatter: (value: string) => hoverDataFormatter(value, true)
  },
  {
    label: T.admin.signaltypes.typedescription,
    dataKey: SignalTableColumnsKey.SIGNAL_TYPE_DESCRIPTION,
    minWidth: 100,
    flexGrow: 2,
    dataFormatter: (value: string) => hoverDataFormatter(value)
  },
  {
    label: T.equipment.category,
    dataKey: SignalTableColumnsKey.SIGNAL_PROVIDER_NAME,
    minWidth: 100,
    flexGrow: 2,
    canSort: true,
    dataFormatter: (
      value: keyof typeof T.signalproviders.type,
      signal,
      _index,
      _isHovering,
      column: ColumnWithSettings
    ) => {
      const localization = T.signalproviders.type[value];
      const name =
        localization !== UnlocalizedString && localization != null
          ? localization
          : value;

      if (
        column.settings?.includeEquipmentName &&
        signal.signalProvider.signalProviderType !==
          SignalProviderType.Equipment
      ) {
        return (
          <EquipmentColumn signalProvider={signal.signalProvider} name={name} />
        );
      }
      return hoverDataFormatter(name);
    }
  },
  {
    label: T.equipment.value,
    dataKey: SignalTableColumnsKey.VALUE,
    minWidth: 120,
    align: HorizontalAlignments.CENTER,
    canSort: true,
    dataFormatter: (value: number, signal) => (
      <SignalsTableValueColumn value={value} signal={signal} />
    )
  },
  {
    label: T.equipment.unit,
    dataKey: SignalTableColumnsKey.UNIT,
    minWidth: 100,
    flexGrow: 0,
    dataFormatter: (unused: unknown, signal) => (
      <SignalsTableUnitColumn signal={signal} />
    )
  }
];

interface SignalsTableProps {
  signals?: SignalsTableSignal[];
  isLoading?: boolean;
  hasError?: boolean;
  setCurrentEditSignal?(signal: SignalsTableSignal): void;
  currentEditSignal?: SignalProviderSignalWithProviderResponseModel;
  signalValuesById: LastSignalValuesResult;
  otherSignals?: SignalProviderSignalResponseModel[];
  onClickRow?(
    signal: InternalSignalsTableSignal,
    row: number,
    column: number
  ): void;
  className?: string;
  selectedSignalIds?: Record<string, boolean>;
  columnKeys?: string[];
  columnSettings?: object;
  onSortChange?: (orderBy: string, sortDirection: SortDirectionType) => void;
  sortBy?: string;
  sortDirection?: SortDirectionType;
}

export type SignalsTableSignal = SignalProviderSignalWithProviderResponseModel &
  SignalValueType & {
    rawSignal: SignalProviderSignalResponseModel;
    step?: number;
  };

export type InternalSignalsTableSignal = SignalsTableSignal & {
  editSignal: React.MouseEventHandler<HTMLButtonElement>;
  toggleSignal: React.MouseEventHandler<HTMLDivElement>;
  isPending: boolean;
};

const SignalsTable = ({
  signals,
  selectedSignalIds,
  isLoading,
  hasError,
  setCurrentEditSignal,
  currentEditSignal,
  signalValuesById,
  otherSignals,
  onClickRow,
  className,
  columnKeys,
  columnSettings,
  onSortChange,
  sortBy,
  sortDirection
}: SignalsTableProps) => {
  const [signalUpdateTimestamps, setSignalUpdateTimestamps] = useState<
    Record<string, string>
  >({});
  const onDoneEditingSignal = useCallback(() => {
    setCurrentEditSignal(null);
  }, [setCurrentEditSignal]);

  const setPendingSignal = useCallback((_signal: SignalsTableSignal) => {
    setSignalUpdateTimestamps((oldUpdateTimes) => {
      return {
        ...oldUpdateTimes,
        [_signal.signalId]: _signal.time ? _signal.time : null
      };
    });
  }, []);

  const clearPendingSignal = useCallback((_signal: SignalsTableSignal) => {
    setSignalUpdateTimestamps((oldUpdateTimes) => {
      const newUpdateTimes = { ...oldUpdateTimes };
      delete newUpdateTimes[_signal.signalId];
      return newUpdateTimes;
    });
  }, []);

  const editSignal = useCallback(
    (
      signal: SignalsTableSignal,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      event.stopPropagation();
      event.preventDefault();
      setCurrentEditSignal?.(signal);
    },
    [setCurrentEditSignal]
  );

  const onSignalUpdateFailed = useCallback(
    (err: Error, _signals: InternalSignalsTableSignal[]) => {
      showUpdateSignalError(err);

      _.forEach(_signals, (signal) => {
        clearPendingSignal?.(signal);
      });
    },
    [clearPendingSignal]
  );

  const [, setSignalValues] = useSignalSetter({
    onSignalUpdated: null,
    onSignalUpdateFailed,
    otherSignals,
    signalValuesById
  });

  const signalWithMessage = useRef(null);
  const [messageDialogOpen, showMessageDialog, hideMessageDialog] =
    useSimpleDialogState();

  const onConfirmSignalMessage = useCallback(
    (message: string) => {
      hideMessageDialog();
      setPendingSignal(signalWithMessage.current);
      const value = signalWithMessage.current.value === 1.0 ? 0.0 : 1.0;
      setSignalValues([{ ...signalWithMessage.current, value }], message);
    },
    [hideMessageDialog, setPendingSignal, setSignalValues]
  );

  const toggleSignal = useCallback(
    (_signal: SignalsTableSignal) => {
      signalWithMessage.current = _signal;
      showMessageDialog();
    },
    [showMessageDialog]
  );

  const tableData: InternalSignalsTableSignal[] = useMemo(() => {
    return _.map(signals, (signal) => {
      const timestampBeforeLastUpdate = signalUpdateTimestamps[signal.signalId];
      const isPending =
        timestampBeforeLastUpdate != null &&
        (signal.time == null || signal.time <= timestampBeforeLastUpdate);

      return {
        ...signal,
        editSignal: isSignalWritable(signal)
          ? (event: React.MouseEvent<HTMLButtonElement>) =>
              editSignal(signal, event)
          : null,
        toggleSignal: isSignalWritable(signal)
          ? (event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault();
              event.stopPropagation();
              toggleSignal(signal);
            }
          : null,
        isPending
      };
    });
  }, [editSignal, signals, signalUpdateTimestamps, toggleSignal]);

  const _checkboxColumn = useMemo(
    () =>
      checkboxColumn<InternalSignalsTableSignal>({
        label: '',
        rowOnChange: _.noop,
        rowIsChecked: (item) => selectedSignalIds[item.signalId] != null
      }),
    [selectedSignalIds]
  );

  const sortable = onSortChange != null;

  const columns: DataTableColumnProps<InternalSignalsTableSignal>[] =
    useMemo(() => {
      let filteredColumns = columnKeys
        ? _.filter(SIGNALS_TABLE_COLUMNS, (column) => {
            return _.includes(
              [...columnKeys, ...NonSelectableColumns],
              column.dataKey
            );
          })
        : SIGNALS_TABLE_COLUMNS;

      if (!sortable) {
        filteredColumns = filteredColumns.map((column) => ({
          ...column,
          canSort: false
        }));
      }
      if (selectedSignalIds) {
        return [_checkboxColumn, ...filteredColumns];
      }
      // add settings to column
      return _.map(filteredColumns, (column) => ({
        ...column,
        settings: columnSettings
      }));
    }, [
      _checkboxColumn,
      columnKeys,
      columnSettings,
      selectedSignalIds,
      sortable
    ]);

  return (
    <>
      <MessageDialog
        isOpen={messageDialogOpen}
        title={T.editsignalvalue.dialogtitle}
        messageTitle={T.common.reason}
        onModalClose={hideMessageDialog}
        onConfirmMessage={onConfirmSignalMessage}
        isRequired
      />

      <DataTable<InternalSignalsTableSignal>
        columns={columns}
        isLoading={isLoading}
        data={tableData}
        hasError={hasError}
        onClickRow={onClickRow}
        className={className}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSortChange={onSortChange}
      />

      <SignalsTableModal
        signal={currentEditSignal}
        signalValuesById={signalValuesById}
        otherSignals={otherSignals}
        onDoneEditingSignal={onDoneEditingSignal}
        setPendingSignal={setPendingSignal}
        clearPendingSignal={clearPendingSignal}
      />
    </>
  );
};

export default SignalsTable;
