import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import T from 'ecto-common/lib/lang/Language';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import React from 'react';
import NodeSearchTable from 'ecto-common/lib/Page/NodeSearchTable';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './NodesAdvancedSearchDialog.module.css';
import HelpPaths from 'ecto-common/help/tocKeys';
import _ from 'lodash';

type NodesAdvancedSearchDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onClickRow: (node: NodeV2ResponseModel) => void;
};

const NodesAdvancedSearchDialog = ({
  isOpen,
  onClose,
  onClickRow
}: NodesAdvancedSearchDialogProps) => {
  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onClose}
      onConfirmClick={onClose}
      title={T.nodes.advancedsearch}
      headerIcon={Icons.Search}
      disableCancel
      actionText={T.common.done}
      large
      helpPath={HelpPaths.docs.general.nodesearch}
      messageBodyClassName={styles.body}
    >
      <NodeSearchTable onClickNode={onClickRow} />
    </ActionModal>
  );
};

export default React.memo(NodesAdvancedSearchDialog);
