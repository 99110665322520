import React, { useCallback, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import T from 'ecto-common/lib/lang/Language';

import AdminPage from 'js/components/AdminPage';

import SignalMappingPage from 'js/components/SignalMapping/SignalMappingPage';
import ProcessMapsPage from 'js/components/ProcessMaps/ProcessMapsPage';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import TenantDashboardsPage from '../Dashboards/TenantDashboardsPage';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';
import EditLocation from 'js/components/EditLocation/EditLocation';
import { useStore } from 'zustand';
import { NodeTreeStoreContext } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

type AdminPageProps = {
  onTitleChanged?: (title: string[]) => void;
  selectedLocation: NodeV2ResponseModel;
  isLoading: boolean;
};

const contentRenderFunctions: Record<
  string,
  (props: AdminPageProps) => JSX.Element
> = {
  dashboard: ({
    onTitleChanged,
    selectedLocation,
    isLoading
  }: AdminPageProps) => (
    <EditLocation
      onTitleChanged={onTitleChanged}
      selectedLocation={selectedLocation}
      isLoading={isLoading}
    />
  ),
  signalmapping: ({
    onTitleChanged,
    selectedLocation,
    isLoading
  }: AdminPageProps) => (
    <SignalMappingPage
      onTitleChanged={onTitleChanged}
      selectedLocation={selectedLocation}
      isLoading={isLoading}
    />
  ),
  dashboards: () => <TenantDashboardsPage />,
  processmaps: () => <ProcessMapsPage />
};

const Location = () => {
  const { nodeId, page: pageFromParams } = useParams<NodeParams>();
  const { tenantId } = useContext(TenantContext);
  const nodeTreeStore = useContext(NodeTreeStoreContext);

  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  const { currentNode: selectedLocation, isLoading: isLoadingCurrentNode } =
    useCurrentNode();
  const nodeListEmpty = rootLevelNodes?.length === 0;
  const page = nodeId === ROOT_NODE_ID ? 'dashboard' : pageFromParams;

  const onTitleChanged = useCallback((title: string[]) => {
    document.title = title.join(' | ');
  }, []);

  if (
    !selectedLocation &&
    nodeId !== ROOT_NODE_ID &&
    nodeListEmpty &&
    !isLoadingCurrentNode
  ) {
    return (
      <Navigate
        to={{
          pathname: `/${tenantId}/home/${ROOT_NODE_ID}/dashboard`
        }}
      />
    );
  }

  let content: React.ReactNode = null;

  if (!selectedLocation && !isLoadingCurrentNode) {
    const title = T.location.error.find;

    content = (
      <ToolbarContentPage title={title} wrapContent>
        {title}
      </ToolbarContentPage>
    );

    return <AdminPage content={content} />;
  }

  const contentFunc = contentRenderFunctions[page];

  return (
    <AdminPage
      content={
        contentFunc &&
        contentFunc({
          onTitleChanged,
          selectedLocation,
          isLoading: isLoadingCurrentNode
        })
      }
    />
  );
};

export default Location;
