import React, {
  useMemo,
  useCallback,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import AddUserDialog from 'js/components/ManageUsers/AddUserDialog';
import { RoleOptions } from 'ecto-common/lib/constants';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import PagedDataTable, {
  PagedDataTableErrorResult,
  totalSizeToTotalPages
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import {
  calculateDataTableMinHeight,
  standardColumns
} from 'ecto-common/lib/utils/dataTableUtils';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import IdentityServiceAPIGen, {
  CreateOrUpdateTenantUserRequest,
  IdNamePairModel,
  TenantModel,
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGen';
import { useMutation, useQuery, keepPreviousData } from '@tanstack/react-query';

export const useManageUsersPaging = (pageSize: number) => {
  const [paging, setPaging] = useState(() => ({
    sortColumn: null as string,
    sortOrder: null as string,
    pageNumber: 0,
    pageSize,
    filter: null as string
  }));

  const setPageNumber = useCallback((pageNumber: number) => {
    setPaging((oldPaging) => ({ ...oldPaging, pageNumber }));
  }, []);

  const setSearchFilter = useCallback((filter: string) => {
    setPaging((oldPaging) => ({ ...oldPaging, pageNumber: 0, filter }));
  }, []);

  return { paging, setSearchFilter, setPageNumber };
};

interface ManageUsersProps {
  newUser?: object;
  setNewUser: Dispatch<SetStateAction<TenantUserModel>>;
  tenantId?: string;
  paging?: ManageUsersPagingData;
  setPageNumber: (newPage: number) => void;
}

type ManageUsersPagingData = {
  pageSize: number;
  pageNumber: number;
};

/**
 * Renders a list view of all users.
 * In this view you can create and edit users
 */
const ManageUsers = ({
  newUser,
  setNewUser,
  paging,
  tenantId,
  setPageNumber
}: ManageUsersProps) => {
  const [userToDelete, setUserToDelete] = useState<TenantUserModel>(null);
  const { availableTenantRoles } = useContext(TenantContext);
  const { contextSettings } = useContext(TenantContext);

  const usersQuery = useQuery({
    queryKey: ['listTenantUsers', tenantId, paging],

    queryFn: ({ signal }) => {
      if (tenantId == null) {
        return IdentityServiceAPIGen.Tenant.listTenantUsers.promise(
          contextSettings,
          paging,
          signal
        );
      }

      return IdentityServiceAPIGen.TenantUsers.listTenantUsers.promise(
        contextSettings,
        {
          tenantId: tenantId
        },
        paging,
        signal
      );
    },
    placeholderData: keepPreviousData
  });

  const users = useMemo(() => {
    if (usersQuery.error) {
      return PagedDataTableErrorResult;
    }

    if (usersQuery.data) {
      return {
        totalPages: totalSizeToTotalPages(
          usersQuery.data.totalSize,
          paging.pageSize
        ),
        hasError: false,
        result: usersQuery.data.tenantUsers
      };
    }

    return {
      totalPages: 0,
      hasError: false,
      result: []
    };
  }, [paging.pageSize, usersQuery.data, usersQuery.error]);

  const updateUserMutation = useMutation({
    mutationFn: (req: CreateOrUpdateTenantUserRequest) => {
      if (tenantId == null) {
        return IdentityServiceAPIGen.Tenant.createOrUpdateTenantUser.promise(
          contextSettings,
          req,
          null
        );
      }

      return IdentityServiceAPIGen.TenantUsers.createOrUpdateTenantUser.promise(
        contextSettings,
        {
          tenantId
        },
        req,
        null
      );
    },

    onSuccess: () => {
      usersQuery.refetch();
    },

    onError: (_err: unknown, user) => {
      toastStore.addErrorToastForUpdatedItem(user.tenantUser?.email, false);
    }
  });

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => {
      if (tenantId == null) {
        return IdentityServiceAPIGen.Tenant.deleteTenantUser.promise(
          contextSettings,
          {
            userId
          },
          null
        );
      }

      return IdentityServiceAPIGen.TenantUsers.deleteTenantUser.promise(
        contextSettings,
        {
          tenantId,
          userId
        },
        null
      );
    },

    onSuccess: () => {
      setUserToDelete(null);
      toastStore.addSuccessToast(T.admin.users.request.deleteduser);
      usersQuery.refetch();
    },

    onError: (_err, _tenantUser) => {
      toastStore.addErrorToastForDeletedItem(userToDelete?.email);
    }
  });

  const cancelDeleteUser = useCallback(() => {
    setUserToDelete(null);
  }, []);

  const confirmDeleteUser = useCallback(() => {
    deleteUserMutation.mutate(userToDelete.userId);
  }, [deleteUserMutation, userToDelete?.userId]);

  const onDelete = useCallback((user: TenantModel) => {
    setUserToDelete(user);
  }, []);

  const updateRole = useCallback(
    (user: TenantUserModel, newRole: GenericSelectOption<string>) => {
      const fullRoleObject = _.find(availableTenantRoles, [
        'name',
        newRole.value
      ]);
      updateUserMutation.mutate({
        tenantUser: { ...user, roles: [fullRoleObject] }
      });
    },
    [availableTenantRoles, updateUserMutation]
  );

  const columns: DataTableColumnProps<TenantUserModel>[] = useMemo(
    () => [
      {
        label: T.admin.users.name,
        dataKey: 'displayName',
        width: 1,
        dataFormatter: (value: string) => (
          <>
            <Icons.User />
            {value}
          </>
        )
      },
      {
        label: T.admin.users.username,
        dataKey: 'email',
        width: 1,
        flexGrow: 1
      },
      {
        label: T.admin.users.role,
        dataKey: 'roles',
        width: 170,
        flexGrow: 0,
        flexShrink: 0,
        dataFormatter: (
          userRoles: IdNamePairModel[],
          user: TenantUserModel
        ) => {
          const relevantRole = _.head(userRoles);
          const selectedOption =
            _.find(RoleOptions, ['value', relevantRole?.name]) ?? null;
          return (
            <Select<GenericSelectOption<string>, false>
              options={RoleOptions}
              value={selectedOption}
              onChange={(newValue: GenericSelectOption<string>) => {
                updateRole(user, newValue);
              }}
            />
          );
        }
      },
      ...standardColumns({ onDelete })
    ],
    [onDelete, updateRole]
  );

  const minHeight = calculateDataTableMinHeight({
    pageSize: paging.pageSize,
    withSelect: true
  });

  return (
    <>
      <PagedDataTable<TenantUserModel>
        pageSize={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={setPageNumber}
        isLoading={
          usersQuery.isFetching ||
          updateUserMutation.isPending ||
          deleteUserMutation.isPending
        }
        data={users}
        columns={columns}
        minHeight={minHeight}
        errorText={T.admin.users.error.loadfailed}
        useAllAvailableHeight
      />
      <AddUserDialog
        onUserChanged={usersQuery.refetch}
        setEditUser={setNewUser}
        editUser={newUser}
        isLoadingResources={usersQuery.isLoading}
        tenantId={tenantId}
      />
      <ConfirmDeleteDialog
        isLoading={deleteUserMutation.isPending}
        isOpen={userToDelete != null}
        onModalClose={cancelDeleteUser}
        onDelete={confirmDeleteUser}
        itemName={userToDelete?.displayName}
      />
    </>
  );
};

export default React.memo(ManageUsers);
