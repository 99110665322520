import React, { useCallback, useMemo } from 'react';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import {
  GenericSelectOption,
  SelectProps
} from 'ecto-common/lib/Select/Select';

export const keyValueFixedSelectableMenuComponents = {
  Menu: () => {
    return <></>;
  }
};

type KeyValueFixedSelectableInputProps<
  ValueType,
  IsMulti extends boolean
> = SelectProps<ValueType, IsMulti> & {
  /**
   * Text describing the component. Shown above the component with a small font.
   */
  keyText: string;
  /**
   * Placeholder text that will be shown instead of the value before any value has been set.
   */
  placeholder?: React.ReactNode;
  /**
   * The current node/text to be shown in the select control.
   */
  value?: React.ReactNode;
  /**
   * Action to trigger when select control is tapped.
   */
  onClick?(): void;
  /**
   * Action to trigger when value is cleared.
   */
  onClear?(): void;
  /**
   * If set to true, show a loading indicator inside the control.
   */
  isLoading?: boolean;
  /**
   * If set to true, display red border around control indicating error.
   */
  hasError?: boolean;

  /**
   * Help text to be shown below the control.
   */
  helpText?: React.ReactNode;
};

/**
 *  This class allows you to render a control which looks like a select control but only shows the current value. Once tapped you can show a modal which enables more complex selection logic than a simple drop down menu.
 */
export function KeyValueFixedSelectableInput<ValueType>({
  keyText,
  value = null,
  onClick = null,
  onClear = null,
  placeholder = null,
  isLoading = false,
  helpText = null,
  ...props
}: KeyValueFixedSelectableInputProps<ValueType, false>) {
  const selectableOptions = useMemo(
    (): GenericSelectOption<ValueType>[] => [
      {
        label: value,
        value: null
      }
    ],
    [value]
  );

  const onChange = useCallback(
    (valueChange: unknown) => {
      if (valueChange == null) {
        onClear?.();
      }
    },
    [onClear]
  );

  return (
    <KeyValueSelectableInput<unknown, false>
      {...props}
      keyText={keyText}
      value={value ? selectableOptions[0] : null}
      placeholder={placeholder}
      isLoading={isLoading}
      isSearchable={false}
      options={selectableOptions}
      components={keyValueFixedSelectableMenuComponents}
      onChange={onChange}
      onMenuOpen={onClick}
      helpText={helpText}
    />
  );
}
