import React, { useCallback } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import T from 'ecto-common/lib/lang/Language';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import _ from 'lodash';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import UploadFile, {
  UploadFileType
} from 'ecto-common/lib/UploadFile/UploadFile';
import FilesList from './FilesList';
import ModalFooter from '../Modal/ModalFooter';
import GreyButton from '../Button/GreyButton';
import styles from './SelectFilesDialog.module.css';

const SelectFilesDialog = ({
  isOpen,
  onModalClose,
  nodeId,
  canEditFiles
}: {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  canEditFiles?: boolean;
}) => {
  const listQuery = PresentationAPIGen.Nodes.getFiles.useQuery(
    { nodeId },
    null,
    {
      enabled: isOpen && !_.isEmpty(nodeId)
    }
  );
  const createItemMutation = PresentationAPIGen.Nodes.addFile.useMutation(
    {
      nodeId
    },
    {
      onError: (_unusedErr, data) => {
        toastStore.addErrorToastForUpdatedItem(data.file.name, true);
      },
      onSuccess: (file) => {
        toastStore.addSuccessToastForUpdatedItem(file.name, true);
        listQuery.refetch();
      }
    }
  );

  const onFileUpload = useCallback(
    (file: UploadFileType) => {
      createItemMutation.mutate({ file });
    },
    [createItemMutation]
  );

  const isLoading = listQuery.isLoading;

  const footer = (
    <ModalFooter>
      <UploadFile
        file={null}
        onFileUpload={onFileUpload}
        isLoading={createItemMutation.isPending}
        disabled={createItemMutation.isPending}
      >
        <Icons.Add />
        {T.common.addfile}
      </UploadFile>
      <GreyButton onClick={onModalClose} disabled={isLoading} tabIndex={-1}>
        {T.common.done}
      </GreyButton>
    </ModalFooter>
  );
  return (
    <>
      <ActionModal
        className={styles.dialog}
        onModalClose={onModalClose}
        isOpen={isOpen}
        headerIcon={Icons.File}
        title={T.common.files}
        cancelText={T.common.done}
        onConfirmClick={null}
        footer={footer}
      >
        <FilesList
          nodeId={nodeId}
          items={listQuery.data?.items ?? []}
          canEditFiles={canEditFiles}
          isLoading={isLoading}
        />
      </ActionModal>
    </>
  );
};

export default SelectFilesDialog;
